import React from "react";
import { BucketSelectorLang } from '../../lang';
import FetchCmp from '../../components/FetchCmp/fetchCmp';


// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  FormGroup
} from "reactstrap";

//Bucket Selector va a tener un prop que llamado bucketList que son los buckets asocidos al token de acceso que Cortteza construye. 
//De esos props construye la tabla de seleccion de buckets. El app deberia guardar en cache entonces el bucket list asociado a la sesión.


class BucketSelector extends React.Component {
  constructor(props) {
    super(props)
    this._isMounted = false;
    this.state = {
      bucketList: null,
      textSearch: ""
    }
    this.handleBucketButtom = this.handleBucketButtom.bind(this);
    this.labels = BucketSelectorLang(props.lang);
    this.loadingSpinner = <div className="lds-container">
      <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
    </div>;
  }

  //1. REACT LIFECYCLE
  async componentDidMount() {
    this._isMounted = true;
    try {
      var cacheId = "GetBuckets-CacheId" + Math.round(Math.random() * 100000000);
      var data = await this.props.askFor('security', 'security/getbuckets', [], false, {}, cacheId);
      if (data != null) {
        this.setState({ bucketList: data })
      }
    } catch (err) {
      console.log(err)
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.lang != this.props.lang) {
      if (this._isMounted) {
        this.labels = BucketSelectorLang(this.props.lang);
        this.forceUpdate();
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  //2.DATA HANDLERS
  handleBucketButtom = async (bucket) => {
    try {
      var res = await this.props.validateAdminToken(this.props.token, bucket.bucketId);
      var isAdmin = { status: res.result, adminData: res.userdata }
      var campaignsConfiguration = bucket.campaignsConfiguration;
      var campaignsAvailable = campaignsConfiguration !== undefined ? campaignsConfiguration.email.enabled || campaignsConfiguration.email.enabled || campaignsConfiguration.facebook.enabled  ? true  : false : false;
      if(campaignsAvailable){
        bucket.menuOptions.push({
          "path" : "campaigns",
          "title" : "menuCampaigns",
          "icon" : "ti-email"
      })
      }
      this.props.handleBucketChange(bucket, isAdmin)
    }
    catch (err) {
      console.log(err)
    }

  }

  handleTextSearch(val) {
    val.replace(/[^a-zA-Z ]/g, "");
    this.setState({ textSearch: val });

  }


  //3.RENDER
  render() {
    return (<>
      <div className="content">
        <Row className="bucketTitleRow">
          <h1>Mis Buckets</h1>
          <p>{this.state.bucketList != null ? this.state.bucketList.length : 0} {this.labels.lblAvailableBuckets}</p>
        </Row>
        <Row>
          <Col md="12">
            <Card>
              <CardBody>
                <Row>
                  <Col md="12" lg="12">
                    <FormGroup>
                      {/* <label>{this.labels.lblSearch}:</label> */}
                      <input type="text"
                        className="form-control border-input"
                        placeholder={this.labels.lblSearchPlaceHolder}
                        value={this.state.textSearch}
                        onChange={e => { this.handleTextSearch(e.target.value) }} />
                    </FormGroup>
                  </Col>
                </Row>
                <Table className="bucketTable" responsive={true}>
                  <thead className="bucketTableHead">
                    {this.state.bucketList != null ?
                      <tr>
                        <th>ID</th>
                        <th>{this.labels.lblBucketName}</th>
                        <th>{this.labels.lblBucketRegion}</th>
                        <th className="text-center"></th>
                      </tr>
                      :
                      <tr>
                        <th style={{ padding: "15px" }}></th>
                      </tr>
                    }
                    <tr>

                    </tr>
                  </thead>
                  {this.state.bucketList != null ?
                    <tbody className="bucketTableBody">
                      {this.state.bucketList.map((bucket, key) => {
                        if (this.state.textSearch != "") {
                          if (bucket.bucketId.toUpperCase() == this.state.textSearch.toUpperCase() || bucket.bucketId.toUpperCase().indexOf(this.state.textSearch.toUpperCase()) > -1 || bucket.name.toUpperCase() == this.state.textSearch.toUpperCase() || bucket.name.toUpperCase().indexOf(this.state.textSearch.toUpperCase()) > -1) {
                            return (<tr key={key}>
                              <td>{bucket.bucketId}</td>
                              <td>{bucket.name}</td>
                              <td>{bucket.region}</td>
                              <td className="text-center"><Button onClick={() => { this.handleBucketButtom(bucket) }}>{this.labels.btnUseBucket}</Button></td>
                            </tr>
                            )
                          }
                        }
                        else {
                          return (<tr key={key}>
                            <td>{bucket.bucketId}</td>
                            <td>{bucket.name}</td>
                            <td>{bucket.region}</td>
                            <td className="text-center"><Button onClick={() => { this.handleBucketButtom(bucket) }}>{this.labels.btnUseBucket}</Button></td>
                          </tr>
                          )
                        }

                      })}
                    </tbody>
                    :
                    <tbody className="bucketLoading">
                      <tr style={{ marginTop: "10px" }}>
                        <td>
                          {this.loadingSpinner}
                          <p className="text-center">{this.labels.lblLoading}</p>
                        </td>
                      </tr>
                    </tbody>
                  }
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>)
  }
}

// var BucketSelector = props => {
//   return (
//     <FetchCmp
//       render={props => <BucketSelectorWraped

//       />
//       }
//     />
//   )
// }


export default BucketSelector;