import React from "react";
import ClientDetail from '../Client/ClientDetail'
import FetchCmp from '../../components/FetchCmp/fetchCmp';
import ReactTooltip from "react-tooltip";
import { SegmentDetailLang, crmDropdownLang, MyExportsCreatorLang } from '../../lang';
import NumberFormat from "react-number-format";
import {
    Container, Row, Col,
    Card, CardBody,
    Button,
    Table,
    FormGroup, Input,
    Dropdown, DropdownMenu, DropdownItem, DropdownToggle
} from 'reactstrap';


class WrapedCRM extends React.Component {
    constructor(props) {
        super(props)
        this._isMounted = false;
        this._pageSize = 10;
        this.dropdownLabels = crmDropdownLang(props.lang);
        this.labels = SegmentDetailLang(props.lang);
        this.state = {
            data: null,
            dropdownOpen: false,
            dropDownValue: "NOMBRE",
            dropDownLabel: this.dropdownLabels.name,
            page: 0,
            count: 0,
            screen: "list", //list || detail
            clientId: null,
            isLoading: false,
        };
        this.body = {
            "searchquery": "",
            "page": 0,
            "sort": "",
        }
        this.toggleDropdown = this.toggleDropdown.bind(this)
        this.changeScreen = this.changeScreen.bind(this);
        this.searchClients = this.searchClients.bind(this);
        this.onEnter = this.onEnter.bind(this);
    }

    async componentDidMount() {
        this._isMounted = true;
        try {
            // this.body.sort = this.state.dropDownValue;
            // let crmcacheID = "CRMFULL-" + "page" + this.state.page;
            // let clientL = await this.props.askFor('crm', 'crm_bucket/searchclients', [], false, this.body, crmcacheID);
            // if (this._isMounted && clientL != null) {
            //     this.setState({ data: clientL.clients });
            // }
        } catch (err) {
            console.log(err);
        }
    }

    async componentDidUpdate(prevProps, prevState) {

        if (prevState.page != this.state.page) {
            this.setState({ data: null, isLoading: true });
            this.body.page = this.props.page;
            let crmcacheID = "crmSegments" + this.props.segment + "page" + this.state.page + this.state.dropDownValue;
            let clientL = await this.props.askFor('crm', 'crm_bucket/searchclients', [], false, this.body, crmcacheID);
            if (clientL != null) {
                this.setState({ data: clientL.clients, isLoading: false, count: clientL.count });
            }
        }

        if (prevState.dropDownValue != this.state.dropDownValue) {
            try {
                console.log(this.body);
                if (this._isMounted) { this.setState({ data: null, isLoading: true, page: 0 }) };
                this.body.page=0;
                this.body.sort = this.state.dropDownValue + ",1";
                let crmcacheID = "crmSegments" + "page" + this.state.page + this.state.dropDownValue;
                let clientL = await this.props.askFor('crm', 'crm_bucket/searchclients', [], false, this.body, crmcacheID);
                if (this._isMounted && clientL != null) {
                    this.setState({ data: clientL.clients, isLoading: false, count: clientL.count });
                }
            } catch (err) {
                console.log(err);
            }
        }

        if (prevProps.labels != this.props.labels) {
            this.labels = this.props.labels;
            if (this._isMounted) {
                this.forceUpdate();
            }
        }

        if (prevProps.lang != this.props.lang) {
            this.dropdownLabels = crmDropdownLang(this.props.lang);
            if (this._isMounted) {
                this.forceUpdate();
            }
        }



        ReactTooltip.rebuild();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    getEmail = (email) => {
        var r = null;

        if (this.props.isFieldHidden('email')) {
            return;
        } else {
            if (email === null || email === undefined) {
                r = <th>Email</th>;
            } else {
                r = <td>{email}</td>;
            }

        }

        return r;
    }

    toggleDropdown = () => {
        this.setState(state => { return ({ dropdownOpen: !state.dropdownOpen }) })
    }

    changeDropdownValue = (value) => {
        var label = "";
        var val = "";

        switch (value) {
            case 'name': label = this.dropdownLabels.name; val = "NOMBRE"; break;
            case 'amount': label = this.dropdownLabels.amount; val = "TXAMOUNTSUM"; break;
            case 'visits': label = this.dropdownLabels.visits; val = "TXCOUNT"; break;
        }


        if (this._isMounted) {
            this.setState({ dropDownValue: val, dropDownLabel: label })
        }
    }

    handleTextSearch(val) {
        this.body.searchquery = val;
    }

    onEnter(e) {
        if (e.keyCode == 13) {
            this.searchClients();
        }
    }

    async searchClients() {
        try {
            if (this._isMounted) { this.setState({ data: null, isLoading: true }) };
            //this.body.page = 0;
            let crmcacheID = "crmSegments" + "page" + 0 + this.state.dropDownValue + this.body.searchquery;
            let clientL = await this.props.askFor('crm', 'crm_bucket/searchclients', [], false, this.body, crmcacheID);
            if (this._isMounted && clientL != null) {
                console.log(clientL)
                this.setState({ data: clientL.clients, isLoading: false, count: clientL.count });
            }
        } catch (err) {
            console.log(err);
        }
    }

    //PAGINATION

    nextPage() {
        let clientsCount = this.state.count;
        let pageCount = Math.ceil(clientsCount / this._pageSize);
        if (this.state.page + 1 < pageCount) {
            this.setState(state => ({ isLoading: true, page: state.page + 1 }))
        }
        else {
            //reach last page
        }

    }

    prevPage() {
        if (this.state.page === 0) { return /*Reach firts page */ }
        this.setState(state => ({ isLoading: true, page: state.page - 1 }))
    }

    firstPage() {
        let currentPage = this.state.page;
        if (currentPage > 0) {
            this.setState({ isLoading: true, page: 0 })
        }
    }

    lastPage() {
        let currentPage = this.state.page;
        let clientsCount = this.state.count;
        let pageCount = Math.ceil(clientsCount / this._pageSize);
        if (currentPage < pageCount) {
            this.setState({ isLoading: true, page: pageCount - 1 })
        }


    }

    //PRE RENDER FUNCTIONS

    changeScreen(values) {
        //In case of the ClientDetail and SegmentDetail screens, the app needs a value to retrieve the correct data.
        //The value property refers said value, and in the case you select this screens, this porperty is required.
        var screen = values.screen;
        var value = values.value;
        if (screen == null || screen == undefined || typeof screen != "string") { return }

        if (value == null) {
            this.setState({ screen: screen });
        }
        else {
            this.setState({ screen: screen, clientId: value })
        }
    }

    getContent() {
        var c = <></>;

        switch (this.state.screen) {
            case "list":
                c = <Container fluid>
                    <ReactTooltip id="crmClientsTooltip" place="left" textColor="#1c79cd" backgroundColor="#c7e1f9" />
                    <Card style={{ marginTop: '15px' }}>
                        <CardBody>
                            <Row className="crmSegmentsBar">
                                <Col style={{ display: "flex" }}>
                                    <input
                                        type="text"
                                        className="form-control border-input noRadius"
                                        placeholder={this.labels.lblSearchPlaceHolder}
                                        onChange={e => { this.handleTextSearch(e.target.value) }}
                                        onKeyDown={this.onEnter}
                                    />
                                    <Button
                                        className="btnExport noRadius" onClick={() => { this.searchClients() }}>
                                        <i className="ti-search" />
                                    </Button>
                                </Col>
                                <label>Ordenar Por:</label>
                                <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggleDropdown}>
                                    <DropdownToggle>
                                        <span>{this.state.dropDownLabel} <i className="ti-angle-down" /></span>
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem onClick={() => { this.changeDropdownValue('name') }}>{this.dropdownLabels.name}</DropdownItem>
                                        <DropdownItem onClick={() => { this.changeDropdownValue('amount') }}>{this.dropdownLabels.amount}</DropdownItem>
                                        <DropdownItem onClick={() => { this.changeDropdownValue('visits') }}>{this.dropdownLabels.visits}</DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                                {/* <Button className="btnExport" onClick={() => { this.props.toggleSegmentExport() }}><i className="ti-download" /> {this.dropdownLabels.export}</Button> */}
                            </Row>
                            <Row>
                                <Col>
                                    {this.state.data != null ?
                                        <Table responsive className="crmSegments" striped={true}>
                                            <thead>
                                                <tr>
                                                    <th>ID</th>
                                                    <th>{this.labels.lblSorterName}</th>
                                                    <th>{this.labels.lblSorterLastName}</th>
                                                    {this.getEmail()}
                                                    <th>{this.labels.lblSorterAge}</th>
                                                    <th>{this.labels.lblSorterCount}</th>
                                                    <th>{this.labels.lblVisitCounter}</th>
                                                    <th>{this.labels.lblActions}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.data.map((element, key) => {
                                                    return (<tr key={key}>
                                                        <td>{element.CLIENTE}</td>
                                                        <td>{element.NOMBRE}</td>
                                                        <td>{element.PRIMERAPELLIDO}</td>
                                                        {this.getEmail(element.EMAIL)}
                                                        <td>{element.EDAD}</td>
                                                        <td><NumberFormat value={element.TXAMOUNTSUM} prefix={this.props.currencySymbol} displayType={'text'} thousandSeparator={true} /></td>
                                                        <td>{element.TXCOUNT}</td>
                                                        <td><Button data-tip="Ver clientes" data-for="crmClientsTooltip" onClick={() => { this.changeScreen({ screen: 'clientDetail', value: element.CLIENTE }) }}>{/* {this.labels.btnViewClients} */} <i className="ti-eye"></i></Button></td>
                                                    </tr>
                                                    )
                                                })
                                                }
                                            </tbody>
                                        </Table>
                                        :
                                        this.state.isLoading ?
                                            <>
                                                {this.props.loadingSpinner}
                                                {/* <p className="text-center">{this.labels.lblLoading}</p> */}
                                            </>
                                            :
                                            <>
                                                <p className="text-center">Digita en la barra de busqueda</p>
                                            </>
                                    }
                                </Col>

                            </Row>
                            {this.state.data != null && this.state.count >= 10 ? this.state.isLoading ? "" :
                                <>
                                    <Row className="text-center">
                                        <Col>
                                            <h4>Mostrando pagina: {this.state.page}</h4>
                                        </Col>
                                    </Row>
                                    <Row className="text-center">
                                        <Col>
                                            <button className="pager-button" onClick={() => { this.firstPage() }}>{this.labels.btnFirst}</button>
                                            <button className="pager-button" onClick={() => { this.prevPage() }}>
                                                <i className="ti-angle-left"></i></button>
                                            {this.state.data.length >= 10 ? <button className="pager-button" onClick={() => { this.nextPage() }}>
                                                <i className="ti-angle-right"></i>
                                            </button> : ""}
                                            {this.state.data.length >= 10 ?
                                                <button className="pager-button" onClick={() => { this.lastPage() }}>{this.labels.btnLast}</button>
                                                :""
                                            }
                                        </Col>
                                    </Row>
                                </>
                                : ""}
                        </CardBody>
                    </Card>

                </Container>
                    ; break;
            case "clientDetail":
                c = <ClientDetail
                    askFor={this.props.askFor}
                    clientId={this.state.clientId}
                    currencySymbol={this.props.bucket.currencySymbol}
                    lang={this.props.lang}
                    loadingSpinner={this.props.loadingSpinner}
                    backFunction={this.changeScreen}
                    backFunctionValues={{ screen: 'list' }}
                    theme={this.props.bucket.theme}
                    hideRedeem={this.props.isSectionHidden('clientdetails_redeemed_points_permonth')}
                    isFieldHidden={this.props.isFieldHidden}
                    getBucketLabels={this.props.getBucketLabels}
                />
                    ; break;
            default: ; break;
        }

        return c;

    }

    render() {
        return <div className="content">
            {this.getContent()}
        </div>;
    }

}

var CRM = props => {
    return (
        <FetchCmp
            render={props => <WrapedCRM
                bucket={props.bucket}
                askFor={props.askFor}
                isSectionHidden={props.isSectionHidden}
                isFieldHidden={props.isFieldHidden}
                currencySymbol={props.bucket.currencySymbol}
                lang={props.lang}
                loadingSpinner={props.loadingSpinner}
                size={props.size}
                getBucketLabels={props.getLabels}
            />
            }
        />
    )
}

export default CRM;