export default class Envieroment {
    _url = {}
    constructor(env) {
        switch (env) {
            case 'dev':
                this._url = {
                    //frontEndUrl: 'https://v3api.cortteza.com',
                    //backEndUrl: "https://v3api.cortteza.com/cortteza2-backend/",
                    security: "http://localhost:7060/",
                    crm: "http://localhost:7061/",
                    globalSum: 'http://localhost:7062/',
                    segments: 'http://localhost:7063/',
                    categorization: "http://localhost:7064/",
                    export: "https://localhost:7065/",
                    goals: "http://localhost:7067/",
                    config: 'http://localhost:7068/',
                    redemptions: 'https://v3api.cortteza.com/redemptions/',
                    kpis: 'https://v3api.cortteza.com/kpis/',
                    tfa: 'http://localhost:7059/',
                    campaigns: 'http://localhost:7058/',  
                };
                break;
            case 'prod':
                this._url = {
                    //frontEndUrl: 'https://v3api.cortteza.com',
                    //backEndUrl: "https://v3api.cortteza.com/cortteza2-backend/",
                    security: "https://v3api.cortteza.com/security/",
                    crm: "https://v3api.cortteza.com/crm/",
                    globalSum: "https://v3api.cortteza.com/globalsummaries/",
                    segments: "https://v3api.cortteza.com/segments/",
                    categorization: "https://v3api.cortteza.com/categorization/",
                    export: "https://v3api.cortteza.com/exportfileserver/",
                    goals: 'https://v3api.cortteza.com/goals/',
                    config: 'https://v3api.cortteza.com/configuration/',
                    redemptions: 'https://v3api.cortteza.com/redemptions/',
                    kpis: 'https://v3api.cortteza.com/kpis/',
                    tfa: 'https://v3api.cortteza.com/tfa/',
                    campaigns: 'https://v3api.cortteza.com/campaigns/',
                }
                break;
            default:
                break;
        }


    }

    get(toWhere) {
        switch (toWhere) {
            case 'security':
                return this.getSecurity();
                break;
            case 'globalsum':
                return this.getGlobalSum();
                break;
            case 'tfa':
                return this.getTfa();
                break;
            case 'segments':
                return this.getSegments();
                break;
            case 'config':
                return this.getConfig();
                break;
            case 'categorization':
                return this.getCategorization();
            break;
            case 'crm':
                return this.getCrm();
            break;
            case 'goals':
                return this.getGoals();
            break;
            case 'export':
                return this.getExport();
            break;
            case 'campaigns':
                return this.getCampaigns();
            break;
            default:
                break;
        }
    }

    getSecurity() {
        return this._url.security;
    }

    getCrm() {
        return this._url.crm;
    }

    getGlobalSum() {
        return this._url.globalSum;
    }

    getSegments() {
        return this._url.segments;
    }

    getCategorization() {
        return this._url.categorization;
    }

    getExport() {
        return this._url.export;
    }

    getGoals() {
        return this._url.goals;
    }

    getConfig() {
        return this._url.config;
    }

    getRedemptions() {
        return this._url.redemptions;
    }

    getKpis() {
        return this._url.kpis;
    }

    getTfa() {
        return this._url.tfa;
    }

    getCampaigns() {
        return this._url.campaigns;
    }
}
