import React, {createRef} from "react";
import ReactTooltip from "react-tooltip";
import {
    Card,
    Container, Row, Col,
    Table,
} from "reactstrap";
import { ReactComponent as EmailIcon } from '../../../../assets/icons/createCampaign/email-icon.svg';
import { ReactComponent as SmsIcon } from '../../../../assets/icons/createCampaign/sms-icon.svg';
import { ReactComponent as FacebookIcon } from '../../../../assets/icons/createCampaign/facebook-icon.svg';
import { FinalSelectionFormLang } from '../../../../lang';


class FinalPreviewForm extends React.Component {
    constructor(props) {
        super(props)
        this._isMounted = false;
        this.labels = FinalSelectionFormLang(props.lang)
        this.state = {
            data: null,
            body: { segments: props.segments, type: props.type }
        }
        this.subjectOrtextLabel = null;
        this.availablePoblationExplain = null;
        this.titleLabel = null;
        this.campaignIcon = null;
        this.imageRow = <tr></tr>;
        this.htmlPreviewRef = createRef();
        switch (props.type) {
            case "email":
                this.titleLabel = this.labels.lblSummaryTitle;
                this.availablePoblationExplain = <i className="ti-info" data-tip={this.labels.lblSummaryAvailablePoblationExplanEmail} data-for="CS" />;
                break;
            case "sms":
                this.titleLabel = this.labels.lblSummaryTitle;
                this.availablePoblationExplain = <i className="ti-info" data-tip={this.labels.lblSummaryAvailablePoblationExplanSms} data-for="CS" />;
                break;
            case "facebook":
                this.titleLabel = this.labels.lblSummaryTitleFacebook;
                this.availablePoblationExplain = <i className="ti-info" data-tip={this.labels.lblSummaryAvailablePoblationExplanSms} data-for="CS" />;
                this.state.body.type = "email"; // checks for emails to get the avaliable poblation count
                break;
        }
    }

    //1. ReactLife Cycle
    async componentDidMount() {
        this._isMounted = true;
        ReactTooltip.rebuild()
        // this.props.setLoadingStep(true)
        //Asks for the available poblation count
        let cacheId = 'GetAvailablePoblation-' + Math.round(Math.random() * 1000);
        let data = await this.props.askFor('campaigns', "getAvailablePoblation", [], false, this.state.body, cacheId);
        if (data != null) {
            this.setState({ data: data.count }, () => { this.props.isLoaded(true) /* this.props.setLoadingStep(false); */ })
        }
    }

    componentDidUpdate(prevProps, prevState) {

    }

    //2. PRE-RENDER FUNCTIONS

    getTable() {
        let table = <tr></tr>;
        var segmentsRow = <tr>
            <td>{this.labels.lblSummarySegments}</td>
            <td>
                <ul className="segments">
                    {this.props.segments.map((s, key) => {
                        return (<li key={key}>{s.name}</li>)
                    })}
                </ul>
            </td>
        </tr>;
        var imageRow = <tr></tr>;
        var poblationRows = <>
            <tr>
                <td className="iTooltip">
                    {this.labels.lblSummaryTotalPoblation}
                    <i className="ti-info" data-tip={this.labels.lblSummaryTotalPoblationExplan} data-for="CS" />
                </td>
                <td className="data">{this.props.selectionCount}</td>
            </tr>

            <tr>
                <td className="iTooltip">
                    {this.labels.lblSummaryAvailablePoblation}
                    {this.availablePoblationExplain}
                </td>
                <td className="data">{this.state.data || 0}</td>
            </tr>
        </>;
        var cuponRow = <tr></tr>

        switch (this.props.type) {
            case "email":
                switch (this.props.emailType) {
                    case "html":
                        // imageRow = <tr>
                        //     <td>Diseño</td>
                        //     <td><div className="htmlPreview" dangerouslySetInnerHTML={{__html:this.props.campaignHtml}}></div></td>
                        // </tr>;
                        break;
                    case "cupon":
                        imageRow = <tr>
                            <td>Cupon</td>
                            <td><img style={{ width: "100%", height: "auto" }} src={this.props.campaignCupon.ImageUrl} alt="" /></td>
                        </tr>;
                        cuponRow = <tr>
                            <td>
                                {this.labels.lblSummaryAvailableCoupons}
                            </td>
                            <td className="data">{this.props.cuponCount || 0}</td>
                        </tr>
                        break;
                    case "image":
                        imageRow = <tr>
                            <td>Imagen</td>
                            <td><img style={{ width: "100%", height: "auto" }} src={this.props.campaignImg} alt="" /></td>
                        </tr>;
                        break;

                }
                table = <Table responsive>
                    <tbody>
                        <tr>
                            <td>{this.labels.lblSummaryType}</td>
                            <td className="data"> <EmailIcon className="campaignIcon" />  {this.props.type.toUpperCase()}</td>
                        </tr>
                        <tr>
                            <td>{this.labels.lblSummaryName}</td>
                            <td className="data">{this.props.campaignName}</td>
                        </tr>
                        <tr>
                            <td>{this.labels.lblSummaryTextMessage}</td>
                            <td className="">{this.props.campaignSubject}</td>
                        </tr>
                        {segmentsRow}
                        {imageRow}
                        {poblationRows}
                        {cuponRow}
                    </tbody>
                </Table>
                    ; break;
            case "sms":
                table = <Table responsive>
                    <tbody>
                        <tr>
                            <td>{this.labels.lblSummaryType}</td>
                            <td className="data"> <SmsIcon className="campaignIcon" />  {this.props.type.toUpperCase()}</td>
                        </tr>
                        <tr>
                            <td>{this.labels.lblSummaryName}</td>
                            <td className="data">{this.props.campaignName}</td>
                        </tr>
                        <tr>
                            <td>{this.labels.lblSummaryTextMessage}</td>
                            <td className="">{this.props.campaignSubject}</td>
                        </tr>
                        {segmentsRow}
                        {poblationRows}
                    </tbody>
                </Table>;
                break;
            case "facebook":
                table = <Table responsive>
                    <tbody>
                        <tr>
                            <td>{this.labels.lblSummaryType}</td>
                            <td className="data"> <FacebookIcon className="campaignIcon" />  {this.props.type.toUpperCase()}</td>
                        </tr>
                        <tr>
                            <td>{this.labels.lblSummaryNameAudience}</td>
                            <td className="data">{this.props.audienceName}</td>
                        </tr>
                        <tr>
                            <td>{this.labels.lblSummaryAudienceDescription}</td>
                            <td className="">{this.props.audienceDescription}</td>
                        </tr>
                        {segmentsRow}
                        {poblationRows}
                    </tbody>
                </Table>;
                break;
            default: table = <tr></tr>; break;
        }

        return table;
    }

    //Z. RENDER
    render() {
        if (this.state.data != null) {
            return (
                <Container fluid className="finalSelectionForm">
                    <Row >
                        <div className="flexCenter">
                            <Col lg="8" md="8" sm="12">
                                <Card>
                                    <div className="table-header">
                                        <h3>{this.titleLabel}</h3>
                                    </div>
                                    {this.getTable()}
                                    <ReactTooltip id="CS" place="bottom" />
                                </Card>
                            </Col>
                        </div>
                    </Row>
                </Container>
            )
        }
        else {
            return (
                <Container fluid className="finalSelectionForm">
                    {this.props.loadingSpinner}
                    <h3 className="loadingText">Cargando datos...</h3>
                </Container>
            )
        }

    }
}

export default FinalPreviewForm;