import React from "react";
import { AgeBracketsLang } from '../../lang'

// reactstrap components
import {
    Container,
    Card,
    CardBody,
    CardHeader,
    Table
} from "reactstrap";


class AgeBrackets extends React.Component {
    constructor(props) {
        super(props)
        this._isMounted=false;
        this.state = { labels: AgeBracketsLang(props.lang) }
    }

    async componentDidMount(){
        try{
            this._isMounted = true;
            var l = await this.props.getBucketLabels("AgeBrackets", AgeBracketsLang(this.props.lang));
            if(l != null){
                this.setState({labels: l});
            }
        }
        catch(err){
            console.log(err);
        }
        
        
    }

    componentDidUpdate(prevProps){
        if(prevProps.lang!==this.props.lang){
            if(this._isMounted){
                this.setState({labels:AgeBracketsLang(this.props.lang)}, ()=>{this.forceUpdate()})
            }
        }
    }

    componentWillUnmount(){
        this._isMounted=false;
    }

    render() {
        if (this.props.bucket.ageBrackets.length===0) {
            return(<></>)
        } else {
        return (<>
            <Container fluid={true}>
                <Card>
                    <div className="ageBrackets">
                        <CardHeader>
                            <h6 className="title">{this.state.labels.cardHeader}</h6>
                            <p className="category">{this.state.labels.cardSubHeader}</p>
                        </CardHeader>
                        <CardBody>
                            <Table responsive>
                                <tbody className="ageTable">
                                    {/*For en la tabla */}
                                    {this.props.bucket.ageBrackets.map((element, key) => {
                                        return (<tr key={key}>
                                            <td>{element.name}</td>
                                            <td>{this.state.labels.headerStartYear} {element.startYear}</td>
                                            <td>{this.state.labels.headerEndYear} {element.endYear}</td>
                                        </tr>
                                        )
                                    })}

                                </tbody>
                            </Table>
                        </CardBody>
                    </div>
                </Card>
            </Container>
        </>)
        }
    }
}




export default AgeBrackets;

