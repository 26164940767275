import React from "react";
import classnames from 'classnames';
// import { ClientProfile } from "./SegmentCreator.jsx";
import NumberFormat from "react-number-format";
import { Line, Doughnut, } from "react-chartjs-2";
import ReactTooltip from "react-tooltip";
// import { SegmentDetailLang } from '../../lang';
import {
    Container, Row, Col,
    Card, CardBody,
    Nav, NavItem, NavLink,
    TabContent, TabPane,
    Button,
    Table,
    Input,
    Dropdown, DropdownToggle, DropdownMenu, DropdownItem,
} from 'reactstrap';
import { SegmentSummary, SegmentListItem } from "../SegmentCreator/MySegments"
import ClientDetail from '../Client/ClientDetail'

//A. SUB-COMPONENT
class PointChart extends React.Component {
    constructor(props) {
        super(props)
        this._isMounted = false;
        this.state = { data: null, total: null }
        this.labels = props.labels;
        this.reducer = this.reducer.bind(this);
        this.chartOptions = {
            legend: {
                display: true,
                labels: {
                    fontColor: '#9a9a9a'
                }
            },
            scales: {
                yAxes: [
                    {
                        ticks: {
                            fontColor: "#9a9a9a",
                            maxTicksLimit: 10,
                            userCallback: function (value, index, values) {
                                var strVal = value.toString();

                                if (value >= 1000 && value < 10000) {
                                    //strVal = value.toString();
                                    return strVal.substring(0, 1) + "," + strVal.substring(1);
                                }

                                if (value >= 10000 && value < 100000) {
                                    //strVal = value.toString();
                                    return strVal.substring(0, 2) + "," + strVal.substring(2);
                                }

                                if (value >= 10000 && value < 1000000) {
                                    //strVal = value.toString();
                                    return strVal.substring(0, 3) + "," + strVal.substring(3);
                                }

                                if (value >= 1000000) {
                                    value = (value / 1000000);
                                    strVal = value.toString();
                                    return strVal + "m";
                                }

                                return strVal;
                            }
                        }
                    }
                ],
                xAxes: [
                    {
                        ticks: {
                            fontColor: "#9a9a9a",
                        }
                    }
                ],
            },
            tooltips: {
                callbacks: {
                    label: function (tooltipItem, data) {
                        let v = tooltipItem.yLabel;
                        return v.toLocaleString();
                    }
                }
            }
        }
    }
    //Props: title, subtitle, endpoint, labels, color.


    reducer = (total, num) => {
        return total + num;
    }

    //0.REACT LIFECYCLE FUNCTIONS (API CALLS)-------------------------------------------------------------------------------------------------------------------------
    async componentDidMount() {
        this._isMounted = true;
        try {
            let cacheId = 'CustomSegmentChartDetail_' + this.props.endpoint + "_" + this.props.segment.segmentName + "_" + this.props.param;
            if (this.props.dataRefresh) {
                cacheId += Math.floor(Math.random() * 1001);
            }
            let data = await this.props.askFor('segments', this.props.endpoint, [], false, { segmentConfig: this.props.segment }, cacheId);
            if (data != null) {
                let total = 0;
                data.forEach(obj => {
                    total += obj[this.props.param];
                })
                this.setState({ data: this.readyChartData(data), total: total })
            }
        } catch (err) {
            console.log(err);
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }


    //1.PRE-RENDER FUNCTIONS ------------------------------------------------------------------------------------------------------------------------------------------------
    hexToRgb(hex) {
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16)
        } : null;
    }



    readyChartData = (data) => {
        if (data === null || data === undefined) {
            return null;
        }


        return (canvas => {
            let ctx = canvas.getContext("2d");
            var gradientStroke = null;
            var pointColor = "#43b02a";

            switch (this.props.theme) {
                case "default.css":
                    gradientStroke = ctx.createLinearGradient(67, 176, 42, 100);
                    gradientStroke.addColorStop(1, "rgba(67, 176, 42,0.6)");
                    gradientStroke.addColorStop(0.4, "rgba(67, 176, 42,0.4)");
                    gradientStroke.addColorStop(0, "rgba(67, 176, 42,0.2)");
                    break;
                case "lightTheme.css":
                    gradientStroke = ctx.createLinearGradient(155, 118, 54, 100);
                    gradientStroke.addColorStop(1, "rgba(155, 118, 54,0.6)");
                    gradientStroke.addColorStop(0.4, "rgba(155, 118, 54,0.4)");
                    gradientStroke.addColorStop(0, "rgba(155, 118, 54,0.2)");
                    pointColor = "#af9044";
                    break;
            }

            let labels = [];
            let chartData = []

            var createMonthString = (element) => {
                let m = null;
                let year = element["monthNum"].toString();
                let y = year.slice(0, 4);
                switch (element["month"]) {
                    case "Enero": m = ['ENE', y]; break;
                    case "Febrero": m = ['FEB', y]; break;
                    case "Marzo": m = ['MAR', y]; break;
                    case "Abril": m = ['ABR', y]; break;
                    case "Mayo": m = ['MAY', y]; break;
                    case "Junio": m = ['JUN', y]; break;
                    case "Julio": m = ['JUL', y]; break;
                    case "Agosto": m = ['AGO', y]; break;
                    case "Septiembre": m = ['SEP', y]; break;
                    case "Octubre": m = ['OCT', y]; break;
                    case "Noviembre": m = ['NOV', y]; break;
                    case "Diciembre": m = ['DEC', y]; break;
                    default: m = ''; break;
                }

                return m;
            }

            data.forEach(element => {
                if (element === null || element === undefined) {
                    return null;
                }
                else {
                    labels.push(createMonthString(element));
                    chartData.push(element[this.props.param].toFixed(0));
                    //chartData.push(element['points'])
                    //chartData.push(element['ptssum'])

                }
            });

            return {
                labels: labels,
                datasets: [
                    {
                        label: this.props.subtitle,
                        fill: true,
                        backgroundColor: gradientStroke,
                        borderColor: pointColor,
                        borderWidth: 2,
                        borderDash: [],
                        borderDashOffset: 0.0,
                        pointBackgroundColor: pointColor,
                        pointBorderColor: "rgba(0,0,0,0)",
                        pointHoverBackgroundColor: pointColor,
                        pointBorderWidth: 20,
                        pointHoverRadius: 4,
                        pointHoverBorderWidth: 15,
                        pointRadius: 4,
                        data: chartData
                    }
                ]
            };
        })
    }

    render() {
        return (<Col lg={this.props.lg || "6"} md={this.props.md || "6"} sm="12" xs="12" className="pointChart text-center">
            <Card>
                <div className="text-left chartTitle">
                    <h4>{this.props.title || 'Titulo'}</h4>
                    <h6>{this.props.subtitle || 'Subtitulo'}: <NumberFormat value={this.state.total || 0} displayType={'text'} thousandSeparator={true} decimalScale={2} /></h6>
                </div>
                <Row>
                    <Col md="12" lg='12'>
                        {this.state.data != null ?

                            <div className="content text-center">
                                <Line data={this.state.data} options={this.chartOptions} />
                            </div>

                            :
                            <CardBody>
                                {this.props.loadingSpinner}
                                <p>{this.labels.lblLoading}</p>
                            </CardBody>
                        }
                    </Col>
                </Row>
            </Card>
        </Col>)
    }
}

class GenderSummary extends React.Component {
    constructor(props) {
        super(props)
        this._isMounted = false;
        this.state = { data: null }
    }

    async componentDidMount() {
        this._isMounted = true;
        try {
            if (this._isMounted) {
                let cacheId = 'CustomSegmentGenderSummary' + this.props.segment.segmentName;
                if (this.props.dataRefresh) {
                    cacheId += Math.floor(Math.random() * 1001);
                }
                let data = await this.props.askFor('segments', 'segments/getcustomsegmentsummarybygender', [], false, { segmentConfig: this.props.segment }, cacheId)
                if (data != null) {
                    this.setState({ data: data })
                }
            }
        } catch (err) {
            console.log(err);
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        return (<Container fluid>
            <Row>
                <Col>
                    <Card className="customDetailGenderSummary">
                        <CardBody>
                            <Row>
                                {this.state.data != null ?
                                    this.state.data.map((obj, index) => {
                                        var genderClass = obj._id == 'HOMBRE' ? "M" : "F";
                                        if (obj.count > 0) {
                                            return (<Col key={index} className="text-center">
                                                <p className={"noMargin " + genderClass}><i className={obj._id == 'HOMBRE' ? "fa fa-male" : "fa fa-female"} /></p>
                                                <p className={"noMargin " + genderClass}><NumberFormat value={obj.count} decimalScale={2} displayType={'text'} thousandSeparator={true} /></p>
                                                <p className={"noMargin "}><small><NumberFormat value={obj.percFromTotal} decimalScale={2} displayType={'text'} />%</small></p>
                                                <p className={"noMargin " + genderClass}><b>{obj._id == 'HOMBRE' ? this.props.labels.lblMen : this.props.labels.lblWomen}</b></p>
                                                <hr />
                                                <p>{this.props.labels.lblAvgTicket} <NumberFormat className={genderClass} value={obj.avgTicket} prefix={this.props.currencySymbol} displayType={'text'} thousandSeparator={true} decimalScale={0} /></p>
                                            </Col>)
                                        }
                                    })
                                    :
                                    this.props.loadingSpinner}
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>)
    }
}

class FavProducts extends React.Component {
    constructor(props) {
        super(props)
        this._isMounted = false;
        this.state = { data: null, topProduct: null };
        this.labels = this.props.labels;
    }

    //0. API CALLS ------------------------------------------------------------------------------------------------------------------------------
    async componentDidMount() {
        this._isMounted = true;
        try {
            let cacheId = "FavProdsCustomSegment" + this.props.segment.segmentID;
            if (this.props.dataRefresh) {
                cacheId += Math.floor(Math.random() * 1001);
            }
            let data = await this.props.askFor('segments', 'segments/gettopproductsbycustomsegment', [], false, { segmentConfig: this.props.segment }, cacheId)
            if (data != null) {
                if (Array.isArray(data) && (data.length > 0) && (this._isMounted)) {
                    this.setState({ data: data, topProduct: data[0] });
                }
            }
        } catch (err) {
            console.log(err);
        }


    }

    async componentDidUpdate(prevProps) {
        if (prevProps.segment != this.props.segment) {
            if (this.props.empty == undefined) {
                try {
                    this.setState({ data: null })
                    let cacheId = "FavProdsSegment" + this.props.segment.segmentID;
                    if (this.props.dataRefresh) {
                        cacheId += Math.floor(Math.random() * 1001);
                    }
                    let data = await this.props.askFor('segments', 'segments/gettopproductsbysegment', [], false, this.props.segment, cacheId)
                    if (data != null) {
                        if (Array.isArray(data) && (data.length > 0) && (this._isMounted)) {
                            this.setState({ data: data, topProduct: data[0] });
                        }
                    }
                } catch (err) {
                    console.log(err);
                }
            }

        }

        if (prevProps.labels != this.props.labels) {
            this.labels = this.props.labels;
            if (this._isMounted) {
                this.forceUpdate();
            }
        }

    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    //1. RENDER ---------------------------------------------------------------------------------------------------------------------------------

    render() {
        if (this.state.data != null) {
            return (<Container fluid className="favProdByCustomSegment">
                <Row>
                    <Col lg="12" md="12" sm="12">
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col lg="3" md="3" sm="3">
                                        <h2>{this.labels.lblTop10}</h2>
                                    </Col>
                                    <Col className="favProd" lg="9" md="9" sm="9">
                                        <h4> <i className="ti-star"></i> {this.labels.lblBestSeller}</h4>
                                        <p>{this.state.topProduct.name}</p>
                                        <p>SKU: {this.state.topProduct.sku}</p>
                                    </Col>
                                    <Col lg="12" md="12" sm="12">
                                        <Table responsive className="productTable">
                                            <thead>
                                                <tr>
                                                    <th>Top</th>
                                                    <th>SKU</th>
                                                    <th>{this.labels.lblProductName}</th>
                                                    <th>{this.labels.lblPurchases}</th>
                                                    <th>{this.labels.lblVisitCounter}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.data.map((element, key) => {
                                                    return (
                                                        <tr key={key}>
                                                            <td><b>{key + 1}</b></td>
                                                            <td><p>{element.sku}</p></td>
                                                            <td><b>{element.name}</b></td>
                                                            <td><b>{element.times}</b></td>
                                                            <td>
                                                                <NumberFormat
                                                                    displayType="text"
                                                                    value={element.amount}
                                                                    prefix={this.props.currencySymbol}
                                                                    decimalScale={2}
                                                                    thousandSeparator={true} />
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>)
        }
        else {
            return (<div className="text-center" style={{ paddingTop: "15px" }}>
                {this.props.loadingSpinner}
                <h2>{this.labels.lblLoading}</h2>
            </div>)
        }

    }

}

class ClientList extends React.Component {
    constructor(props) {
        super(props)
        this._isMounted = false;
        this._pageSize = 10;
        this.state = {
            totalCount: 0,
            data: null,
            page: 0,
            sortdirection: -1,
            textSearch: "",
            dropdownOpen: false,
            dropDownValue: "NOMBRE",
            dropDownLabel: this.props.labels.lblNameDrop
        };
        //Function Binding
        this.nextPage = this.nextPage.bind(this);
        this.prevPage = this.prevPage.bind(this);
        this.firstPage = this.firstPage.bind(this);
        this.lastPage = this.lastPage.bind(this);
    }

    // 1. API CALLS ------------------------------------------------------------------------------------------------------------------------------
    async componentDidMount() {
        this._isMounted = true;
        try {
            let cacheId = 'CustomSegmentGetClients' + this.props.segment.segmentName + this.state.page;
            if (this.props.dataRefresh) {
                cacheId += Math.floor(Math.random() * 1001);
            }
            this.props.segment["page"] = this.state.page;
            this.props.segment["textSearch"] = this.state.textSearch;
            let data = await this.props.askFor('crm', 'crm_bucket/searchclientsofcustomsegment', [], false, { segmentConfig: this.props.segment }, cacheId);
            if (this._isMounted) {
                if (data != null) {
                    this.setState({ data: data.clients, totalCount: data.count })
                }

            }
        } catch (err) {
            console.log(err);
        }
    }

    async componentDidUpdate(prevProps, prevState) {
        if (prevState.page != this.state.page) {
            try {
                if (this._isMounted) { this.setState({ data: null }) }
                let cacheId = 'CustomSegmentGetClients' + this.props.segment.segmentName + this.state.page;
                if (this.props.dataRefresh) {
                    cacheId += Math.floor(Math.random() * 1001);
                }
                this.props.segment["page"] = this.state.page;
                this.props.segment["textSearch"] = this.state.textSearch;
                this.props.segment["sort"] = this.state.dropDownValue;
                this.props.segment["sortdirection"] = this.state.sortdirection;
                let data = await this.props.askFor('crm', 'crm_bucket/searchclientsofcustomsegment', [], false, { segmentConfig: this.props.segment }, cacheId);
                if (this._isMounted) {
                    if (data != null) {
                        this.setState({ data: data.clients, totalCount: data.count })
                    }
                }
            } catch (err) {
                console.log(err);
            }
        }

        if (prevState.dropDownValue != this.state.dropDownValue) {
            try {
                if (this._isMounted) { this.setState({ data: null }) }
                let cacheId = 'CustomSegmentGetClients' + this.props.segment.segmentName + this.state.page + this.state.textSearch + this.state.dropDownValue;
                this.props.segment["page"] = this.state.page;
                this.props.segment["textSearch"] = this.state.textSearch;
                this.props.segment["sort"] = this.state.dropDownValue;
                this.props.segment["sortdirection"] = this.state.sortdirection;
                let data = await this.props.askFor('crm', 'crm_bucket/searchclientsofcustomsegment', [], false, { segmentConfig: this.props.segment }, cacheId);
                if (this._isMounted) {
                    if (data != null) {
                        this.setState({ data: data.clients, totalCount: data.count })
                    }
                }
            } catch (err) {
                console.log(err);
            }
        }

        ReactTooltip.rebuild();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    //2. HANDLE PAGE FUNCTION ---------------------------------------------------------------------------------------------------------------------

    nextPage() {
        let clientsCount = this.state.totalCount;
        let pageCount = Math.ceil(clientsCount / this._pageSize);
        if (this.state.page + 1 < pageCount) {
            this.setState(state => ({ page: state.page + 1 }))
        }
        else {
            //reach last page
        }
    }

    prevPage() {
        if (this.state.page == 0) { return /*Reach firts page */ }
        this.setState(state => ({ page: state.page - 1 }))
    }

    firstPage() {
        let currentPage = this.state.page;
        if (currentPage > 0) {
            this.setState({ page: 0 })
        }
    }

    lastPage() {
        let currentPage = this.state.page;
        let clientsCount = this.state.totalCount;
        let pageCount = Math.ceil(clientsCount / this._pageSize);
        if (currentPage < pageCount) {
            this.setState({ page: pageCount - 1 })
        }
    }

    //3.CONFIG SEARCH FUNCTIONS ---------------------------------------------------------------------------------------------------------------
    toggleDropdown = () => {
        this.setState(state => { return ({ dropdownOpen: !state.dropdownOpen }) })
    }

    changeDropdownValue = (value) => {
        var label = "";
        var val = "";

        switch (value) {
            case 'name': label = this.props.labels.lblNameDrop; val = "NOMBRE"; break;
            case 'amount': label = this.props.labels.lblAmountDrop; val = "TXAMOUNTSUM"; break;
            case 'visits': label = this.props.labels.lblVisitsDrop; val = "TXCOUNT"; break;
        }


        if (this._isMounted) {
            this.setState({ dropDownValue: val, dropDownLabel: label })
        }
    }

    handleTextSearch(val) {
        if (this._isMounted) {
            this.setState({ textSearch: val })
        }
    }

    async searchClients() {
        try {
            if (this._isMounted) { this.setState({ data: null }) }
            let cacheId = 'CustomSegmentGetClients' + this.props.segment.segmentName + this.state.page + this.state.textSearch;
            this.props.segment["page"] = this.state.page;
            this.props.segment["textSearch"] = this.state.textSearch;
            let data = await this.props.askFor('crm', 'crm_bucket/searchclientsofcustomsegment', [], false, { segmentConfig: this.props.segment }, cacheId);
            if (this._isMounted) {
                if (data != null) {
                    this.setState({ data: data.clients, totalCount: data.count })
                }
            }
        } catch (err) {
            console.log(err);
        }
    }

    // 4. PRE-RENDER FUNCTIONS --------------------------------------------------------------------------------------------------------------
    getEmail = (email) => {
        var r = null;

        if (this.props.isFieldHidden('email')) {
            return;
        } else {
            if (email == null || email == undefined) {
                r = <th>Email</th>;
            } else {
                r = <td>{email}</td>;
            }

        }

        return r;
    }

    //5. RENDER ------------------------------------------------------------------------------------------------------------------------------
    render() {
        return (<Container fluid>
            <ReactTooltip id="crmClientsTooltip" place="left" textColor="#1c79cd" backgroundColor="#c7e1f9" />
            <h3>{this.props.labels.lblClientTableTitle}</h3>
            <Row className="crmSegmentsBar">
                <Col lg="7" md="7" sm="12" style={{ display: "flex" }}>
                    <input
                        type="text"
                        className="form-control border-input noRadius"
                        placeholder={this.props.labels.lblSearchPlaceHolder}
                        onChange={e => { this.handleTextSearch(e.target.value) }}
                    />
                    <Button
                        className="btnExport noRadius" onClick={() => { this.searchClients() }}>
                        <i className="ti-search" />
                    </Button>
                </Col>
                <Col lg="5" md="5" sm="12" style={{ display: "flex" }}>
                    <label>Ordenar Por:</label>
                    <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggleDropdown}>
                        <DropdownToggle>
                            <span>{this.state.dropDownLabel} <i className="ti-angle-down" /></span>
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem onClick={() => { this.changeDropdownValue('name') }}>{this.props.labels.lblNameDrop}</DropdownItem>
                            <DropdownItem onClick={() => { this.changeDropdownValue('amount') }}>{this.props.labels.lblAmountDrop}</DropdownItem>
                            <DropdownItem onClick={() => { this.changeDropdownValue('visits') }}>{this.props.labels.lblVisitsDrop}</DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                    <Button className="btnExport" onClick={() => { this.props.toggleSegmentExport() }}><i className="ti-download" /> {this.props.labels.lblExportDrop}</Button>
                </Col>
            </Row>
            {this.state.data != null ?
                <>
                    <Table responsive className="crmSegments" striped={true}>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>{this.props.labels.lblSorterName}</th>
                                <th>{this.props.labels.lblSorterLastName}</th>
                                {this.getEmail()}
                                <th>{this.props.labels.lblSorterAge}</th>
                                <th>{this.props.labels.lblSorterCount}</th>
                                <th>{this.props.labels.lblVisitCounter}</th>
                                <th>{this.props.labels.lblActions}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.data.map((element, key) => {
                                return (<tr key={key}>
                                    <td>{element.CLIENTE}</td>
                                    <td>{element.NOMBRE}</td>
                                    <td>{element.PRIMERAPELLIDO}</td>
                                    {this.getEmail(element.EMAIL)}
                                    <td>{element.EDAD}</td>
                                    <td><NumberFormat value={element.TXAMOUNTSUM} prefix={this.props.currencySymbol} displayType={'text'} thousandSeparator={true} /></td>
                                    <td>{element.TXCOUNT}</td>
                                    <td><Button data-tip="Ver clientes" data-for="crmClientsTooltip" onClick={() => { this.props.openClientDetail(element.CLIENTE) }}>{/* {this.props.labels.btnViewClients} */} <i className="ti-eye"/></Button></td>
                                </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                    {this.state.totalCount > 10 ?
                        <Row className="tableRowButtons">
                            <Col lg="12" md="12" sm="12">
                                <h4>{this.props.labels.lblPaginator}: {this.state.page + 1}</h4>
                            </Col>
                            <Col lg="12" md="12" sm="12">
                                <button className="pager-button" onClick={() => { this.firstPage() }}>{this.props.labels.lblButtonFirst}</button>
                                <button className="pager-button" onClick={() => { this.prevPage() }}>
                                    <i className="ti-angle-left"></i></button>
                                <button className="pager-button" onClick={() => { this.nextPage() }}>
                                    <i className="ti-angle-right"></i>
                                </button>
                                <button className="pager-button" onClick={() => { this.lastPage() }}>{this.props.labels.lblButtonLast}</button>
                            </Col>
                        </Row>
                        :
                        ""
                    }
                </>
                :
                <Row>
                    {this.props.loadingSpinner}
                </Row>}
        </Container>)
    }
}


// B. MAIN COMPONENT
class CustomSegmentDetail extends React.Component {
    constructor(props) {
        super(props)        //State = | screen:string -> 'segment' or 'client' | purchasesFromTotal:int | tab:string | clientId:string -> ID of the client to show when open the client Detail |
        this.state = { screen: 'segment', percPurchasesFromTotal: 0, tab: "1", clientId: null };
        //this.labels = new SegmentDetailLang(props.lang)
        this.labels = props.labels;
        //FUNCTION BINDING
        this.handlePurchasePercentage = this.handlePurchasePercentage.bind(this);
        this.openClientDetail = this.openClientDetail.bind(this);
        this.closeClientDetail = this.closeClientDetail.bind(this);
        this.changeTabs = this.changeTabs.bind(this);
        this.dummyFunction = this.dummyFunction.bind(this);
    }

    //0. API CALLS -----------------------------------------------------------------------------------------------------------------------------
    async componentDidUpdate(prevProps, prevState) {
        if (prevState.screen != this.state.screen) {
            window.scrollTo(0, 0);
        }

        if (prevProps.lang != this.props.lang) {
            this.labels = this.props.labels;
            this.forceUpdate();
        }

        if (prevProps.labels != this.props.labels) {
            this.labels = this.props.labels;
            this.forceUpdate();
        }
    }

    //1. DATA HANDLERS -----------------------------------------------------------------------------------------------------------------------------
    handlePurchasePercentage(perc) {
        this.setState({ percPurchasesFromTotal: perc })
    }


    //2. NAVIGATION FUNCTION -----------------------------------------------------------------------------------------------------------------------------
    openClientDetail(clientId) {
        this.setState({ clientId: clientId, screen: "client" })
    }

    closeClientDetail() {
        this.setState({ clientId: null, screen: 'segment' })
    }

    dummyFunction() {
        console.log('dummy')
    }

    changeTabs(tab) {
        this.setState({ tab: tab });
    }

    getMainScreen() {
        var mainScreen = null;
        switch (this.state.screen) {
            case "segment": mainScreen = <>
                <Container fluid className="customSegmentDetail">
                    <Row className="backButtonCol">
                        <Button onClick={() => { this.props.openSegmentList() }}><span>&#10229;</span>  {this.labels.lblButtonBack}</Button>
                    </Row>
                    <Row>{/*Summary Card */}
                        <Col>
                            <SegmentListItem
                                showBtnBack
                                navigateBack={this.props.openSegmentList}
                                segment={this.props.segment}
                                currencySymbol={this.props.currencySymbol}
                                askFor={this.props.askFor}
                                handlePurchasePercentage={this.handlePurchasePercentage}
                                labels={this.labels}
                                openSegmentDetail={this.props.refreshSegmentDetail}
                                loadingSpinner={this.props.loadingSpinner}
                            />
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: "35px" }}>{/* NAVIGATION TABS */}
                        <Nav className="segmentTabs" tabs>
                            <NavItem>
                                <NavLink onClick={() => this.changeTabs("1")} className={classnames({ active: this.state.tab == "1" })}>{this.labels.btnShowSummary}</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink onClick={() => this.changeTabs("2")} className={classnames({ active: this.state.tab == "2" })}>{this.labels.btnViewClients}</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink onClick={() => this.changeTabs("3")} className={classnames({ active: this.state.tab == "3" })}>{this.labels.btnPurchaseCharts}</NavLink>
                            </NavItem>
                            {this.props.isSectionHidden('favprods') ? " " :
                                <NavItem>
                                    <NavLink onClick={() => this.changeTabs("4")} className={classnames({ active: this.state.tab == "4" })}>{this.labels.btnFavoriteProducts}</NavLink>
                                </NavItem>
                            }
                        </Nav>
                    </Row>
                    <Row>{/* CONTENT */}
                        <TabContent activeTab={this.state.tab} className="container-fluid tabCont" ref={this.myRef}>

                            <TabPane tabId="1">{/* Segment Summary */}
                                <Row>{/*Doughnut and Gender Summary */}
                                    <Col>{/* Gender Summary*/}
                                        <GenderSummary
                                            askFor={this.props.askFor}
                                            segment={this.props.segment}
                                            labels={this.labels}
                                            currencySymbol={this.props.currencySymbol}
                                            loadingSpinner={this.props.loadingSpinner}
                                            dataRefresh={this.props.dataRefresh}
                                        />
                                    </Col>
                                    <Col>{/* Doughnut */}
                                        <Card className="doughnut">
                                            <CardBody>
                                                {this.state.percPurchasesFromTotal != null ?
                                                    <Row style={{ alignItems: "center" }}>
                                                        <Col lg="6" sm="6" xs="6">
                                                            {this.state.tab == "1" ?
                                                                <Doughnut
                                                                    data={{
                                                                        datasets: [{
                                                                            data: [this.state.percPurchasesFromTotal, 100 - this.state.percPurchasesFromTotal],
                                                                            backgroundColor: [this.props.segment.segmentColor, '#cdb989'],
                                                                            borderColor: "rgba(0,0,0,0)"
                                                                        }],
                                                                        labels: [""]
                                                                    }}
                                                                    options={{
                                                                        legend: { display: false, position: "bottom" },
                                                                        maintainAspectRatio: false,
                                                                        tooltips: { enabled: false },
                                                                        hover: { mode: null },
                                                                    }}
                                                                />
                                                                : this.props.loadingSpinner}
                                                        </Col>
                                                        <Col lg="6" sm="6" xs="6">
                                                            <h4>{this.state.percPurchasesFromTotal}%</h4>
                                                            <p className="noMargin">{this.labels.lblDoughnutTitle}</p>
                                                        </Col>
                                                    </Row>
                                                    : ""}
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>

                                <Row>
                                    <PointChart
                                        title={this.labels.lblPointChartTitle}
                                        subtitle={this.labels.lblPointChartSubTitle}
                                        endpoint="segments/getcustomsegmentpointsummary"
                                        param="points" segment={this.props.segment} labels={this.labels}
                                        color={this.props.segment.segmentColor}
                                        askFor={this.props.askFor}
                                        loadingSpinner={this.props.loadingSpinner}
                                        dataRefresh={this.props.dataRefresh}
                                        theme={this.props.theme}
                                    />
                                    {this.props.isSectionHidden('segmentdetails_redeemed_points_permonth') ? "" :
                                        <PointChart
                                            title={this.labels.lblRedemChartTitle}
                                            subtitle={this.labels.lblRedemChartSubTitle}
                                            endpoint="segments/getcustomsegmentredemsummary"
                                            param="points" segment={this.props.segment}
                                            labels={this.labels}
                                            color={this.props.segment.segmentColor}
                                            askFor={this.props.askFor}
                                            loadingSpinner={this.props.loadingSpinner}
                                            dataRefresh={this.props.dataRefresh}
                                            theme={this.props.theme}
                                        />
                                    }
                                </Row>
                            </TabPane>


                            <TabPane tabId="2">{/* Client List */}
                                <Row>{/*Client Table */}
                                    <Col>
                                        <Card style={{ width: "100%" }}>
                                            <CardBody>
                                                <ClientList
                                                    segment={this.props.segment}
                                                    currencySymbol={this.props.currencySymbol}
                                                    isFieldHidden={this.props.isFieldHidden}
                                                    askFor={this.props.askFor}
                                                    labels={this.labels}
                                                    openClientDetail={this.openClientDetail}
                                                    loadingSpinner={this.props.loadingSpinner}
                                                    dataRefresh={this.props.dataRefresh}
                                                />
                                            </CardBody>
                                        </Card>
                                    </Col>

                                </Row>
                            </TabPane>


                            <TabPane tabId="3">{/* Charts */}
                                <Row className="singleBigChart">
                                    <Col lg="8" md="8" sm="12">
                                        <PointChart
                                            title={this.labels.lblPurchasesChartTitle}
                                            subtitle={this.labels.lblPurchasesChartSubTitle}
                                            endpoint="segments/getcustomsegmentpointsummary"
                                            cacheId="CustomSegmentPurchase"
                                            param="txSum" segment={this.props.segment} labels={this.labels}
                                            color={this.props.segment.segmentColor}
                                            askFor={this.props.askFor}
                                            lg="12"
                                            loadingSpinner={this.props.loadingSpinner}
                                            dataRefresh={this.props.dataRefresh}
                                        />
                                    </Col>
                                </Row>
                            </TabPane>

                            {this.props.isSectionHidden('favprods') ? "" :
                                <TabPane tabId="4">{/* Fav Products */}
                                    <Row>
                                        <Col sm="12" lg="12">
                                            <FavProducts
                                                labels={this.labels}
                                                askFor={this.props.askFor}
                                                currencySymbol={this.props.currencySymbol}
                                                segment={this.props.segment}
                                                loadingSpinner={this.props.loadingSpinner}
                                            />
                                        </Col>
                                    </Row>
                                </TabPane>
                            }


                        </TabContent>
                    </Row>
                </Container>
            </>; break;
            case "client": mainScreen = <>
                <Container fluid>
                    <ClientDetail
                        askFor={this.props.askFor}
                        clientId={this.state.clientId}
                        backFunction={this.closeClientDetail}
                        currencySymbol={this.props.currencySymbol}
                        theme={this.props.theme}
                        lang={this.props.lang}
                        hideRedeem={this.props.isSectionHidden('clientdetails_redeemed_points_permonth')}
                        getBucketLabels={this.props.getBucketLabels}
                        isFieldHidden= {this.props.isFieldHidden}
                    />
                </Container>
            </>; break;
        }

        return mainScreen;
    }


    //3. RENDER ------------------------------------------------------------------------------------------------------------------------------------
    render() {
        return (
            <div>
                {this.getMainScreen()}
            </div>
        )
    }
}

export default CustomSegmentDetail;