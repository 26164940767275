import React from "react";
import classnames from 'classnames';
import Switch from "react-switch";
import {  CSSTransition } from "react-transition-group"
import { FiltersLang } from '../../lang'
// reactstrap components
import {
    Container,
    Row,
    Col,
    Button,
    Label,
    Table,
    Input,
    FormGroup,
} from "reactstrap";


//1. SUB-COMPONENT

var Xbutton = props => {

    // var s = { cursor: "pointer", color: "white" }

    return (<>
        <span className="xButton" onClick={() => { props.onClick(false, this) }} aria-label="Close">&times;</span>
    </>)
}

class SwitchButton extends React.Component {
    constructor(props) {
        super(props)
        this.state = { checked: false }
        this.handleChange = this.handleChange.bind(this);
        this._isMounted = false;

    }

    componentDidMount() {
        this._isMounted = true;
        this.setState({ checked: this.props.ff.check(this.props.filter.id) })
    }

    componentDidUpdate(prevProps) {
        if (prevProps.filter != this.props.filter) {
            this.setState({ checked: this.props.ff.check(this.props.filter.id) })
        }
    }

    componentWillMount() {
        this._isMounted = false;
    }

    handleChange() {
        if (!this.state.checked) {
            this.props.ff.add(this.props.filter);

        }
        else {
            this.props.ff.remove(this.props.filter.id);
        }
    }


    render() {
        return (<Switch className={this.classN} onChange={this.handleChange} checked={this.state.checked} />)
    }
}

class EditFilterPanel extends React.Component {
    constructor(props) {
        super(props)
        this._isMounted = false;
        this.state = { data: null }
    }

    componentDidMount() {
        this._isMounted = true;
        var dat = [];
        try {
            this.props.filters.map((filter) => {
                if (filter.filteroption === "") return; //skips demographic filters.
                this.props.getCatDat(filter.fieldname, filter.pk, "").then(r => {
                    if (r != null) {
                        r.map(obj => {
                            if (obj.id === filter.id) {
                                obj.pk = filter.pk;
                                obj.filteroption = filter.filteroption;
                                obj.fieldname = filter.fieldname;
                                obj.label = filter.label;
                                dat.push(obj)
                            }
                        })
                        this.setState({ data: dat });
                    }
                })

            })


        } catch (err) {
            console.log(err);
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.labels != this.props.labels) {
            if (this._isMounted) {
                this.forceUpdate();
            }
        }
    }

    getButtonClass(id, option) {
        var c = "";
        var options = this.props.ff.getOptions(id);
        if (option === options.positive) c = 'active';
        if (option === options.filteroption) c = 'active';
        if (option === 'N') { c += " spaceR" };
        return c;
    }

    render() {
        return (<>
            <Table responsive className="tableCategoryItems">
                <tbody>
                    {this.state.data != null ? this.state.data.map((element, key) => {
                        return (<tr key={key}>
                            <td><b>{element.label}</b><p className="id">Id: {element.id}</p></td>
                            <CSSTransition classNames="slides" in={true} timeout={1000}>

                                <td className="filterButtonConfig">
                                    <button onClick={() => this.props.ff.changeOptions(element.id, 'SI')} className={this.getButtonClass(element.id, "S")}>SÍ</button>
                                    <button onClick={() => this.props.ff.changeOptions(element.id, 'NO')} className="spaceR" className={this.getButtonClass(element.id, "N")}>NO</button>
                                    <button onClick={() => this.props.ff.changeOptions(element.id, 'ALL')} className={this.getButtonClass(element.id, "ALL")}>TODOS</button>
                                    <button onClick={() => this.props.ff.changeOptions(element.id, 'SIM')} className={this.getButtonClass(element.id, "SIM")}>SIMILARES</button>
                                    <button onClick={() => this.props.ff.changeOptions(element.id, 'PRE')} className={this.getButtonClass(element.id, "PRE")}>PREDICCION</button>
                                </td>

                            </CSSTransition>
                        </tr>)
                    }) : <tr><td>{this.props.labels.lblWait}</td></tr>}

                </tbody>
            </Table>
        </>)
    }
}

class CategoryItemList extends React.Component {
    //PROPS
    //clasification (Array):    Array which contains the different categories of filters, this categories cascade from parents to children
    //level (Int):              Current categorie in which the user is selecting a filter;
    //getCatDat (Function):     Function which recieves the parameters to retrieve the set of available filters for categorie 
    //ff (Object):              Object which contains functions associated with the filters (AddFilter, RemoveFilter, CheckFilters, etc.)
    //labels (Object):          Object which contains the labels to print in the component, used for multilenguage porpuses
    //changeLevel (Function):   Binded function used to change the level of clasification to be presented in the component
    constructor(props) {
        super(props)
        this._isMounted = false;
        this.state = { data: null, textSearch: "", filters: props.ff.get(), parentKeys: [], maxLevel: this.props.clasification.length - 1 }
        this.handleSearch = this.handleSearch.bind(this);
        this.getButtonClass = this.getButtonClass.bind(this);
        this.cleanPK = this.cleanPK.bind(this);
        this.search = this.search.bind(this);
        this.clasification = this.props.clasification[this.props.level];
    }

    //1. DATA GATHERING FUNCTIONS ---------------------------------------------------------------------------------------------------------------
    async componentDidMount() {
        this._isMounted = true
        try {
            let dat = await this.props.getCatDat(this.clasification.name, this.state.parentKeys, this.state.textSearch);
            if (dat != null) {
                let x = dat;
                let data = []
                let filters = this.props.ff.get();
                if (filters.length > 0) { x.map((obj, index, array) => { filters.map(filter => { if (obj.id === filter) { data.push(obj); array.splice(index, 1) } }) }) }//retrieves the active filters and put them on top of the list.
                if (x.length > 100) {
                    for (var i = 0; i < 100; i++) {
                        data.push(x[i]);
                    }
                } else {
                    for (var i = 0; i < x.length; i++) {
                        data.push(x[i]);
                    }
                }


                if (this._isMounted) { this.setState({ data: data }) }
            }
        } catch (err) {
            console.log(err);
        }
    }

    async componentDidUpdate(prevProps) {
        if (prevProps.level != this.props.level) {
            this.clasification = this.props.clasification[this.props.level]; //changes the clasification value;

            //Deletes parentKeys if the user goes down on clasification levels
            if (prevProps.level > this.props.level) {
                var x = null;
                var dif = prevProps.level - this.props.level;
                this.setState(state => {
                    x = state.parentKeys;
                    for (var i = 0; i < dif; i++) { x.pop() }
                    return ({ parentKeys: x })
                })
            }

            try {
                if (this._isMounted) { this.setState({ data: null, textSearch: "" }) }
                let dat = await this.props.getCatDat(this.clasification.name, this.state.parentKeys, this.state.textSearch);
                if (dat != null) {
                    let x = dat;
                    let data = []
                    let filters = this.props.ff.get();
                    if (filters.length > 0) { x.map((obj, index, array) => { filters.map(filter => { if (obj.id === filter) { data.push(obj); array.splice(index, 1) } }) }) }//retrieves the active filters and put them on top of the list.
                    if (x.length > 100) {
                        for (var i = 0; i < 100; i++) {
                            data.push(x[i]);
                        }
                    } else {
                        for (var i = 0; i < x.length; i++) {
                            data.push(x[i]);
                        }
                    }
                    if (this._isMounted) { this.setState({ data: data }) }
                }
            } catch (err) {
                console.log(err);
            }
        }

        if (prevProps.labels != this.props.labels) {
            if (this._isMounted) {
                this.forceUpdate()
            }
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    async search() {
        try {
            if (this._isMounted) { this.setState({ data: null }) }
            let dat = await this.props.getCatDat(this.clasification.name, this.state.parentKeys, this.state.textSearch);
            if (dat != null) {
                let x = dat;
                let data = []
                if (x.length > 100) {
                    for (var i = 0; i < 100; i++) {
                        data.push(x[i]);
                    }
                }
                else {
                    data = x;
                }

                if (this._isMounted) { this.setState({ data: data }) }
            }
        } catch (err) {
            console.log(err);
        }
    }



    //2. EVENT HANDLING FUNCTIONS ---------------------------------------------------------------------------------------------------------------
    handleSearch(event) {
        let val = event.target.value;
        val.replace(/[^a-zA-Z ]/g, "")
        this.setState({ textSearch: val })
    }

    onEnterPress(event) {
        var code = event.keyCode || event.which;
        if (code === 13) {
            this.search();
        }
    }


    //3. PRE-RENDER FUNCTIONS ---------------------------------------------------------------------------------------------------------------
    getButtonClass(id, option) {
        var c = "";
        var options = this.props.ff.getOptions(id);
        if (option === options.positive) c = 'active';
        if (option === options.filteroption) c = 'active';
        if (option === 'N') { c += " spaceR" }
        return c;
    }

    getPanelTitle() {
        var label = this.clasification.label;
        var length = this.state.parentKeys.length;
        if (length > 0) {
            label = this.state.parentKeys[length - 1].id
        }
        return label;
    }


    //4. UTILITY FUNCTIONS --------------------------------------------------------------------------------------------------------------------
    changeLevel(level, pk) {
        if (level <= this.state.maxLevel) {
            this.setState(state => {
                const p = state.parentKeys.concat(pk)
                return { parentKeys: p, textSearch: '' }
            })
            this.props.changeLevel(level);
        }
    }

    cleanPK() {
        this.setState(() => ({ parentKeys: [] }));
    }

    //5. RENDER -------------------------------------------------------------------------------------------------------------------------------
    render() {
        var nextLevel = ""
        if (this.props.level < this.state.maxLevel) { nextLevel = this.props.clasification[this.props.level + 1].label; }

        return (<>
            <h3>{this.props.labels.lblFIltersAvailable + " " + this.getPanelTitle()}</h3>
            <FormGroup className="selectFilterForm" onKeyPress={(e) => { this.onEnterPress(e) }}>
                <Row>
                    <Col lg="10" md="8" sm="8"><Input type="text" placeholder="Texto para buscar" onChange={this.handleSearch} value={this.state.textSearch} /></Col>
                    <Col lg="2" md="4" sm="4"><Button className="btnFilterSearch" onClick={this.search}><i className="ti-search" />  {this.props.labels.lblSearchInCategories}</Button></Col>
                </Row>
                <Row>
                    <Table responsive className="tableCategoryItems">
                        <tbody>
                            {this.state.data != null ? this.state.data.map((element, key) => {
                                return (<tr key={key}>
                                    <td>
                                        <b>{element.label}</b>
                                        <p className="id">Id: {element.id}</p>
                                    </td>
                                    {this.props.ff.check(element.id) ?
                                        <CSSTransition classNames="slides" in={true} timeout={1000}>
                                            <>
                                                <td>
                                                    <SwitchButton ff={this.props.ff} filter={{ id: element.id }} />
                                                </td>
                                                <td className="filterButtonConfig">
                                                    <button onClick={() => this.props.ff.changeOptions(element.id, 'SI')} className={this.getButtonClass(element.id, "S")}>SÍ</button>
                                                    <button onClick={() => this.props.ff.changeOptions(element.id, 'NO')} className={this.getButtonClass(element.id, "N")}>NO</button>
                                                    <button onClick={() => this.props.ff.changeOptions(element.id, 'ALL')} className={this.getButtonClass(element.id, "ALL")}>TODOS</button>
                                                    <button onClick={() => this.props.ff.changeOptions(element.id, 'SIM')} className={this.getButtonClass(element.id, "SIM")}>SIMILARES</button>
                                                    <button onClick={() => this.props.ff.changeOptions(element.id, 'PRE')} className={this.getButtonClass(element.id, "PRE")}>PREDICCION</button>
                                                </td>
                                            </>
                                        </CSSTransition>
                                        :
                                        <>
                                            <td className="floatR">{this.props.level === this.state.maxLevel ? "" : <button onClick={() => this.changeLevel(this.props.level + 1, { name: this.clasification.name, id: element.id })}>{nextLevel}  {'->'}</button>}</td>
                                            <td className="floatR"><SwitchButton ff={this.props.ff} filter={{ id: element.id, label: element.label, fieldname: this.clasification.name, filteroption: "ALL", name: element.label, pk: this.state.parentKeys, productTableField: this.clasification.productsTableIdField }} cleanPK={this.cleanPK} /></td>
                                        </>
                                    }
                                </tr>)
                            }) : <tr><td>{this.props.labels.lblWait}</td></tr>}
                        </tbody>
                    </Table>
                </Row>
            </FormGroup>
        </>)
    }
}


//2. MAIN COMPONENT
class Filtros extends React.Component {
    constructor(props) {
        super(props);
        //Creation of labels in the selected lengauge
        this._isMounted = false;
        this.labels = FiltersLang(props.lang);
        let screen = props.filters.length > 0 ? 'hasFilter' : 'addFilter';
        this.state = { screen: screen, openFilterPanel: false, filters: props.filters, filterPanelType: "", level: 0 };
        //Binding of utility functions
        this.toggleFilterPanel = this.toggleFilterPanel.bind(this);
        this.changeClasificationLevel = this.changeClasificationLevel.bind(this);
        this.toggleFilterEditPanel = this.toggleFilterEditPanel.bind(this);
        //Binding of filter function
        this.addFilter = this.addFilter.bind(this);
        this.removeFilter = this.removeFilter.bind(this);
        this.checkFilter = this.checkFilter.bind(this);
        this.applyFilter = this.applyFilter.bind(this);
        this.getFilters = this.getFilters.bind(this);
        this.changeFilterOption = this.changeFilterOption.bind(this);
        this.getFilterOption = this.getFilterOption.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.level != this.state.level) {
            if (this._isMounted) {
                this.setState(state => ({ level: state.level }))
            }

        }

        if (prevProps.lang != this.props.lang) {
            if (this._isMounted) {
                this.labels = FiltersLang(this.props.lang)
            }
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    // 1. FILTER MANAGEMENT FUNCTIONS -------------------------------------------------------------------------------------
    addFilter = (data) => {
        if (data === null || data === undefined) data = { id: "", name: "", flagvariable: "", positive: true, filteroption: "" };
        if (data.filteroption === null || data.filteroption === undefined) data.filteroption = "";
        if (data.flagvariable === null || data.flagvariable === undefined) data.flagvariable = null;

        var filter = {
            id: data.id,
            name: data.name,
            flagvariable: data.flagvariable,
            positive: true,
            filteroption: data.filteroption,
            pk: data.pk,
            productTableField: data.productTableField,
        }

        if (data.label != null || data.label != undefined) { filter.label = data.label }
        if (data.fieldname != null || data.fieldname != undefined) { filter.fieldname = data.fieldname }

        this.setState(state => {
            const fil = state.filters.concat(filter)
            return { filters: fil }
        });
    }

    checkFilter = (type) => {
        var r = false
        if (!this.state.filters.length === 0) {
            this.state.filters.map(filter => {
                if (filter.id === type) {
                    r = true
                }
            })
        }

        return r;
    }

    removeFilter = type => {
        this.setState(state => {
            const filters = state.filters.filter((item) => item.id !== type);
            return { filters };
        }, () => { this.applyFilter() })
    }

    hasFilter = () => {
        if (this.state.filters.length === 0) {
            return false;
        } else {
            return true;
        }
    }

    applyFilter = () => {
        if (this.state.filters.length === 0) {
            if (this._isMounted) {
                this.setState({ screen: 'addFilter' })
            }
        }
        this.props.applyFilters(this.state.filters);
    }

    getFilters = () => {
        var r = [];
        this.state.filters.map(filter => {
            r.push(filter.id);
        })
        return r;
    }

    changeFilterOption = (id, option) => {
        var f = []
        this.state.filters.map(filter => {
            if (filter.id === id) {
                switch (option) {
                    case 'SI':
                        filter.positive = true;
                        break;

                    case 'NO':
                        filter.positive = false;
                        break;
                    default:
                        filter.filteroption = option;
                        break;
                }
            }
            f.push(filter);
        })

        this.setState({ filters: f }, () => { this.applyFilter() });
    }

    getFilterOption = (id) => {
        var r = {}
        let pol = "S"
        this.state.filters.map(filter => {
            if (filter.id === id) {
                filter.positive ? pol = "S" : pol = "N"
                r.positive = pol;
                r.filteroption = filter.filteroption;
            }
        })
        return r;
    }

    hasCategorizationFilter = () => {
        var hasCatFilter = false;
        this.state.filters.map(filter => {
            if (filter.filteroption !== "") {
                hasCatFilter = true;
            }
        })

        return hasCatFilter;
    }

    filterFunctions = { add: this.addFilter, check: this.checkFilter, remove: this.removeFilter, applyF: this.applyFilter, get: this.getFilters, changeOptions: this.changeFilterOption, getOptions: this.getFilterOption }

    // 2. UTILITY FUNCTIONS -----------------------------------------------------------------------------------------------
    toggleFilterPanel = (bool, key, level) => {
        if (level === undefined) level = 0;
        if (key === undefined) key = "";
        this.setState({ openFilterPanel: bool, filterPanelType: key, level: level });
        this.props.toggleFilterSelectorFocus(bool);
    }

    createAppliedFilters = () => {
        var r = this.state.filters.map((filter, key) => {
            return (
                <Col lg="3" md="6" sm="12" key={key}>
                    <CSSTransition in={this.hasFilter()} classNames="animated" timeout={1000}>
                        <div className={classnames({ appliedFilters: true, 'text-center': true, min: filter.name.length > 20 })}>
                            <i>{filter.name}</i>
                            <span aria-label="Close" className="ti-close deleteFilter" onClick={() => { this.removeFilter(filter.id); this.toggleFilterPanel(false) }}></span>
                        </div>
                    </CSSTransition>
                </Col>
            )
        })

        return r;
    }

    changeClasificationLevel = (level) => {
        this.setState({ level: level });
    }

    toggleFilterEditPanel = () => {
        this.setState({ filterPanelType: 'EDIT', openFilterPanel: true });
        this.props.toggleFilterSelectorFocus(true);
    }

    // 3. PRE-RENDER FUNCTIONS TO DETERMINE WHAT AND WHERE TO PAINT IN SCREEN ---------------------------------------------

    //renders filters on top bar
    getAppliedFiltersLabel = () => {
        var r = <></>
        if (this.state.screen === "addFilter" && this.hasFilter()) {
            r =
                <Row style={{ paddingLeft: "20px" }}>
                    <h4>{this.labels.lblFiltersToApply} </h4>
                    {this.createAppliedFilters()}
                </Row>

        }
        else if (this.state.screen !== "addFilter" && this.hasFilter()) {
            r = <h4> {this.labels.lblAppliedFilters}</h4>
        }

        return r;
    }

    //renders the top bar, in which the filters are shown
    getHorizontalBar = () => {
        var bar = null;

        switch (this.state.screen) {
            case "addFilter":
                bar = <>
                    <CSSTransition classNames="animated" timeout={1000} in={this.state.screen === "addFilter"}>
                        <Col className="buttonCol" lg="3" md="6" sm="12">
                            <button className="btnFiltros" onClick={(e) => { this.toggleFilterPanel(true, 'DEMOGRAPHIC') }}><i className="ti-plus" /> {this.labels.lblDemographicTitle}</button>
                        </Col>
                    </CSSTransition>
                    {this.props.clasificationlist.map((clasification, key) => {
                        if (key <= this.state.level) {
                            return (
                                <CSSTransition classNames="animated" timeout={1000} key={key} in={this.state.screen == "addFilter"}>
                                    <Col lg="3" md="6" sm="12">
                                        <button className={this.state.level != key || key == 0 ? "btnFiltros" : "btnFiltros active"} onClick={(e) => this.toggleFilterPanel(true, "CATEGORIES", key, e)}><i className="ti-plus" /> {this.labels.lblFIlterBy + clasification.label}</button>
                                    </Col>
                                </CSSTransition>
                            )
                        }
                    })}
                    {this.hasFilter() ?
                        <CSSTransition classNames="animated" timeout={1000} in={this.state.screen === "addFilter"}>
                            <Col lg="3" md="6" sm="12" className="clearFilterCol">
                                <button className="btnClearFiltros" onClick={() => { this.toggleFilterPanel(false); this.setState({ screen: 'hasFilter' }); this.props.applyFilters(this.state.filters) }}> {this.labels.lblApply} </button>
                            </Col>
                        </CSSTransition>
                        :
                        <CSSTransition classNames="animated" timeout={1000} >
                            <Col><span className="xButton" onClick={() => { this.toggleFilterPanel(false); this.props.toogleFilterBar(false); this.props.applyFilters([]) }} aria-label="Close">&times;</span> </Col>
                        </CSSTransition>
                    }

                </>
                    ; break;

            case "hasFilter":
                bar = <>
                    {this.createAppliedFilters()}
                    <Col lg="3" md="6" sm="12">
                        <CSSTransition in={true} classNames="animated" timeout={1000}>
                            <button className="btnEditFilters" onClick={() => { this.setState({ screen: "addFilter" }) }}>{this.labels.lblAddFilter} <i className="ti-plus" /></button>
                        </CSSTransition>
                    </Col>
                    <Col lg="3" md="6" sm="12" className="clearFilterCol ml-auto">
                        {this.hasCategorizationFilter() ?
                            <CSSTransition in={true} classNames="animated" timeout={1000} >
                                <button className="btnEditFilters" onClick={() => this.toggleFilterEditPanel()}>{this.labels.lblEditFilters}</button>
                            </CSSTransition>
                            : ""}
                        <CSSTransition in={true} classNames="animated" timeout={1000}  >
                            <button className="btnClearFiltros" onClick={() => { this.setState({ filters: [], screen: 'addFilter' }); this.props.applyFilters([]); this.props.toogleFilterBar(false); }}>{this.labels.lblCleanFilters} <i className="ti-minus" /></button>
                        </CSSTransition>
                    </Col>
                </>
                    ; break;
        }
        return bar;
    }

    //renders the filter selection panel
    getFilterPanel = () => {
        var panel = null
        switch (this.state.filterPanelType) {
            case "DEMOGRAPHIC":
                panel = <Container className="filterPanel">
                    {this.props.genderSeparation ?
                        <Row>
                            <Col>
                                <Xbutton onClick={this.toggleFilterPanel} />
                                <h3>{this.labels.lblGenderTitle}</h3>
                                <Table responsive>
                                    <tbody>
                                        <tr>
                                            <td className="genderFilter">
                                                <div>
                                                    <span>{this.labels.lblMenGender}:</span>
                                                    <Label><SwitchButton ff={this.filterFunctions} filter={{ id: "male", name: this.labels.lblMenGender, flagvariable: "_filter_male" }} /></Label>
                                                </div>
                                                <div>
                                                    <span>{this.labels.lblWomenGender}:</span>
                                                    <Label><SwitchButton ff={this.filterFunctions} filter={{ id: "female", name: this.labels.lblWomenGender, flagvariable: "_filter_female" }} /></Label>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                        : <Xbutton onClick={this.toggleFilterPanel} />}
                    {this.props.ageBrackets.length === 0 ? " "
                        :
                        <Row>
                            <h3>{this.labels.lblAgeRangeTitle}</h3>
                            <Table responsive className="table-full-width">
                                <tbody>
                                    {this.props.ageBrackets.map((element, key) => {
                                        return (<tr key={key} className="ps-child">
                                            <td>{element.name}</td>
                                            <td>{element.startYear} - {element.endYear}</td>
                                            <td><Label><SwitchButton ff={this.filterFunctions} filter={{ id: element.name, name: element.name, flagvariable: "_filter_ageRange" + (key + 1) }} /></Label></td>
                                        </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                        </Row>
                    }
                </Container>

                break;
            case "CATEGORIES":
                panel = <Container className="filterPanel">
                    <Row>
                        <Col>
                            <Xbutton onClick={this.toggleFilterPanel} />
                            <CategoryItemList
                                clasification={this.props.clasificationlist}
                                level={this.state.level}
                                getCatDat={this.props.getCatDat}
                                ff={this.filterFunctions}
                                labels={this.labels}
                                changeLevel={this.changeClasificationLevel}
                            />
                        </Col>
                    </Row>

                </Container>
                break;

            case "EDIT":
                panel = <Container className="filterPanel">
                    <Row>
                        <Col>
                            <Xbutton onClick={this.toggleFilterPanel} />
                            <EditFilterPanel filters={this.state.filters} getCatDat={this.props.getCatDat} ff={this.filterFunctions} labels={this.labels} />
                        </Col>
                    </Row>

                </Container>
                break;


            default:
                panel = <div></div>
                break;
        }

        return panel;
    }


    // 4. RENDER ---------------------------------------------------------------------------------------------------
    render() {
        //Creación de estilos previos al render---------------------------------------------------------------------
        if (this.state.filterPanelPos != null) {
            var t = { left: this.state.filterPanelPos.triangle }
        }
        //RENDER DEL COMPONENTE ---------------------------------------------------------------------------------------------------
        return (
            <Container className="containerFiltros" fluid>
                {/* {this.getAppliedFiltersLabel()} */}
                <Row className="filtroFontSize filterRow">
                    {this.getHorizontalBar()}
                </Row>
                {this.state.openFilterPanel ?
                    <Row>
                        {this.getFilterPanel()}
                    </Row>
                    : ""}
            </Container>
        );
    }
}



export default Filtros;
export { SwitchButton };
