import React, { useState } from "react";
import classnames from 'classnames';
import ReactTooltip from "react-tooltip";
import DatePicker, { registerLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
import {
    Card, CardBody,
    Container, Row, Col,
    Button,
    Input,
    Modal, ModalBody, ModalHeader
} from "reactstrap";
import { CSSTransition } from "react-transition-group"
import DragnDropIcon from '../../../../assets/icons/createCampaign/dragndrop.png';
import CuponIcon from '../../../../assets/icons/createCampaign/cupon.png';
import { CampaignSmsCreatorLang } from '../../../../lang';
import Breadcrumbs from "../SubComponents/Breadcrumbs";
import Cuponera from "../SubComponents/Cuponera";
import SegmentSelectionForm from './SegmentSelectionForm';
import FinalPreviewForm from './FinalPreviewForm';
registerLocale('es', es);

var PhonePreview = props => {

    return (
        <div className="phone">
            {props.message.length > 0 ?
                <div className="textMessage">
                    {props.message || ""}
                </div>
                : ""
            }
        </div>

    )
}

var SmsCampaignForm = props => {

    // 1. CONST DEFINITIONS    
    const [step, setInternalStep] = useState(1); // 1. Campaign Name | 2. Sms Content | 3. Segment Selection | 4. Confirmation
    const [modal, setModal] = useState(false);
    const [modalScreen, setModalScreen] = useState('');
    // const [datePickerModal, setDatePickerModal] = useState(false);
    const labels = CampaignSmsCreatorLang(props.lang);
    const [maxCharacters, setMaxCharacters] = useState(160);
    const [selectionCount, setSelectionCount] = useState(0);
    const [finalSelectionLoaded, setFinalSelectionLoaded] = useState(false);
    const [summitChoice, setSummitChoice] = useState('summit') // summit | save | schedule
    const [campaign, setCampaign] = useState({
        name: '',
        text: '',
        cuponId: null,
        segments: [],
        config: { sentNow: true, sentAt: new Date() }

    });
    const [messageType, setMessagetype] = useState('');
    const [coupon, setCoupon] = useState(null);
    const [testSms, setTestSms] = useState({
        testSms: null,
        testSmsSending: false,
        testSmsSent: false,
        testSmsError: false
    })

    // 2. Utility Functions ------------------
    var createFormTextInput = (label, explan, controller, required, value, placeholder) => {
        if (required == undefined) { required = false }
        if (value == undefined || value == null) { value = "" }
        if (placeholder == undefined || placeholder == null) { placeholder = "" }
        return (
            <div className="formGroup">
                <label> {label} <span className="requiredLabel" data-for="smsCampaignCreatorForm" data-tip="Campo requerido" data-place="right">{required ? "?" : ""}</span></label>
                <Input type="text" placeholder={placeholder} onChange={e => { controller(e.target.value) }} value={value}></Input>
                <p>{explan}</p>
            </div>
        )
    }

    var setStepCheckingCondition = (nstep) => {
        var bool = false;
        switch (step) {
            case 1: if (campaign.name !== "" && nstep > step) { bool = true; setInternalStep(nstep) }; break;
            case 2:
                if (nstep < step) {
                    setInternalStep(nstep);
                }
                else if (campaign.text != '') {
                    bool = true;
                    setInternalStep(nstep);
                }
                ; break;
            case 3:
                if (nstep < step) {
                    setInternalStep(nstep)
                } else if (campaign.segments.length > 0) {
                    bool = true;
                    setInternalStep(nstep)
                };
                break;
            case 4:
                if (nstep < step) {
                    setFinalSelectionLoaded(false);
                    setInternalStep(nstep)
                }
                break;
            default: ; break;
        }

        return bool;
    }

    const breadcrumbs = [
        { text: labels.lblStepConf, function: setStepCheckingCondition, functionValue: 1 },
        { text: labels.lblStepContent, function: setStepCheckingCondition, functionValue: 2 },
        { text: labels.lblStepSegments, function: setStepCheckingCondition, functionValue: 3 },
        { text: labels.lblStepConfirmation, function: setStepCheckingCondition, functionValue: 4 },
    ];

    var setSmsforTest = (smsNumber) => {
        setTestSms(t => {
            t.testSms = smsNumber;
            return ({ ...t });
        })
    }

    var toggleTestSmsSent = (bool) => {
        if (bool == undefined) { bool = false }
        setTestSms(t => {
            t.testSms = "";
            t.testSmsSent = bool;
            t.testSmsSending = false;
            return ({ ...t });
        })
    }

    var toggleModal = (screen) => { if (screen === undefined) { screen = '' }; setModalScreen(screen); setModal(!modal); }

    // 3. CAMPAIGN OBJECT UPDATE FUNCTIONS
    var setCampaignName = (name) => {
        setCampaign(c => {
            c.name = name;
            return ({ ...c });
        })
    }

    var setTextMessage = (message) => {
        setCampaign(c => {
            // var maxC = state.maxCharacters;
            let valLength = message.length;
            let textLength = c.text.length;

            if (valLength > textLength && (valLength - textLength) === 1) {
                setMaxCharacters(maxCharacters - 1);
            }

            if (valLength < textLength && (textLength - valLength) === 1) {
                setMaxCharacters(maxCharacters + 1);
            }

            if (valLength > textLength && (valLength - textLength) !== 1) {
                setMaxCharacters(160 - valLength);
            }

            if (valLength < textLength && (textLength - valLength) !== 1) {
                setMaxCharacters(160 - valLength);
            }

            if (maxCharacters < 0) {
                setMaxCharacters(0);
            }

            if (valLength === 0) {
                setMaxCharacters(160);
            }

            c.text = message;
            return ({ ...c });
        })
    }

    var setCampaignDate = (date) => {
        setCampaign(c => {
            c.config.sentAt = date;
            return ({ ...c })
        })
    }

    var setCampaignSegments = (seg, count) => {
        setCampaign(c => {
            c.segments = seg;
            return ({ ...c });
        })
        setSelectionCount(count)
        setStepCheckingCondition(4);
    }

    var setCampaignCupon = (cupon) => {
        if (cupon === null) {
            setMaxCharacters(160);
            setCoupon(null);
            setCampaign(c => {
                c.text = '';
                c.cuponId = null;
                return { ...c }
            })
        }
        else {
            if (cupon.ShortMessage.length <= 160) {
                setMaxCharacters(160);
                setCoupon(cupon);
                setCampaign(c => {
                    c.text = cupon.ShortMessage;
                    c.cuponId = cupon.ID;
                    return { ...c }
                })
            } else {
                setMaxCharacters(maxCharacters - cupon.ShortMessage.length);
            }
        }


    }

    //5. Async Functions - API CALLS
    var sendSmsTest = async () => {
        if (props.campaignConfiguration.is_demo) {
            console.log('Is Demo')
        }
        else {
            // Checks if theres a text to be sended
            if (campaign.name != "" && campaign.text != "") {
                //Activates the loading Spinner
                setTestSms(tm => {
                    tm.testSmsSending = true;
                    return ({ ...tm });
                })

                //Checks if its trying to send a cupon
                if (campaign.cuponId !== null) {
                    //Cupon Text Message ---------------------------------------------------------------------------------------------
                    let claimCacheId = 'ClaimCuponSms' + Math.round(Math.random() * 1000);
                    let code = await props.askFor('campaigns', 'claimCupon', [], false, { cuponId: campaign.cuponId }, claimCacheId)
                    if (code != null) {
                        var cacheId = "SendTestSms-" + props.bucketId + "-" + campaign.name
                        var message = { message: campaign.text + " " + code, recipients: [testSms.testSms] };
                        var res = await props.askFor('campaigns', "sendTestSms", [], false, message, cacheId);
                        if (res != null) {
                            setTestSms(tm => {
                                tm.testSmsSent = true;
                                tm.testSmsSending = false;
                                tm.testSmsError = false;
                                return ({ ...tm });
                            })
                        }
                        else {
                            setTestSms(tm => {
                                tm.testSmsSent = true;
                                tm.testSmsSending = false;
                                tm.testSmsError = true;
                                return ({ ...tm });
                            })
                        }
                    } else {
                        setTestSms(tm => {
                            tm.testSmsSent = true;
                            tm.testSmsSending = false;
                            tm.testSmsError = true;
                            return ({ ...tm });
                        })
                    }
                }
                else {
                    //Regular Text message --------------------------
                    var cacheId = "SendTestSms-" + props.bucketId + "-" + campaign.name
                    var message = { message: campaign.text, recipients: [testSms.testSms] };
                    var res = await props.askFor('campaigns', "sendTestSms", [], false, message, cacheId);
                    if (res != null) {
                        setTestSms(tm => {
                            tm.testSmsSent = true;
                            tm.testSmsSending = false;
                            tm.testSmsError = false;
                            return ({ ...tm });
                        })
                    }
                    else {
                        setTestSms(tm => {
                            tm.testSmsSent = true;
                            tm.testSmsSending = false;
                            tm.testSmsError = true;
                            return ({ ...tm });
                        })
                    }
                }
            }
            else {
                setTestSms(s => {
                    s.testSmsSent = true;
                    s.testSmsSending = false;
                    s.testSmsError = true;
                    return ({ ...s });
                })
            }
        }



    }

    var summitCampaign = async () => {
        if (props.campaignConfiguration.is_demo) {
            props.setLoadingStep(true);
            setInterval(() => {
                props.setLoadingStep(false);
                props.changeScreen({ screen: 'list', creationCancel: false });
            }, 2000);
        }
        else {
            if (campaign.name !== "" && campaign.subject !== "" && campaign.segments.length > 0) {
                props.setLoadingStep(true);
                var smsCampaign = {
                    name: campaign.name,
                    text: campaign.text,
                    segments: campaign.segments,
                    cuponId: campaign.cuponId,
                    config: { sendNow: true }
                }
                var cacheId = "CreateSMSCampaign-" + props.bucketid + "-" + campaign.name;
                var ressms = await props.askFor('campaigns', "createsmscampaign", [], false, smsCampaign, cacheId);
                if (ressms === null) {
                    alert("Error while creating the campaign")
                    props.setLoadingStep(false);
                } else {
                    props.setLoadingStep(false);
                    props.changeScreen({ screen: 'list', creationCancel: false });
                }
            }
        }

    }

    var summitAndSaveCampaign = async () => {
        if (props.campaignConfiguration.is_demo) {
            props.setLoadingStep(true);
            setInterval(() => {
                props.setLoadingStep(false);
                props.changeScreen({ screen: 'list', creationCancel: false });
            }, 2000);
        }
        else {
            var smsCampaign = {
                name: campaign.name,
                text: campaign.text,
                segments: campaign.segments,
                cuponId: campaign.cuponId,
                config: { sendNow: false }
            }
            var cacheId = "CreateSMSCampaign-" + props.bucketid + "-" + smsCampaign.name
            props.setLoadingStep(true);
            var res = await props.askFor('campaigns', "createsmscampaign", [], false, smsCampaign, cacheId);
            if (res !== null) {
                props.changeScreen({ screen: 'list', creationCancel: false });
            }
        }
    }

    var summitAndScheduleCampaign = async () => {
        if (props.campaignConfiguration.is_demo) {
            props.setLoadingStep(true);
            setInterval(() => {
                props.setLoadingStep(false);
                props.changeScreen({ screen: 'list', creationCancel: false });
            }, 2000);
        }
        else {
            var smsCampaign = {
                name: campaign.name,
                text: campaign.text,
                segments: campaign.segments,
                cuponId: campaign.cuponId,
                config: { sendNow: false, sendAt: campaign.config.sentAt.toISOString() }
            }
            var cacheId = "CreateSMSCampaign-" + props.bucketid + "-" + smsCampaign.name
            props.setLoadingStep(true);
            var res = await props.askFor('campaigns', "createsmscampaign", [], false, smsCampaign, cacheId);
            if (res !== null) {
                props.changeScreen({ screen: 'list', creationCancel: false });
            }
        }


    }

    //4. PRE-RENDER FUNCTIONS
    var getScreenStep = () => {
        var screen = <></>;

        switch (step) {
            case 1:/* Campaign Name */
                screen = <div>
                    {createFormTextInput(labels.lblCampaignName, labels.lblCampaignNameExplan, setCampaignName, true, campaign.name, "Promoción...")}

                    <div className="stepMessage">
                        <p>Selecciona el contenido de tu campaña</p>
                    </div>

                    <Container>
                        <Row>
                            <Col lg="4" md="4" sm="4" className="smsTypeSelectCard">
                                <Card onClick={() => { setMessagetype("message"); }} className={classnames({ active: messageType === "message" })}>
                                    <CardBody>
                                        <h5 className="icon">{labels.lblEditMessage}</h5>
                                        <img src={DragnDropIcon} alt="" className="img-responsive" />
                                        <p>{labels.lblEditMessage}</p>
                                    </CardBody>
                                </Card>
                            </Col>

                            <Col lg="4" md="4" sm="4" className="smsTypeSelectCard">
                                <Card onClick={() => { setMessagetype("cupon"); }} className={classnames({ active: messageType === "cupon" })}>
                                    <CardBody>
                                        <h5 className="icon">{labels.lblCuponExplan}</h5>
                                        <img src={CuponIcon} alt="" className="img-responsive" />
                                        <p>{labels.lblCuponExplan}</p>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>

                    <div className="buttonsRow">
                        {campaign.name !== "" && messageType !== '' ?
                            <CSSTransition classNames="animated" timeout={1000} in={true} appear={true}>
                                <Button onClick={() => { setStepCheckingCondition(2) }}>Siguiente</Button>
                            </CSSTransition>
                            :
                            ""
                        }
                    </div>
                </div>
                    ; break;
            case 2:/* Campaign Text Message */
                switch (messageType) {
                    case 'message':
                        screen = <div>
                            <div className="formGroup">
                                <label> {labels.lblCampaignTextMessage} </label>
                                <Input type="textarea" value={campaign.text} placeholder="Mensaje de texto" onChange={e => { setTextMessage(e.target.value) }}></Input>
                                {maxCharacters <= 0 ?
                                    <CSSTransition classNames="animated" timeout={1000} in={true} appear={true}>
                                        <p className="alert">{labels.lblAvailableCharactersAlert}</p>
                                    </CSSTransition>
                                    :
                                    <CSSTransition classNames="animated" timeout={1000} in={true} appear={true}>
                                        <p className="availableCharacters">{labels.lblAvailableCharacter + ": " + maxCharacters}</p>
                                    </CSSTransition>
                                }
                            </div>

                            {campaign.text != "" ?
                                maxCharacters > 0 ?
                                    <div className="buttonsRow">
                                        <CSSTransition classNames="animated" timeout={1000} in={true} appear={true}>
                                            {props.campaignConfiguration.is_demo ?
                                                <></>
                                                :
                                                <Button onClick={() => { toggleModal('sendTest') }}>Mensaje de prueba</Button>
                                            }

                                        </CSSTransition>
                                        <CSSTransition classNames="animated" timeout={1000} in={true} appear={true}>
                                            <Button onClick={() => { setStepCheckingCondition(3) }}>Siguiente</Button>
                                        </CSSTransition>
                                    </div>
                                    :
                                    <div className="stepMessage">
                                        <p>El mensaje del cupón seleccionado es muy extenso</p>
                                    </div>
                                :
                                <div className="stepMessage">
                                    <p>Escriba el mensaje de texto a enviar</p>
                                </div>
                            }


                            <PhonePreview message={campaign.text} />
                        </div>
                            ; break;
                    case 'cupon':
                        screen = <div>
                            <div className="formGroup">
                                <Cuponera
                                    askFor={props.askFor}
                                    loadingSpinner={props.loadingSpinner}
                                    setCampaignCupon={setCampaignCupon}
                                    selectedCupon={coupon}
                                    type="sms"
                                />
                            </div>

                            {campaign.text != "" ?
                                maxCharacters > 0 ?
                                    <div className="buttonsRow">
                                        <CSSTransition classNames="animated" timeout={1000} in={true} appear={true}>
                                            {props.campaignConfiguration.is_demo ?
                                                <></>
                                                :
                                                <Button onClick={() => { toggleModal('sendTest') }}>Mensaje de prueba</Button>
                                            }
                                        </CSSTransition>
                                        <CSSTransition classNames="animated" timeout={1000} in={true} appear={true}>
                                            <Button onClick={() => { setStepCheckingCondition(3) }}>Siguiente</Button>
                                        </CSSTransition>
                                    </div> : ""
                                :
                                <div className="stepMessage">
                                    {/* <p>Digita el contenido del mensaje de texto</p> */}
                                </div>
                            }

                            <CSSTransition mountOnEnter unmountOnExit classNames="ani" timeout={1000} in={coupon !== null} appear={coupon !== null}>
                                {coupon !== null ?
                                    <PhonePreview message={coupon.ShortMessage + ": XASFGDVA"} />
                                    :
                                    <></>
                                }
                            </CSSTransition>



                        </div>;
                        ; break;
                    default: ; break;
                }

                ; break;
            case 3:/* Segment selection */
                screen = <Col lg="12" md="12" sm="12">
                    <Card>
                        <CardBody>
                            <SegmentSelectionForm
                                askFor={props.askFor}
                                exportFunction={setCampaignSegments}
                                loadingSpinner={props.loadingSpinner}
                                selectionCount={selectionCount}
                                lang={props.lang}
                                type="sms"
                            />
                        </CardBody>
                    </Card>
                </Col>
                    ; break;
            case 4:/* Confirmation */
                screen = <div>
                    <FinalPreviewForm
                        askFor={props.askFor}
                        lang={props.lang}
                        type="sms"
                        loadingSpinner={props.loadingSpinner}
                        selectionCount={selectionCount}
                        segments={campaign.segments}
                        campaignName={campaign.name}
                        campaignSubject={campaign.text}
                        isLoaded={setFinalSelectionLoaded}
                    />

                    {finalSelectionLoaded ?
                        <Row className="buttonsRow">
                            <Button onClick={() => { setSummitChoice('summit'); toggleModal('confirmation') }}>{labels.lblCreateAndSendCampaign}</Button>
                            <Button onClick={() => { setSummitChoice('save'); toggleModal('confirmation') }}>{labels.lblCreateAndSaveCampaign}</Button>
                            <Button onClick={() => { setSummitChoice('schedule'); toggleModal('datePicker'); }}>{labels.lblCreateAndScheduleCampaign}</Button>
                        </Row>
                        :
                        ""
                    }
                </div>
                    ; break;
            default: ; break;
        }

        return screen;
    }

    var getModalContent = () => {
        var screen = <></>;

        switch (modalScreen) {
            case 'confirmation':
                var content = <></>;
                switch (summitChoice) {
                    case 'summit': content = <>
                        <h6>La campaña se creará a continuación</h6>
                        <div className="buttonsRow">
                            <Button onClick={() => { summitCampaign() }}>{labels.lblAccept}</Button>
                            <Button onClick={toggleModal}>{labels.lblCancel}</Button>
                        </div>

                    </>; break;
                    case 'save': content = <>
                        <h6>¿Guardar campaña como borrador?</h6>
                        <div className="buttonsRow">
                            <Button onClick={() => { summitAndSaveCampaign() }}>{labels.lblAccept}</Button>
                            <Button onClick={toggleModal}>{labels.lblCancel}</Button>
                        </div>
                    </>; break;
                    case 'schedule': content = <>
                        <h6>La campaña se enviará:</h6>
                        <p>{campaign.config.sentAt.toLocaleDateString()}</p>
                        <div className="buttonsRow">
                            <Button onClick={() => { summitAndScheduleCampaign() }}>{labels.lblAccept}</Button>
                            <Button onClick={toggleModal}>{labels.lblCancel}</Button>
                        </div>
                    </>; break;
                    default: break;
                }

                screen = <div className="confirmation">
                    {content}
                </div>
                    ; break;
            case "datePicker":
                screen = <div className="datePicker">
                    <p>Seleccionar fecha de envío</p>
                    <DatePicker
                        selected={campaign.config.sentAt}
                        onChange={setCampaignDate}
                        locale={props.lang}
                    />
                    <Button onClick={() => { setModalScreen('confirmation') }}>{labels.lblAccept}</Button>
                </div>
                    ; break;
            case "sendTest":
                screen = <div className="sendTestEmail">
                    <div className="inputGroup">
                        {testSms.testSmsSent ?
                            testSms.testSmsError ?
                                <>
                                    <span>Error al enviar el mensaje de texto</span>
                                    <button onClick={() => { toggleTestSmsSent(false); }}>Enviar mensaje</button>
                                </>
                                :
                                <>
                                    <span>Enviado a: {testSms.testSms}</span>
                                    <button onClick={() => { toggleTestSmsSent(false); }}>Enviar otro mensaje</button>
                                </>
                            :
                            testSms.testSmsSending ?
                                <>
                                    {props.loadingSpinner}
                                </>
                                :
                                <>
                                    <label>Número de telefono</label>
                                    <Input type="text" placeholder="50688776655" onChange={e => { setSmsforTest(e.target.value) }}></Input>
                                    <button onClick={() => { sendSmsTest(); }}>Enviar</button>
                                </>
                        }
                    </div>
                </div>
                break;
            default: ; break;
        }

        return screen;
    }

    //Render
    return (
        <Container fluid className="smsCampaignCreatorForm">
            <Row className="stepRow">
                <Breadcrumbs breadcrumps={breadcrumbs} active={step} />
            </Row>
            <Row>
                <Col lg={step === 3 || step === 4 ? "12" : "8"} md="8" sm="12">
                    <ReactTooltip id="smsCampaignCreatorForm" />

                    {getScreenStep()}

                    <Modal isOpen={modal} toggle={toggleModal} centered={true} className="smsModal">
                        <ModalHeader toggle={toggleModal}><label> {modalScreen == "sendTest" ? labels.lblTestSms : ""} </label></ModalHeader>
                        <ModalBody>
                            {getModalContent()}
                        </ModalBody>
                    </Modal>
                </Col>
            </Row>
        </Container>
    )

}

export default SmsCampaignForm;