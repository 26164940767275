import React from "react";
import {Container, Row, Col,} from "reactstrap";
import classnames from 'classnames';


var Breadcrumbs = props => {

    //Props:
    //breadcrumps = [{text:string, function: f(), functionValue: x, isEnable: function f(),}]
    //active

    return (
        <Container fluid className="breadcrumps">
            <Row>
                <Col>
                    {props.breadcrumps.map((b, index) => {
                        return (
                            <div key={index}>
                                {index != 0 ? <span className="middle"> {">"} </span> : ""}
                                <span className={classnames({ active: index + 1 == props.active })}
                                    onClick={() => {
                                        b.function(b.functionValue)
                                    }}>
                                    {b.text}
                                </span>
                            </div>
                        )
                    })}
                </Col>
            </Row>
        </Container>
    )
}

export default Breadcrumbs;