

export default class CacheCortt {
    constructor(){
        this.cache = [];
    }

    storeInCache(name,data){
        let a = {id:name,data:data}
        this.cache.push(a);
    }

    getFromCache(id){
        for(var i=0; i<this.cache.length;i++){
            if(this.cache[i].id===id){
                return this.cache[i];
            }
        }
    }

    searchInCache(id){
        var index = -1;
        for(var i=0; i<this.cache.length;i++){
            if(this.cache[i].id===id){
                index =  i;
            }
        }

        return index;
    }

   existInCache(id){
        var bool=false;
        for(var i=0; i<this.cache.length;i++){
            if(this.cache[i].id===id){
                bool = true;
            }
        }
        return bool;
    }

    removeFromCache(id){
        let pos=this.searchInCache(id);
        this.cache.splice(pos);
    }

}