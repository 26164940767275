import React from "react";
import { useState } from "react";
import ReactTooltip from "react-tooltip";
import { Transition, CSSTransition, /* TransitionGroup */ } from "react-transition-group"
import { Redirect } from "react-router-dom";
import classnames from 'classnames';
import SegmentCreator from "./SegmentCreator.jsx";
import { ClientProfile } from "./SegmentCreator.jsx";
import CustomSegmentDetail from "./CustomSegmentDetail.jsx";
import { MySegmentsLang, MyExportsCreatorLang } from '../../lang';
import NumberFormat from "react-number-format";
import {
    Container, Row, Col,
    Card, CardBody,
    // Nav, NavItem, NavLink,
    // TabContent, TabPane,
    Button,
    Table,
    FormGroup, Input,
    Dropdown, DropdownMenu, DropdownItem, DropdownToggle
}
    from 'reactstrap'
import FetchCmp from "../../components/FetchCmp/fetchCmp";
import { ListFormat } from "typescript";

class ExportCreatorForCustomSegments extends React.Component {
    constructor(props) {
        super(props)
        this._isMounted = false;
        this._labels = MyExportsCreatorLang(props.lang);
        this._count = props.customSegment.baseData[props.customSegment.baseData.length - 1].count;
        this.state = {
            exportName: "",
            exportDescription: "",
            segmentConfig: props.customSegment,
            screen: 'input',
        }
        //Function Binding
        this.handleDescription = this.handleDescription.bind(this);
        this.handleName = this.handleName.bind(this);
        this.saveExport = this.saveExport.bind(this);
    }

    //1. REACT LIFECYCLE --------------------------
    componentDidMount() {
        this._isMounted = true;
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.lang != this.props.lang) {
            if (this._isMounted) {
                this._labels = MyExportsCreatorLang(this.props.lang);
                this.forceUpdate();
            }
        }
    }

    componentWillMount() {
        this._isMounted = false;
    }


    //2.DATA HANDLERS
    handleName(name) {
        if (this._isMounted) {
            this.setState({ exportName: name })
        }
    }

    handleDescription(desc) {
        if (this._isMounted) {
            this.setState({ exportDescription: desc })
        }
    }


    //3.SAVE EXPORT FUNCTION
    async saveExport() {
        try {
            if (this._isMounted) {
                this.setState({ screen: "inProgress" });
            }
            let cacheId = 'SaveCustomSegmentExport' + this.state.name;
            let data = await this.props.askFor('crm', 'crm_bucket/clients/export/customSegment/create', [], false, this.state, cacheId);
            if (this._isMounted) {
                if (data != null) {
                    this.setState({ screen: "sended" })
                }
            }
        } catch (err) {
            console.log(err);
        }
    }


    //4. RENDER -------------------------- -------------------------- --------------------------
    render() {
        switch (this.state.screen) {
            case "input": return (<Container fluid className="exportCreator">
                <Card>
                    <CardBody>
                        <Row>{/* Title and explanation */}
                            <Col>
                                <h4>{this._labels.lblTitle}</h4>
                                <p>{this._labels.lblExplain}</p>
                            </Col>
                        </Row>
                        <Row>{/* Name and count */}
                            <Col>
                                <h4>{this.props.segmentName || 'Nombre'}</h4>
                                <p>{this._labels.lblClientsToExport}: <b>{this._count || 0}</b> </p>
                            </Col>
                        </Row>
                        <Row>{/* Inputs */}
                            <Col>
                                <FormGroup>
                                    <Input type="text" placeholder={this._labels.lblNamePlaceHolder} onChange={e => this.handleName(e.target.value)} />
                                    <Input type="textarea" placeholder={this._labels.lblDescPlaceholder} onChange={e => this.handleDescription(e.target.value)} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>{/* Buttons */}
                            <Col>
                                <Row>
                                    <Col>
                                        <button className="d-inline" onClick={() => { this.props.openCustomSegmentExport(false) }}>{this._labels.lblButtonCancel}</button>
                                        <button className="d-inline" onClick={() => { this.saveExport() }}><i className="ti-download" />  {this._labels.lblButtonExport}</button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Container>); break;
            case "inProgress": return (<Container fluid className="exportCreator">
                <Card>
                    <CardBody>
                        {this.props.loadingSpinner}
                    </CardBody>
                </Card>
            </Container>); break;
            case "sended": return (<Redirect to="/myexports" />); break;
        }
    }
}

class DeleteConfirm extends React.Component {
    constructor(props) {
        super(props)
        this._isMounted = false;
        this._labels = props.labels
        this.state = { loading: false }
    }

    //1. REACT LIFECYCLE --------------------------
    componentDidMount() {
        this._isMounted = true;
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.hidden !== this.props.hidden) {
            if (this.props.hidden == false) {
                this.hideLoading();
            }
        }
    }

    componentWillMount() {
        this._isMounted = false;
    }


    //2.DATA HANDLERS
    showLoading() {
        if (this._isMounted) {
            this.setState({ loading: true });
        }

    }

    hideLoading() {
        if (this._isMounted) {
            this.setState({ loading: false });
        }

    }

    //3.SAVE EXPORT FUNCTION



    //4. RENDER -------------------------- -------------------------- --------------------------
    render() {
        //Default
        return (<Container fluid className="deleteQuestion" hidden={this.props.hidden}>
            <Card>
                {this.state.loading == false ?
                    <CardBody>
                        <Row>{/* Title and explanation */}
                            <Col>
                                <h5>Seguro que desea elminar el segmento</h5>
                            </Col>
                        </Row>
                        <Row>{/* Buttons */}
                            <Col>
                                <Row>
                                    <Col className="buttons">
                                        <button className="d-inline" onClick={() => { this.props.toggleDeleteConfirm() }}>{this._labels.lblCancel}</button>
                                        <button className="d-inline" onClick={() => { this.showLoading(); this.props.deleteFunction() }}> {this._labels.lblButtonDeleteSegment}</button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </CardBody>
                    :
                    this.props.loadingSpinner
                }
            </Card>
        </Container>)
    }
}

class SegmentSummary extends React.Component {
    //Props:
    //Segment: Obj with properties and config of the segment to represent
    //horizontal: Boolean -> Determina cual de los posibles 2 renders mostrar (Horizontal y Vertical)
    constructor(props) {
        super(props)
        this._isMounted = false;
        this.state = {
            data: {
                count: 0,
                avgTicket: 0,
                avgVisitsPerYear: 0,
                txcount: 0,
                perc_purchase_fromtotal: 0,
                perc_count_fromtotal: 0
            }
        };
        this.labels = props.labels;
    }

    componentDidMount() {
        this._isMounted = true;
        let dat = this.props.segment.baseData[this.props.segment.baseData.length - 1];
        if (this.props.handlePurchasePercentage != undefined) {
            this.props.handlePurchasePercentage(dat.perc_purchase_fromtotal)
        }
        if (this._isMounted) {
            this.setState({ data: dat })
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.segment != this.props.segment) {
            let dat = this.props.segment.baseData[this.props.segment.baseData.length - 1]
            this.setState({ data: dat })
        }

        if (prevProps.labels != this.props.labels) {
            this.labels = this.props.labels;
            this.forceUpdate();
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    async refreshSegmentData() {
        if (this._isMounted) {
            this.setState({ data: null }, () => { ReactTooltip.hide() })
        }
        let cacheId = "RefreshSegment" + Math.floor(Math.random() * 1000);
        let dat = await this.props.askFor("segments", "segments/refreshcustomsegment", [], false, { segmentConfig: this.props.segment }, cacheId)
        if (dat != null) {
            if (this.props.handlePurchasePercentage != undefined) {
                this.props.handlePurchasePercentage(dat.perc_purchase_fromtotal)
            }
            if (this._isMounted) {
                this.setState({ data: dat.newData }, () => { this.props.setState({ baseData: dat.newData, dataChange: true, config: dat.newConfig }); ReactTooltip.show() })
            }
        }
    }

    render() {
        if (this.state.data == null) { return (<div>{this.props.loadingSpinner}</div>) }
        if (this.props.horizontal || false) {
            return (
                <Container fluid className="customSegmentMinSumHorizontal" /* style={{ color: this.props.segment.segmentColor }} */>
                    <Row >
                        <Col>
                            <NumberFormat
                                value={this.state.data.count || 0}
                                displayType="text"
                                decimalScale={0}
                                thousandSeparator={true}
                            />
                            <small>{this.state.data.perc_count_fromtotal}%</small>
                            <h6>{this.labels.lblClients}</h6>
                        </Col>
                        <Col>
                            <NumberFormat
                                value={this.state.data.avgTicket || 0}
                                displayType="text"
                                prefix={this.props.currencySymbol}
                                decimalScale={0}
                                thousandSeparator={true}
                            />
                            <small>{this.labels.lblAvgTIcketExplain}</small>
                            <h6>{this.labels.lblAvgTicket}</h6>
                        </Col>
                        <Col>
                            <NumberFormat
                                value={this.state.data.txcount || 0}
                                displayType="text"
                                decimalScale={0}
                                thousandSeparator={true}
                            />
                            <small>{this.state.data.perc_purchase_fromtotal}%</small>
                            <h6>{this.labels.lblSales}</h6>
                        </Col>
                        <Col>
                            <span>{this.state.data.avgVisitsPerYear || 0}</span>
                            <h6>{this.labels.lblAvgVisit}</h6>
                        </Col>
                        {this.props.showBtnReload == undefined ?
                            <button data-for="refreshTooltip" data-tip={this.labels.lblButtonRefresh} className="btnViewDetail" onClick={() => { this.refreshSegmentData() }}><i className="ti-reload" /></button>
                            : ""}
                        <ReactTooltip id="refreshTooltip" place="bottom" textColor="#1c79cd" backgroundColor="#c7e1f9" />
                    </Row>
                </Container>
            )
        }
        else {
            return (<Container fluid className="customSegmentMinSum">
                <Card>
                    <CardBody>
                        <Table responsive style={{ color: this.props.segment.segmentColor }}>
                            <tbody>
                                <tr><td>{this.labels.lblClients}:</td><td className="stdOut">{this.state.data.count || 0}</td></tr>
                                <tr><td>{this.labels.lblSales}:</td><td className="stdOut">{this.state.data.txcount || 0}</td></tr>
                                <tr><td>{this.labels.lblAvgTicket}:</td><td className="stdOut"><NumberFormat value={this.state.data.tiquete_promedio || 0} displayType="text" prefix={this.props.currencySymbol} decimalScale={0} thousandSeparator={true} /></td></tr>
                            </tbody>
                        </Table>
                    </CardBody>
                </Card>
            </Container>)
        }

    }
}

var SegmentListItem = props => {
    //Props = segment:obj | currencySymbol:string | askFor:function | openSegmentDetail:function | deleteSegment:function | noButtons:bool | labels:array
    var initialState = { baseData: props.segment.baseData[props.segment.baseData.length - 1], dataChange: false, config: props.segment.config }
    const [state, setState] = useState(initialState);

    return (<Card key={props.key} className='segmentListItem'>
        <ReactTooltip id="segmentCardTooltip" place="bottom" textColor="#1c79cd" backgroundColor="#c7e1f9" />
        <CardBody>
            <Row>{/* Title and Buttons */}
                <Col lg={props.buttonGetBack != undefined ? "12" : "8"} md="8" sm="8">
                    <h4 style={{ marginBottom: '0px' }}>
                        {props.segment.segmentName}
                    </h4>
                </Col>
                <Col lg='4' md="4" sm="4" className={classnames({ 'd-none': props.noButtons != undefined })}>
                    <Container fluid className={classnames({ "d-none": props.showBtnBack != undefined })}>
                        <Row>
                            <Col className="buttonsCol">
                                <button data-for="segmentCardTooltip" className="btnViewDetail" data-tip={props.labels.lblButtonViewDetail} onClick={() => { props.openSegmentDetail(props.segment, state.dataChange, state.baseData, state.config) }}>
                                    <i className="fa fa-eye" />
                                </button>
                                <button data-for="segmentCardTooltip" className="btnViewDetail" data-tip={props.labels.lblButtonExport} onClick={() => { props.openCustomSegmentExport(true, props.segment) }}>
                                    <i className="ti-download" />
                                </button>
                                <button data-for="segmentCardTooltip" className="btnViewDetail" data-tip={props.labels.lblButtonModify} onClick={() => { props.openSegmentModifier(props.segment) }} >
                                    <i className="fa fa-pencil-alt" />
                                </button>
                                <button data-for="segmentCardTooltip" className="btnViewDetail" data-tip={props.labels.lblButtonDeleteSegment} onClick={() => { props.openDeleteQuestion(props.segment.segmentID) }}>
                                    <i className="ti-trash" />
                                </button>
                            </Col>
                        </Row>
                    </Container>
                </Col>
            </Row>
            <hr />
            <SegmentSummary
                currencySymbol={props.currencySymbol}
                askFor={props.askFor}
                handlePurchasePercentage={props.handlePurchasePercentage || undefined}
                segment={props.segment}
                horizontal={true}
                labels={props.labels}
                loadingSpinner={props.loadingSpinner}
                setState={setState}
                showBtnReload={props.showBtnBack}
            />
            <hr />
            <Row>{/* Descripción y Caracteristicas */}
                <Col lg='4' md="6" sm="12" style={{ display: 'inline-block' }}>
                    <h6>{props.labels.lblDescription}:</h6>
                    <Card className="segmentDescription">
                        <CardBody>
                            <p >{props.segment.segmentDescription}</p>
                        </CardBody>
                    </Card>
                </Col>
                <Col lg='8' md="6" sm="12" style={{ display: 'inline' }}>
                    {props.segment.type == 'rfm' ?
                        <Row>{/* Con perfil de cliente */}
                            <Col lg='7' md="12">
                                <h6>{props.labels.lblClientProfile}:</h6>
                                <ClientProfile
                                    recencia={props.segment.config.rfmValues.r}
                                    frecuencia={props.segment.config.rfmValues.f}
                                    periodType={"dias"}
                                    montoMax={props.segment.config.rfmValues.m.max}
                                    montoMin={props.segment.config.rfmValues.m.min}
                                    currencySymbol={props.currencySymbol}
                                    color={props.segment.segmentColor}
                                    labels={props.labels}
                                    darken={false}
                                    noIcon={true}
                                />
                            </Col>
                            <Col lg='5' md='12'>
                                <h6>{props.labels.lblAppliedFilters}:</h6>
                                <ul className="list-unstyled">

                                    {props.segment.config.filters.length > 0 ?
                                        props.segment.config.filters.map((filter, filterKey) => {
                                            return (<li key={filterKey}>{filter.name}</li>)
                                        })
                                        :
                                        <li>{props.labels.lblNoFilter}</li>
                                    }
                                </ul>
                            </Col>
                        </Row>
                        :
                        <Row>{/* Con segmentos base */}
                            <Col lg='5'>
                                <h6>{props.labels.lblBaseSegments}:</h6>
                                <ul className="list-unstyled sbList">
                                    <Row>
                                        {props.segment.config.baseSegments.length == 5 ?
                                            <Col>
                                                <li className="all">{props.labels.lblAllPeople}</li>
                                            </Col>
                                            :
                                            props.segment.config.baseSegments.map((bs, bsKey) => {
                                                return (<Col key={bsKey}><li >{bs.name}</li></Col>)
                                            })}
                                    </Row>
                                </ul>
                            </Col>
                            <Col lg='7'>
                                <h6>{props.labels.lblAppliedFilters}:</h6>
                                <ul className="list-unstyled">
                                    {props.segment.config.filters.length > 0 ?
                                        props.segment.config.filters.map((filter, filterKey) => {
                                            return (<li key={filterKey}>{filter.name}</li>)
                                        })
                                        :
                                        <li>{props.labels.lblNoFilter}</li>
                                    }
                                </ul>
                            </Col>
                        </Row>
                    }
                </Col>
            </Row>
            <Row>
                <Col className="dateOfData">
                    <label>{props.labels.lblCardSummary}:</label>
                    <span>{new Date(state.baseData.date).toLocaleDateString()}</span>

                </Col>
            </Row>
        </CardBody>
    </Card>)
}

class SegmentList extends React.Component {
    constructor(props) {
        super(props)
        this._isMounted = false;
        this.state = { segmentList: null, recharge: false, hideDeleteQuestion: true, deleteSegmentId: "", dropdownViewModeDisplay: false, viewMode: "table" }
        this.searchSegment = this.searchSegment.bind(this);
        this.deleteSegment = this.deleteSegment.bind(this);
        this.toogleDeleteQuestion = this.toogleDeleteQuestion.bind(this);
        this.labels = props.labels;
    }

    //0. API CALLS ------------------------------------------------------------------------------------------------------------------------
    async componentDidMount() {
        this._isMounted = true;
        try {
            let cacheId = "CustomSegmentListNoCache" + Math.round(Math.random() * 1000);
            let data = await this.props.askFor('segments', 'segments/getcustomsegmentlist', [], false, {}, cacheId)
            if (this._isMounted) {
                if (data != null) {
                    if (data.length == 0) {
                        this.setState({ segmentList: data, recharge: false })
                    } else {
                        this.setState({ segmentList: data, recharge: false })
                    }
                }

            }
        } catch (err) {
            console.log(err);
        }
    }

    async componentDidUpdate(prevProps, prevState) {
        if ((prevState.segmentList != this.state.segmentList) && (this.state.segmentList == null)) {
            try {
                let cacheId = "CustomSegmentListNoCache" + Math.round(Math.random() * 1000);
                let data = await this.props.askFor('segments', 'segments/getcustomsegmentlist', [], false, {}, cacheId)
                if (this._isMounted) {
                    if (data != null) {
                        if (data.length == 0) {
                            this.setState({ segmentList: null })
                        } else {
                            this.setState({ segmentList: data })
                        }
                    }

                }


            } catch (err) {
                console.log(err);
            }
        }

        if (prevProps.labels != this.props.labels) {
            this.labels = this.props.labels;
            this.forceUpdate();
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }


    //1. SEGMENTS FUNCTIONS ---------------------------------------------------------------------------------------------------------------
    toogleDeleteQuestion(id) {
        if (this._isMounted) {
            this.setState(state => { return ({ hideDeleteQuestion: !state.hideDeleteQuestion, deleteSegmentId: id }) })
        }
    }

    async deleteSegment() {
        try {
            let cacheId = "DeleteSegment" + Math.round(Math.random() * 1000);
            let res = await this.props.askFor('segments', 'segments/deleteCustomSegment', [], false, { segmentId: this.state.deleteSegmentId }, cacheId);
            console.log(res);
            if (this._isMounted) {
                if (res !== null) {
                    this.setState({ segmentList: null, hideDeleteQuestion: true, deleteSegmentId: null }, () => { /* this.toogleDeleteQuestion(this.state.deleteSegmentId);  *//* this.forceUpdate() */ })
                }
            }

        } catch (err) {
            console.log(err);
        }
    }

    searchSegment(segmentName) {
        this.setState(state => {
            let indexMove = null
            state.segmentList.forEach((segment, index) => {
                if (segment.segmentName.toUpperCase().indexOf(segmentName.toUpperCase()) != -1) {
                    state.segmentList.splice(index, 1);
                    state.segmentList.unshift(segment);
                }
            });

            return ({ segmentList: state.segmentList })
        })
    }

    //2. VIEW MODE FUNCTIONS ---------------------------------------------------------------------------------------------------------------
    changeViewMode(val) {
        this.setState({ viewMode: val });
    }

    getView() {
        var screen = <></>;

        switch (this.state.viewMode) {
            case "list":
                screen = <ul className="list-unstyled">
                    {this.state.segmentList != null ?
                        this.state.segmentList.length != 0 ?
                            this.state.segmentList.map((segment, index) => {
                                return (<li key={index} >
                                    <SegmentListItem
                                        segment={segment}
                                        currencySymbol={this.props.currencySymbol}
                                        askFor={this.props.askFor}
                                        openSegmentDetail={this.props.openSegmentDetail}
                                        openSegmentModifier={this.props.openSegmentModifier}
                                        openCustomSegmentExport={this.props.openCustomSegmentExport}
                                        openDeleteQuestion={this.toogleDeleteQuestion}
                                        labels={this.labels}
                                        loadingSpinner={this.props.loadingSpinner}
                                    />
                                </li>)
                            }) :
                            <h2 className="text-center" style={{ opacity: "0.6", marginBottom: "15px" }}>Crea tu propio segmento</h2>
                        :
                        <Card>
                            <CardBody>
                                {this.props.loadingSpinner}
                                <h3 style={{ textAlign: 'center' }}>{this.labels.lblLoading}</h3>
                            </CardBody>
                        </Card>

                    }
                </ul>
                    ; break;
            case "table":
                screen = <>
                    <ReactTooltip id="segmentTableTooltip" place="top" textColor="#fff" backgroundColor="#4a92d4" />
                    <Table responsive striped bordered className="segmentTable">
                        <thead>
                            <tr>
                                <th>Nombre</th>
                                <th>Clientes</th>
                                <th>Fecha de datos</th>
                                <th>Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.segmentList.map((segment, index) => {
                                    return (<tr key={index} >
                                        <td>{segment.segmentName}</td>
                                        <td>{segment.baseData[segment.baseData.length - 1].count}</td>
                                        <td>{new Date(segment.baseData[segment.baseData.length - 1].date).toLocaleDateString()}</td>
                                        <td>
                                            <button data-for="segmentTableTooltip" className="btnViewDetail" data-tip={this.labels.lblButtonViewDetail} onClick={() => { this.props.openSegmentDetail(segment, false, segment.baseData[segment.baseData.length - 1], segment.config) }}>
                                                <i className="fa fa-eye" />
                                            </button>
                                            <button data-for="segmentTableTooltip" className="btnViewDetail" data-tip={this.labels.lblButtonExport} onClick={() => { this.props.openCustomSegmentExport(true, segment) }}>
                                                <i className="ti-download" />
                                            </button>
                                            <button data-for="segmentTableTooltip" className="btnViewDetail" data-tip={this.labels.lblButtonModify} onClick={() => { this.props.openSegmentModifier(segment) }} >
                                                <i className="fa fa-pencil-alt" />
                                            </button>
                                            <button data-for="segmentTableTooltip" className="btnViewDetail" data-tip={this.labels.lblButtonDeleteSegment} onClick={() => { this.toogleDeleteQuestion(segment.segmentID) }}>
                                                <i className="ti-trash" />
                                            </button>
                                            {/* <button data-for="segmentTableTooltip" className="btnViewDetail" data-tip={this.labels.lblButtonRefresh} className="btnViewDetail" onClick={() => {  }}>
                                                <i className="ti-reload" />
                                            </button> */}
                                        </td>
                                    </tr>)
                                })
                            }
                        </tbody>
                    </Table>
                </>
                    ; break;
            default: ; break;
        }

        return screen
    }

    getLabelValue() {
        switch (this.state.viewMode) {
            case 'list': return 'Vista de lista'; break;
            case 'table': return 'Vista de tabla'; break;
            default: ; break;
        }
    }

    //3. RENDER ---------------------------------------------------------------------------------------------------------------------------

    render() {
        return (<div >
            <Container fluid className="segmentList">
                <Row>
                    <Container fluid>
                        <Row style={{ marginBottom: '25px' }}> {/*Get Back Button */}
                            <Col className="dropdownCol" lg="8" md="3" sm="12"><h1 className="noMargin">{this.labels.lblTitle}</h1>
                                <div>
                                    <Dropdown isOpen={this.state.dropdownViewModeDisplay} toggle={() => { this.setState(s => ({ dropdownViewModeDisplay: !s.dropdownViewModeDisplay })) }}>
                                        <DropdownToggle caret>
                                            {this.getLabelValue()}
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem onClick={() => { this.changeViewMode('list') }}>Ver en lista</DropdownItem>
                                            <DropdownItem onClick={() => { this.changeViewMode('table') }}>Ver en tabla</DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>
                                </div>
                            </Col>
                            <Col className="buttonCol" lg="4" md="5" sm="12"><Button onClick={() => { this.props.openSegmentCreator() }} className="newSegmentButton"><i className="fa fa-plus" /> {this.labels.lblButtonNewSegment}</Button></Col>
                        </Row>
                        <Row style={{ marginBottom: "20px" }}> {/*Search Bar */}
                            <Col>
                                <Input type="text" placeholder={this.labels.lblSegmentListSearchBar} onChange={e => { this.searchSegment(e.target.value) }} />
                            </Col>
                        </Row>
                        <Row> {/* List of Segments */}
                            <Col>
                                {this.state.segmentList != null ?
                                    this.state.segmentList.length != 0 ?
                                        this.getView()
                                        : ""
                                    : this.props.loadingSpinner}

                            </Col>
                        </Row>
                    </Container>
                </Row>
            </Container>
            <DeleteConfirm
                labels={this.labels}
                hidden={this.state.hideDeleteQuestion}
                deleteFunction={this.deleteSegment}
                toggleDeleteConfirm={this.toogleDeleteQuestion}
                loadingSpinner={this.props.loadingSpinner}
            />
            {/* {Animacion de pantalla negra para hacer focus en la barra de filtros} */}
            <CSSTransition in={!this.state.hideDeleteQuestion} classNames="darkPanelT" timeout={500}>
                <>
                    <div className="darkPanel spinContainer" hidden={this.state.hideDeleteQuestion}>
                        {this.props.loadingSpinner}
                        {this.labels.loading}
                    </div>

                </>
            </CSSTransition>
        </div>)
    }
}

class WrapedMySegments extends React.Component {
    constructor(props) {
        super(props)
        // State: 
        // Screen has 3 posible values: "list", "creator", "detail". It determines which component to render on screen
        this.state = {
            screen: 'list',
            segmentDetail: null,
            openSegmentExport: false,
            dataRefresh: false,
        }
        this.openSegmentCreator = this.openSegmentCreator.bind(this);
        this.openSegmentList = this.openSegmentList.bind(this);
        this.openSegmentDetail = this.openSegmentDetail.bind(this);
        this.openSegmentModifier = this.openSegmentModifier.bind(this);
        this.openCustomSegmentExport = this.openCustomSegmentExport.bind(this);
        this.labels = MySegmentsLang(this.props.lang);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.screen != this.state.screen) {
            window.scrollTo(0, 0);
        }

        if ((prevState.recharge != this.state.recharge) && (this.state.recharge == true)) {
            //this.setState({recharge:false});
        }

        if (prevProps.lang != this.props.lang) {
            this.labels = MySegmentsLang(this.props.lang);
            this.forceUpdate();
        }
    }

    // 1. NAVIGATION FUNCTIONS -----------------------------------------------------------------------------------------------------------------------
    openSegmentCreator() {
        this.setState({ screen: "creator" })
    }

    openSegmentList() {
        this.setState({ screen: "list" })
    }

    openSegmentDetail(segment, dataChange, newBaseData, newConfig) {
        if (dataChange) {
            segment.baseData.push(newBaseData)
            segment.config = newConfig;
            this.setState({ screen: "detail", segmentDetail: segment, dataRefresh: true })
        }
        else {
            this.setState({ screen: "detail", segmentDetail: segment })
        }

    }

    openSegmentModifier(segment) {
        this.setState({ screen: "modify", segmentDetail: segment })
    }

    openCustomSegmentExport(bool, segment) {
        if (bool == undefined) { bool = false }
        if (segment == undefined) { segment = null }
        this.setState({ openSegmentExport: bool, segmentDetail: segment });

    }


    // 2. SEGMENT SELECTOR FUNCTIONS -----------------------------------------------------------------------------------------------------------------------
    getMainScreen() {
        switch (this.state.screen) {
            case "creator": return (
                <SegmentCreator
                    openSegmentList={this.openSegmentList}
                    currencySymbol={this.props.currencySymbol}
                    askFor={this.props.askFor}
                    getCatData={this.props.getCatData}
                    bucketId={this.props.bucketId}
                    ageBrackets={this.props.ageBrackets}
                    clasificationList={this.props.clasificationList}
                    labels={this.labels}
                    loadingSpinner={this.props.loadingSpinner}
                />
            ); break;

            case "detail": return (
                <CustomSegmentDetail
                    askFor={this.props.askFor}
                    openSegmentList={this.openSegmentList}
                    segment={this.state.segmentDetail}
                    currencySymbol={this.props.currencySymbol}
                    isSectionHidden={this.props.isSectionHidden}
                    isFieldHidden={this.props.isFieldHidden}
                    lang={this.props.lang}
                    labels={this.labels}
                    loadingSpinner={this.props.loadingSpinner}
                    dataRefresh={this.state.dataRefresh}
                    theme={this.props.theme}
                    getBucketLabels={this.props.getBucketLabels}
                />
            ); break;

            case "list": return (
                <SegmentList
                    askFor={this.props.askFor}
                    openSegmentCreator={this.openSegmentCreator}
                    openSegmentDetail={this.openSegmentDetail}
                    openSegmentModifier={this.openSegmentModifier}
                    openCustomSegmentExport={this.openCustomSegmentExport}
                    currencySymbol={this.props.currencySymbol}
                    labels={this.labels}
                    loadingSpinner={this.props.loadingSpinner}
                />
            ); break;

            case "modify": return (
                <SegmentCreator
                    openSegmentList={this.openSegmentList}
                    currencySymbol={this.props.currencySymbol}
                    askFor={this.props.askFor}
                    getCatData={this.props.getCatData}
                    bucketId={this.props.bucketId}
                    ageBrackets={this.props.ageBrackets}
                    clasificationList={this.props.clasificationList}
                    labels={this.labels}
                    segment={this.state.segmentDetail}
                    loadingSpinner={this.props.loadingSpinner}
                />
            ); break;
        }
    }

    // 3.RENDER --------------------------------------------------------------------------------------------------------------------------------------------
    render() {
        return (
            <div className="content">
                {this.getMainScreen()}

                {/* {Animacion de pantalla negra para hacer focus} */}
                <CSSTransition in={this.state.openSegmentExport} classNames="darkPanelT" timeout={500}>
                    <>
                        <div className="darkPanel spinContainer" hidden={!this.state.openSegmentExport} onClick={() => { this.openCustomSegmentExport(false) }}>
                            {this.loadingSpinner}
                            {this.labels.loading}
                        </div>

                    </>
                </CSSTransition>

                {/* Panel de Exportación */}
                {this.state.openSegmentExport ?
                    <CSSTransition classNames="animated" timeout={500} in={true}>
                        <ExportCreatorForCustomSegments
                            customSegment={this.state.segmentDetail}
                            openCustomSegmentExport={this.openCustomSegmentExport}
                            askFor={this.props.askFor}
                            lang={this.props.lang}
                            closeExport={this.toggleSegmentExport}
                            loadingSpinner={this.props.loadingSpinner}
                        />
                    </CSSTransition>
                    : ""}


            </div>
        )
    }


}

var MySegments = props => {
    return (
        <FetchCmp
            render={props => <WrapedMySegments
                currencySymbol={props.bucket.currencySymbol}
                askFor={props.askFor}
                getCatData={props.getCatDat}
                isSectionHidden={props.isSectionHidden}
                isFieldHidden={props.isFieldHidden}
                bucketId={props.bucket.bucketId}
                theme={props.bucket.theme}
                ageBrackets={props.bucket.ageBrackets}
                clasificationList={props.bucket.clasificationlist}
                lang={props.lang}
                loadingSpinner={props.loadingSpinner}
                getBucketLabels={props.getLabels}
            />
            }
        />
    )
}

export default MySegments;
export { SegmentSummary, SegmentListItem }