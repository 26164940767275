import React from "react";
// nodejs library that concatenates classes
import { ProcessingPeriodsLang } from '../../lang'

// reactstrap components
import {
  Container,
  Row,
  Col,
} from "reactstrap";




class Processingperiods extends React.Component {
  constructor(props) {
    super(props)
    this.state = { labels: ProcessingPeriodsLang(props.lang), dataAvailable: false, data: null }
    this._isMounted = false;

  }

  async componentDidMount(){
    try{
      this._isMounted=true;
      var l = await this.props.getBucketLabels("ProcessingPeriods", ProcessingPeriodsLang(this.props.lang));
      if(l != null){
        this.setState({labels: l});
      }
      var periods = await this.props.askFor('globalsum', 'global/getprocessedperiods');
      this.setState({data:periods, dataAvailable:true});
    }
    catch(err){
      console.log(err);
    }
    
  }

  componentDidUpdate(prevProps) {
    if (prevProps.lang != this.props.lang) {
      if(this._isMounted){
        this.setState({ labels: ProcessingPeriodsLang(this.props.lang)}, ()=>{this.forceUpdate()});
      }
    }
  }

  componentWillUnmount(){
    this._isMounted = false;
  }

  readyDate(date) {
    var d = date.toString().split("")
    return d[0] + d[1] + d[2] + d[3] + "-" + d[4] + d[5];
  }


  render() {
    if (this.state.dataAvailable) {
      var minDate = this.readyDate(this.state.data.min);
      var maxDate = this.readyDate(this.state.data.max);
    }


    return (<>
      {this.state.dataAvailable ?
        <Container fluid>
          <Row>
            <Col sm='12' lg='12'>
              <Row className="text-right">
                <Col xs="12">
                  <p>{this.state.labels.lblTitle}</p>
                  <h5 className="stdOutData">{minDate} | {maxDate}</h5>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        : ""}
    </>)
  }
}


export default Processingperiods;