import React, { useState, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Button } from 'reactstrap';
import ReactTooltip from "react-tooltip";
import classNames from "classnames";
import { CSSTransition } from "react-transition-group"
// import aes from "crypto-js/aes";
import templateConfiguration from './files/configTemplate.json';
import Switch from "react-switch";
// import classnames from 'classnames';

// 0. Const declaration
//EMAIL -----------------------------------------------------------------------------------------------------------------
const _defaultEmailApiKey = "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";
const _defaultEmailApiUrl = "https://api.sendinblue.com/v3/emailCampaigns";
const _defaultEmail = "pedrom@loymark.com";
const _defaultEmailKeyFileUrl = "Internal";
const _defaultEmailBucketName = "corttezamailinglists";
//SMS --------------------------------------------------------------------------------------------------------------------------
const _smsDefaultUser = "Lifemiles";
const _smsDefaultPass = "2020Lmiles";
const _smsDefaultApiUrl = "http://api.messaging-service.com";
//FACEBOOK ---------------------------------------------------------------------------------------------------------------------
const _defaultFacebookAppId = "309779753520300";
const _defaultFacebookAddAcountId = "act_2915610455134713";
//CUPONERA ---------------------------------------------------------------------------------------------------------------------
const _defaultCuponeraApiUrl = "https://cuponeraapidev.loymark.com/api/coupons";

// 2. Component
var AdminCampaign = props => {

    //1. Var initialization
    const hasConfiguration = props.campaignConfig === undefined ? false : true;
    var baseConfiguration = null;
    if (hasConfiguration) {
        baseConfiguration = JSON.parse(JSON.stringify(props.campaignConfig));
    }
    else {
        baseConfiguration = JSON.parse(JSON.stringify(templateConfiguration))
    }
    const isAvailable = baseConfiguration.email.enabled || baseConfiguration.sms.enabled || baseConfiguration.email.facebook ? true : false;
    const [campaignAvailable, setCampaignAvailable] = useState(isAvailable);
    const [configurationObject, setConfigObject] = useState({ ...baseConfiguration });
    const [isLoading, setLoading] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);




    useEffect(() => {
        ReactTooltip.rebuild()
        return () => {
        }
    })


    // 2. Utility Functions

    var toggleCampaignAvailable = () => {
        if (campaignAvailable) {
            setCampaignAvailable(false);
            setConfigObject(templateConfiguration);
        }
        else {
            setCampaignAvailable(true);
            setConfigObject(templateConfiguration);
        }
    }

    var toggleEmail = () => {
        setConfigObject(co => {
            co.email.enabled = !co.email.enabled;
            return { ...co }
        })
    }

    var toggleSms = () => {
        setConfigObject(co => {
            co.sms.enabled = !co.sms.enabled;
            return { ...co }
        })
    }

    var toggleFacebook = () => {
        setConfigObject(co => {
            co.facebook.enabled = !co.facebook.enabled;
            return { ...co }
        })
    }

    var toggleCuponera = () => {
        setConfigObject(co => {
            co.cuponera.enabled = !co.cuponera.enabled;
            return { ...co }
        })
    }

    var checkForChanges = () => {
        var bool = JSON.stringify(configurationObject) === JSON.stringify(baseConfiguration);
        if (bool) {
            return false;
        }
        else {
            return true;
        }
    }

    // 3. Configuration Object Functions
    var toggleDefaultEmailConfig = () => {
        setConfigObject(co => {
            co.email.use_default = !co.email.use_default;
            return { ...co }
        });
    }

    var toggleDefaultSmsConfig = () => {
        setConfigObject(co => {
            co.sms.use_default = !co.sms.use_default;
            return { ...co }
        });
    }

    var toggleDefaultFacebookConfig = () => {
        setConfigObject(co => {
            co.facebook.use_default = !co.facebook.use_default;
            return { ...co }
        });
    }

    var resetCuponeraToken = () => {
        setConfigObject(so => {
            so.cuponera.validToken = templateConfiguration.cuponera.validToken;
            return { ...so }
        })
    }

    var updateConfigurationObject = (type, prop, value, secondLevelProp) => {
        setConfigObject(co => {
            if (secondLevelProp === undefined) {
                co[type][prop] = value;
            }
            else {
                co[type][prop][secondLevelProp] = value;
            }
            return { ...co }
        });
    }

    var handleDemoSwitch = (checked, event, id) => {
        setConfigObject(co => {
            switch (id) {
                case 'email': co.email.is_demo = checked;; break;
                case 'sms': co.sms.is_demo = checked;; break;
                case 'facebook': co.facebook.is_demo = checked;; break;
                default: ; break;
            }

            return {...co}
        })
    }


    // 4. Pre-render functions
    var getStorageForm = () => {
        var form = <></>;

        switch (configurationObject.email.storage.type) {
            case 'google':
                form = <CSSTransition mountOnEnter unmountOnExit classNames="ani" timeout={350} in={configurationObject.email.storage.type === "google"} appear={configurationObject.email.storage.type === "google"}>
                    <div style={{ marginTop: "15px" }}>
                        {configurationObject.email.use_default ?
                            <>
                                <label>Email:</label>
                                <input type="text" disabled={configurationObject.email.use_default} value={_defaultEmail} onChange={(e) => { updateConfigurationObject('email', 'storage', e.target.value, 'user') }} />

                                <label>Key File Url:</label>
                                <input type="text" disabled={configurationObject.email.use_default} value={_defaultEmailKeyFileUrl} onChange={(e) => { updateConfigurationObject('email', 'storage', e.target.value, 'key_file_url') }} />

                                <label>Nombre del bucket</label>
                                <input type="text" disabled={configurationObject.email.use_default} value={_defaultEmailBucketName} onChange={(e) => { updateConfigurationObject('email', 'storage', e.target.value, 'bucket') }} />
                            </>
                            :
                            <>
                                <label>Email:</label>
                                <input type="text" disabled={configurationObject.email.use_default} value={configurationObject.email.storage.user || ""} onChange={(e) => { updateConfigurationObject('email', 'storage', e.target.value, 'user') }} />

                                <label>Key File Url:</label>
                                <input type="text" disabled={configurationObject.email.use_default} value={configurationObject.email.storage.key_file_url || ""} onChange={(e) => { updateConfigurationObject('email', 'storage', e.target.value, 'key_file_url') }} />

                                <label>Nombre del bucket</label>
                                <input type="text" disabled={configurationObject.email.use_default} value={configurationObject.email.storage.bucket || ""} onChange={(e) => { updateConfigurationObject('email', 'storage', e.target.value, 'bucket') }} />
                            </>
                        }

                    </div>
                </CSSTransition >
                    ; break;
            case 'aws':
                form = <CSSTransition mountOnEnter unmountOnExit classNames="ani" timeout={350} in={true} appear={true}>
                    <div style={{ marginTop: "15px" }}>
                        <label>Usuario:</label>
                        <input type="text" disabled={configurationObject.email.use_default} value={configurationObject.email.storage.user || ""} onChange={(e) => { updateConfigurationObject('email', 'storage', e.target.value, 'user') }} />

                        <label>Llave de acceso:</label>
                        <input type="text" disabled={configurationObject.email.use_default} value={configurationObject.email.storage.key_file_url || ""} onChange={(e) => { updateConfigurationObject('email', 'storage', e.target.value, 'key_file_url') }} />
                    </div>
                </CSSTransition>
                    ; break;
            default: ; break;
        }

        return form;
    }

    var getContent = () => {
        var content = <></>;


        if (campaignAvailable) {
            content =
                <div>
                    <ReactTooltip id="adminCampaignTooltip" place="right" />

                    <CSSTransition mountOnEnter unmountOnExit classNames="ani" timeout={350} in={configurationObject.email.enabled} appear={configurationObject.email.enabled} >
                        <Row className="emailsRow">
                            <Row className="titleRow">
                                <h4>Correo Electrónico (@)</h4>
                                <div>
                                    <label>Modo Demo:</label>
                                    <Switch id="email" onChange={handleDemoSwitch} checked={configurationObject.email.is_demo} />
                                </div>
                            </Row>



                            <div className="formGroup">
                                <input type="checkbox" checked={configurationObject.email.use_default} onChange={toggleDefaultEmailConfig} />
                                <label className="w30">Utilizar valores por defecto</label>
                            </div>

                            <div className="formGroup">
                                <p><b>API url</b>  <span className="tip" data-tip="Url base del API encargado del envío de correos electrónicos" data-for="adminCampaignTooltip">?</span></p>
                                <label>Url:</label>
                                {configurationObject.email.use_default ?
                                    <input type="text" disabled={configurationObject.email.use_default} value={_defaultEmailApiUrl} onChange={(e) => { updateConfigurationObject('sms', 'credentials', e.target.value, 'user') }} />
                                    :
                                    <input type="text" disabled={configurationObject.email.use_dafault} value={configurationObject.email.api_base_url} onChange={(e) => { }} />
                                }
                            </div>

                            <div className="formGroup">
                                <p><b>Credenciales de Send In Blue</b>  <span className="tip" data-tip="Api key de Send In Blue" data-for="adminCampaignTooltip" >?</span></p>
                                <label>Api key:</label>
                                {configurationObject.email.use_default ?
                                    <input type="text" disabled={configurationObject.email.use_default} value={_defaultEmailApiKey} onChange={(e) => { updateConfigurationObject('email', 'sib_apikey', e.target.value) }} />
                                    :
                                    <input type="text" disabled={configurationObject.email.use_default} value={configurationObject.email.sib_apikey || ""} onChange={(e) => { updateConfigurationObject('email', 'sib_apikey', e.target.value) }} />
                                }
                            </div>

                            <div className="formGroup">
                                <p><b>Límite de correos Mensual</b> <span className="tip" data-tip="Lite de cantidad de correos que pueden ser enviados mensualmente" data-for="adminCampaignTooltip">?</span></p>
                                <label>Limite:</label>
                                <input type="number"  value={configurationObject.email.month_limit || 0} onChange={(e) => { updateConfigurationObject('email', 'month_limit', e.target.value) }} />
                            </div>

                            <div className="formGroup">
                                <p><b>Almacenamiento</b>  <span className="tip" data-tip="Tipo de almacenamiento para listas de clientes y contenido multimedia" data-for="adminCampaignTooltip">?</span></p>
                                <Button onClick={() => { updateConfigurationObject('email', 'storage', 'google', 'type') }} className={classNames({ active: configurationObject.email.storage.type === "google", disabled: configurationObject.email.use_default })} disabled={configurationObject.email.use_default}>Google</Button>
                                <Button onClick={() => { updateConfigurationObject('email', 'storage', 'aws', 'type') }} className={classNames({ active: configurationObject.email.storage.type === "aws", disabled: configurationObject.email.use_default })} disabled={configurationObject.email.use_default}>AWS</Button>
                                {getStorageForm()}
                            </div>


                        </Row>
                    </CSSTransition>

                    <CSSTransition mountOnEnter unmountOnExit classNames="ani" timeout={350} in={configurationObject.sms.enabled} appear={configurationObject.sms.enabled}>
                        <Row className="smsRow">
                            <Row className="titleRow">
                                <h4>Mensajes de Texto ( <span className="ti-email" /> )</h4>
                                <div>
                                    <label>Modo Demo:</label>
                                    <Switch id="sms" onChange={handleDemoSwitch} checked={configurationObject.sms.is_demo} />
                                </div>
                            </Row>
                            

                            <div className="formGroup">
                                <input type="checkbox" checked={configurationObject.sms.use_default} onChange={toggleDefaultSmsConfig} />
                                <label className="w30">Utilizar valores por defecto</label>
                            </div>


                            <div className="formGroup">
                                <p><b>API url</b>  <span className="tip" data-tip="Url base del API encargado del envío de los mensajes de texto" data-for="adminCampaignTooltip">?</span></p>
                                <label>Url:</label>
                                {configurationObject.sms.use_default ?
                                    <input type="text" disabled={configurationObject.sms.use_default} value={_smsDefaultApiUrl} onChange={(e) => { updateConfigurationObject('sms', 'credentials', e.target.value, 'user') }} />
                                    :
                                    <input type="text" disabled={configurationObject.sms.use_dafault} value={configurationObject.sms.api_base_url} onChange={(e) => { }} />
                                }
                            </div>

                            <div className="formGroup">
                                <p><b>Credenciales de icomnkit</b>  <span className="tip" data-tip="Credenciales de servicios de SMS" data-for="adminCampaignTooltip">?</span></p>
                                <label>User:</label>
                                {configurationObject.sms.use_default ?
                                    <input type="text" disabled={configurationObject.sms.use_default} value={_smsDefaultUser} onChange={(e) => { updateConfigurationObject('sms', 'credentials', e.target.value, 'user') }} />
                                    :
                                    <input type="text" disabled={configurationObject.sms.use_dafault} value={configurationObject.sms.credentials.user} onChange={(e) => { updateConfigurationObject('sms', 'credentials', e.target.value, 'user') }} />
                                }


                                <label>Contraseña:</label>
                                {configurationObject.sms.use_default ?
                                    <input type="password" disabled={configurationObject.sms.use_default} value={_smsDefaultPass} onChange={(e) => { updateConfigurationObject('sms', 'credentials', e.target.value, 'password') }} />
                                    :
                                    <input type="password" disabled={configurationObject.sms.use_default} value={configurationObject.sms.credentials.password} onChange={(e) => { updateConfigurationObject('sms', 'credentials', e.target.value, 'password') }} />
                                }

                            </div>




                        </Row>
                    </CSSTransition>

                    <CSSTransition mountOnEnter unmountOnExit classNames="ani" timeout={350} in={configurationObject.facebook.enabled} appear={configurationObject.facebook.enabled}>
                        <Row className="facebookRow">
                            <Row className="titleRow">
                                <h4>Audiencias ( <span className="ti-facebook" /> )</h4>
                                <div>
                                    <label>Modo Demo:</label>
                                    <Switch id="facebook" onChange={handleDemoSwitch} checked={configurationObject.facebook.is_demo} />
                                </div>
                            </Row>
                            

                            <div className="formGroup">
                                <input type="checkbox" checked={configurationObject.facebook.use_default} onChange={toggleDefaultFacebookConfig} />
                                <label className="w30">Utilizar valores por defecto</label>
                            </div>

                            <div className="formGroup">
                                <p><b>Credenciales</b>  <span className="tip" data-tip="Credenciales relacionadas a Facebook" data-for="adminCampaignTooltip">?</span></p>
                                <label>AppId:</label>
                                {configurationObject.facebook.use_default ?
                                    <input type="text" disabled={configurationObject.facebook.use_default} value={_defaultFacebookAppId} onChange={(e) => { updateConfigurationObject('facebook', 'appId', e.target.value) }} />
                                    :
                                    <input type="text" disabled={configurationObject.facebook.use_default} value={configurationObject.facebook.appId} onChange={(e) => { updateConfigurationObject('facebook', 'appId', e.target.value) }} />
                                }
                            </div>

                            <div className="formGroup">
                                <label>AdAccountId:</label>
                                {configurationObject.facebook.use_default ?
                                    <input type="text" disabled={configurationObject.facebook.use_default} value={_defaultFacebookAddAcountId} onChange={(e) => { updateConfigurationObject('facebook', 'credentials', e.target.value, 'user') }} />
                                    :
                                    <input type="text" disabled={configurationObject.facebook.use_default} value={configurationObject.facebook.credentials.user} onChange={(e) => { updateConfigurationObject('facebook', 'credentials', e.target.value, 'user') }} />
                                }
                            </div>

                        </Row>
                    </CSSTransition>

                    <CSSTransition mountOnEnter unmountOnExit classNames="ani" timeout={350} in={configurationObject.cuponera.enabled} appear={configurationObject.cuponera.enabled}>
                        <Row className="cuponeraRow">
                            <h4>Cuponera ( <span className="ti-ticket" /> )</h4>

                            <div className="formGroup">
                                <p><b>API url</b>  <span className="tip" data-tip="Url base del API encargado de la cuponera" data-for="adminCampaignTooltip">?</span></p>
                                <label>Url:</label>
                                <input type="text" value={configurationObject.cuponera.api_base_url} onChange={(e) => { updateConfigurationObject('cuponera', 'api_base_url', e.target.value) }} />
                            </div>

                            <div className="formGroup">
                                <p><b>Link de acción</b>  <span className="tip" data-tip="Url a redirigir en los envíos de email" data-for="adminCampaignTooltip">?</span></p>
                                <label>Url:</label>
                                <input type="text" value={configurationObject.cuponera.link_to_action} onChange={(e) => { updateConfigurationObject('cuponera', 'link_to_action', e.target.value) }} />
                            </div>

                            <div className="formGroup">
                                <p><b>Credenciales de Cuponera</b>  <span className="tip" data-tip="Credenciales para utilizar la Cuponera de Loymark" data-for="adminCampaignTooltip">?</span></p>
                                <label>Nombre:</label>
                                <input type="text" value={configurationObject.cuponera.credentials.user} onChange={(e) => { updateConfigurationObject('cuponera', 'credentials', e.target.value, 'user'); resetCuponeraToken() }} />
                                <label>Contraseña:</label>
                                <input type="password" value={configurationObject.cuponera.credentials.password} onChange={(e) => { updateConfigurationObject('cuponera', 'credentials', e.target.value, 'password'); resetCuponeraToken() }} />
                            </div>
                        </Row>
                    </CSSTransition>

                </div>
        }

        return content
    }

    // 5. API Calls

    var saveNewConfiguration = async () => {
        setLoading(true);
        let cacheId = "SeveNewCampaignConfig" + Math.floor(Math.random() * 1000001);
        let res = await props.askFor('campaigns', 'updateConfiguration', [], false, { config: configurationObject }, cacheId);
        if (res !== null) {
            setShowSuccessMessage(true);
            setInterval(() => {
                setShowSuccessMessage(false);
            }, 3000);
            props.changeBucketCampConf(JSON.parse(JSON.stringify(configurationObject)))
            if (configurationObject.email.enabled || configurationObject.sms.enabled || configurationObject.facebook.enabled) { props.toggleCampaignRute(true); }
            else { props.toggleCampaignRute(false); }
            setLoading(false);
        }
        else {

        }
    }

    // 6. Render ------------------------------------------------------------------------------------------------
    return (
        <div className="adminScreenContent">
            {isLoading ?
                props.loadingSpinner
                :
                <Container fluid className="campaigns">
                    <Row className="titleRow">
                        <h3>Campañas</h3>
                        <CSSTransition mountOnEnter unmountOnExit classNames="ani" timeout={350} in={showSuccessMessage} appear={showSuccessMessage}>
                            <p><b>¡Configuración guardada corrrectamente!</b></p>
                        </CSSTransition>
                        <CSSTransition mountOnEnter unmountOnExit classNames="ani" timeout={350} in={checkForChanges()} appear={checkForChanges()}>
                            <Button onClick={() => { saveNewConfiguration() }}>Guardar</Button>
                        </CSSTransition>
                    </Row>
                    <Row className="activateRow">
                        <div className="formGroup">
                            <input type="checkbox" checked={campaignAvailable} onChange={toggleCampaignAvailable} />
                            <label>Habilitar Módulo de Campañas</label>
                        </div>

                        <CSSTransition mountOnEnter unmountOnExit classNames="ani" timeout={350} in={campaignAvailable} appear={campaignAvailable}>
                            <div style={{ paddingLeft: "5%", paddingTop: "10px" }}>

                                <div className="formGroup">
                                    <input type="checkbox" checked={configurationObject.email.enabled} onChange={toggleEmail} />
                                    <label>Habilitar Módulo de Email</label>
                                </div>

                                <div className="formGroup">
                                    <input type="checkbox" checked={configurationObject.sms.enabled} onChange={toggleSms} />
                                    <label>Habilitar Módulo de Mensajes de Texto</label>
                                </div>

                                <div className="formGroup">
                                    <input type="checkbox" checked={configurationObject.facebook.enabled} onChange={toggleFacebook} />
                                    <label>Habilitar Módulo de Audiencias</label>
                                </div>

                                <div className="formGroup">
                                    <input type="checkbox" checked={configurationObject.cuponera.enabled} onChange={toggleCuponera} />
                                    <label>Habilitar Cuponera</label>
                                </div>

                            </div>
                        </CSSTransition>
                    </Row>
                    {getContent()}
                </Container>
            }
        </div>
    )
}

AdminCampaign.propTypes = {
    campaignConfig: PropTypes.object,
}

export { AdminCampaign };