import React from "react";
import classnames from 'classnames';
import { CampaignCreatorLang } from '../../../lang'
// import DatePicker from "react-datepicker";
// import ReactTooltip from "react-tooltip";
// import FacebookLogin from 'react-facebook-login';
// import EmailEditor from 'react-email-editor';
// reactstrap components
import {
    Container, Row,
    Button,
} from "reactstrap";
// import NumberFormat from "react-number-format";
// import { Line, Bar, Pie, Polar } from "react-chartjs-2";
import { ReactComponent as EmailIcon } from '../../../assets/icons/createCampaign/email-icon.svg';
import { ReactComponent as SmsIcon } from '../../../assets/icons/createCampaign/sms-icon.svg';
// import { ReactComponent as PushIcon } from '../../../assets/icons/createCampaign/push-icon.svg';
import { ReactComponent as FacebookIcon } from '../../../assets/icons/createCampaign/facebook-icon.svg';
// import { ReactComponent as RetainIcon } from '../../../assets/icons/createCampaign/retain-icon.svg';
// import { ReactComponent as IncreaseMIcon } from '../../../assets/icons/createCampaign/increaseM-icon.svg';
// import { ReactComponent as IncreaseFIcon } from '../../../assets/icons/createCampaign/increaseF-icon.svg';
// import { ReactComponent as BringBackIcon } from '../../../assets/icons/createCampaign/bringBack-icon.svg';
// import { ReactComponent as OccasionalsIcon } from '../../../assets/icons/createCampaign/ocasionals-icon.svg';
import {
    SelectCard,
    // CampaignDatePicker,
    // SmsCampaignForm,
    FacebookCampaignForm,
} from "./CampaignCreatorSubComponents";
import EmailCampaignForm from './Forms/EmailCampaignForm';
import SmsCampaignForm from './Forms/SmsCampaignForm';


class CampaignCreator extends React.Component {
    constructor(props) {
        super(props)
        this._isMounted = false;
        this.labels = CampaignCreatorLang(props.lang);
        this.state = {
            //Global state props
            step: 1, // 1. Select Campaign Type|2. Campaign Construction| 3. Campaign Confirmation
            campaignType: null, // email | sms | push | facebook
            selectionCount: 0,
            showLoadingSpinner: false,
            loadingStep: false,
            //EMAIL PROPS
            testEmail: null,
            testEmailSent: false,
            testEmailError: false,
            file: null,
            filePreview: null, //fe only prop
            cuponCount: 0,
            campaign: {
                name: '',
                subject: '',
                segments: [],
                cupon: null,
                redirectUrl: '',
                html: null,
            }, //Object that is sent to the BE
            //SMS PROPS
            maxCharacters: 160,
            selectedDate: new Date(),
            showDatePicker: false,
            testPhoneNumber: null,
            testSmsSent: false,
            //FACEBOOK PROPS
            isFacebookLogIn: false,
            audience: {
                name: null,
                description: null,
            },
            facebookToken: null,
        }
        this.setStep = this.setStep.bind(this);
        this.setCampaignType = this.setCampaignType.bind(this);
        this.setCampaignName = this.setCampaignName.bind(this);
        this.setCampaignSubject = this.setCampaignSubject.bind(this);
        this.setCampaignImg = this.setCampaignImg.bind(this);
        this.setCampaignHtml = this.setCampaignHtml.bind(this);
        this.setCampaignSegments = this.setCampaignSegments.bind(this);
        this.setCampaignCupon = this.setCampaignCupon.bind(this);
        this.setCampaignRedirectUrl = this.setCampaignRedirectUrl.bind(this);
        this.isSegmentActive = this.isSegmentActive.bind(this);
        this.resetCampaignSegments = this.resetCampaignSegments.bind(this);
        this.setSelectedDate = this.setSelectedDate.bind(this);
        this.setTestPhoneNumber = this.setTestPhoneNumber.bind(this);
        this.setTestEmail = this.setTestEmail.bind(this);
        this.setTestEmailError = this.setTestEmailError.bind(this);
        this.toggleDatePicker = this.toggleDatePicker.bind(this);
        this.setIsFacebookLogIn = this.setIsFacebookLogIn.bind(this);
        this.setAudienceName = this.setAudienceName.bind(this);
        this.setAudienceDecription = this.setAudienceDecription.bind(this);
        this.summitAndSaveCampaign = this.summitAndSaveCampaign.bind(this);
        this.summitAndScheduleCampaign = this.summitAndScheduleCampaign.bind(this);
        this.createFacebookAudience = this.createFacebookAudience.bind(this);
        this.sendTestSms = this.sendTestSms.bind(this);
        this.sendTestEmail = this.sendTestEmail.bind(this);
        this.setLoadingStep = this.setLoadingStep.bind(this);
        this.toggleTestSmsSent = this.toggleTestSmsSent.bind(this);
        this.toggleTestEmailSent = this.toggleTestEmailSent.bind(this);
        this.breadcrumbs = [
            { text: this.labels.lblStep1, function: this.setStep, functionValue: 1 },
            { text: this.labels.lblStep2, function: this.setStep, functionValue: 2 },
            { text: this.labels.lblStep3, function: this.setStep, functionValue: 3 },
            { text: this.labels.lblStep4, function: this.setStep, functionValue: 4 },
        ];
    }

    //0. REACT LIFECYCLE
    async componentDidMount() {
        this._isMounted = true;
    }

    async componentDidUpdate(prevProps, prevState) {
        if (prevProps.lang !== this.props.lang) {
            if (this._isMounted) {
                this.labels = CampaignCreatorLang(this.props.lang)
                this.forceUpdate()
            }
        }
    }

    //1. FUNCTIONS
    setCampaignType = campaignType => {
        if (campaignType === undefined) { campaignType = null }
        if (campaignType === this.state.campaignType) { campaignType = null }
        if (this._isMounted) {
            this.setState({ campaignType: campaignType })
        }
    }

    setStep = step => {
        if (step === undefined) { step = 1 }
        if (this._isMounted) {
            switch (step) {
                // case 1: this.setState({ step: step }); break;
                case 2:
                    if (this.state.campaignType !== null) {
                        this.setState({ step: step })
                    }
                    ; break;
                case 3:
                    if (this.state.campaignType === "email" && this.state.campaign.name !== "" && this.state.campaign.subject !== "" && (this.state.file !== null || this.state.campaign.cupon !== null || this.state.campaign.html !== null)) {
                        this.setState({ step: step })
                    }
                    if (this.state.campaignType === "sms" && this.state.campaign.name !== "" && this.state.campaign.subject) {
                        this.setState({ step: step })
                    }
                    if (this.state.campaignType === "facebook") {
                        this.setState({ step: step })
                    }
                    ; break;
                case 4:
                    if (this.state.campaignType !== null && this.state.campaign.segments.length > 0) {
                        this.setState({ step: step })
                    }
                    ; break;
                default: ; break;
            }

        }
    }

    resetState = () => {
        if (this._isMounted) {
            this.setState({
                step: 1,
                campaignType: null,
                selectionCount: 0,
                cuponCount: 0,
                campaign: {
                    name: '',
                    subject: '',
                    segments: [],
                },
                file: null,
                filePreview: null,
                maxCharacters: 160,
                testPhoneNumber: null,
                testSmsSent: false,
                selectedDate: new Date(),
                testEmail: null,
                testEmailSent: false,
                testEmailError: false,

            }, () => { this.forceUpdate() })
        }
    }

    setLoadingStep = bool => {
        if (this._isMounted) {
            this.setState({ showLoadingSpinner: bool })
        }
    }

    //1.1 EMAIL

    setCampaignName = val => {
        if (this._isMounted) {
            this.setState(state => {
                var c = state.campaign;
                c.name = val;
                return ({ campaign: c })
            })
        }
    }

    setCampaignSubject = val => {
        if (this._isMounted) {
            if (this.state.campaignType === "email") {
                this.setState(state => {
                    var c = state.campaign;
                    c.subject = val;
                    return ({ campaign: c })
                })
            }

            if (this.state.campaignType === "sms") {
                if (this.state.maxCharacters >= 0) {
                    this.setState(state => {
                        var c = state.campaign;
                        var maxC = state.maxCharacters;
                        var valLength = val.length;
                        let textLength = c.subject.length

                        if (valLength > textLength && (valLength - textLength) === 1) {
                            maxC--;
                        }

                        if (valLength < textLength && (textLength - valLength) === 1) {
                            maxC++;
                        }

                        if (valLength > textLength && (valLength - textLength) !== 1) {
                            maxC = 160 - valLength;
                        }

                        if (valLength < textLength && (textLength - valLength) !== 1) {
                            maxC = 160 - valLength;
                        }

                        if (maxC < 0) {
                            maxC = 0;
                        }

                        if (valLength === 0) {
                            maxC = 160;
                        }

                        c.subject = val;

                        return ({ campaign: c, maxCharacters: maxC })
                    })
                }

            }

        }
    }

    setCampaignImg = (val, previewVal) => {
        if (this._isMounted) {
            this.setState({ file: val, filePreview: previewVal })
        }
    }

    setCampaignHtml = (html) => {
        if (this._isMounted) {
            this.setState(state => {
                var c = state.campaign;
                c.html = html;
                return ({ campaign: c })
            })
        }
    }

    isSegmentActive = id => {
        var isActive = false;
        this.state.campaign.segments.forEach(s => {
            if (s.id === id) {
                isActive = true;
            }
        })

        return isActive;
    }

    setCampaignSegments = (val) => {
        if (this._isMounted) {
            this.setState(state => {
                var c = state.campaign;
                var sCount = state.selectionCount;
                if (this.isSegmentActive(val.id)) {
                    let ind = null;
                    c.segments.forEach((s, index) => { if (s.id === val.id) { ind = index } })
                    c.segments.splice(ind, 1);
                    sCount -= val.count;
                }
                else {
                    c.segments.push(val);
                    sCount += val.count;
                }

                if (sCount < 0) { sCount = 0 }
                return ({ campaign: c, selectionCount: sCount })
            })
        }
    }

    setCampaignRedirectUrl = val => {
        if (this._isMounted) {
            this.setState(state => {
                var c = state.campaign;
                c.redirectUrl = val;
                return ({ campaign: c })
            })
        }
    }

    setCampaignCupon = cupon => {
        var cup = {
            "ID": cupon['ID'],
            "ImageUrl": cupon["ImageList"][0]["ImageUrl"],
        }

        var cuponCount = cupon["QuantityAvailable"] - cupon["QuantityClaimed"];

        if (this._isMounted) {
            this.setState(state => {
                var c = state.campaign;
                c.cupon = cup;
                return ({ campaign: c, cuponCount: cuponCount })
            })
        }
    }

    setTestEmail = (email) => {
        if (this._isMounted) {
            this.setState({ testEmail: email })
        }
    }

    setTestEmailError = (bool) => {
        if (this._isMounted) {
            this.setState({ testEmailError: bool })
        }
    }

    toggleTestEmailSent = (bool) => {
        if (bool === undefined) { bool = false }
        if (this._isMounted) {
            this.setState({ testEmailSent: bool })
        }
    }

    resetCampaignSegments = () => {
        this.setState(state => {
            var campaign = state.campaign;
            campaign.segments = [];
            return ({ campaign: campaign, selectionCount: 0 })
        })
    }

    //1.2 SMS
    checkTextMessageLength = (text) => {
        var textLength = text.length;
        if (this.state.campaignType === "sms") {
            this.setState(state => {
                let val = state.maxCharacters - textLength;
                return ({ maxCharacters: val })
            })
        }
    }

    toggleDatePicker = (bool) => {
        if (bool === undefined) { bool = false }
        if (this._isMounted) {
            this.setState({ showDatePicker: bool })
        }
    }

    setSelectedDate = (date) => {
        if (this._isMounted) {
            this.setState({ selectedDate: date })
        }
    }

    setTestPhoneNumber = (phone) => {
        if (this._isMounted) {
            this.setState({ testPhoneNumber: phone })
        }
    }

    toggleTestSmsSent = (bool) => {
        if (bool === undefined) { bool = false }
        if (this._isMounted) {
            this.setState({ testSmsSent: bool })
        }
    }

    //1.3 FACEBOOK
    setIsFacebookLogIn(bool, token) {
        if (bool === undefined) { bool = false }
        if (token === undefined) { token = null }
        if (this._isMounted) {
            this.setState(state => { return ({ isFacebookLogIn: bool, facebookToken: token }) })
        }
    }

    setAudienceName(name) {
        if (this._isMounted) {
            this.setState(state => {
                var a = state.audience;
                a.name = name;
                return ({ audience: a })
            })
        }
    }

    setAudienceDecription(description) {
        if (this._isMounted) {
            this.setState(state => {
                var a = state.audience;
                a.description = description;
                return ({ audience: a })
            })
        }
    }

    //2. CAMPAIGN CREATION & AUDIENCE CREATION PETITION

    async summitCampaign() {
        if (this.state.campaignType === "email") {
            if (this.state.campaign.name !== "" && this.state.campaign.subject !== "" && this.state.campaign.segments.length > 0 && (this.state.file !== null || this.state.campaign.cupon !== null)) {
                var formData = new FormData();
                formData.append("campaign", JSON.stringify(this.state.campaign));
                formData.append('imgFile', this.state.file);
                this.setState({ showLoadingSpinner: true });
                var res = await this.props.formDataPetition('campaigns', "createCampaign", formData);
                if (res == null) {
                    alert("Error while creating the campaign")
                }
                this.props.changeScreen({ screen: 'list', creationCancel: false });
            }
        }

        if (this.state.campaignType === "sms") {
            if (this.state.campaign.name !== "" && this.state.campaign.subject !== "" && this.state.campaign.segments.length > 0) {
                var smsCampaign = {
                    name: this.state.campaign.name,
                    text: this.state.campaign.subject,
                    segments: this.state.campaign.segments,
                    config: { sendNow: true }
                }
                var cacheId = "CreateSMSCampaign-" + this.props.bucket.bucketId + "-" + smsCampaign.name
                this.setState({ showLoadingSpinner: true });
                var ressms = await this.props.askFor('campaigns', "createsmscampaign", [], false, smsCampaign, cacheId);
                if (ressms === null) {
                    alert("Error while creating the campaign")
                }
                this.props.changeScreen({ screen: 'list', creationCancel: false });
            }
        }
    }

    async summitAndSaveCampaign() {
        switch (this.state.campaignType) {
            case "email": ; break;
            case "sms":
                var smsCampaign = {
                    name: this.state.campaign.name,
                    text: this.state.campaign.subject,
                    segments: this.state.campaign.segments,
                    config: { sendNow: false }
                }
                var cacheId = "CreateSMSCampaign-" + this.props.bucket.bucketId + "-" + smsCampaign.name
                this.setState({ showLoadingSpinner: true });
                var res = await this.props.askFor('campaigns', "createsmscampaign", [], false, smsCampaign, cacheId);
                if (res !== null) {
                    this.props.changeScreen({ screen: 'list', creationCancel: false });
                }
                ; break;
            default: ; break
        }
    }

    async summitAndScheduleCampaign() {
        switch (this.state.campaignType) {
            case "email": ; break;
            case "sms":
                var smsCampaign = {
                    name: this.state.campaign.name,
                    text: this.state.campaign.subject,
                    segments: this.state.campaign.segments,
                    config: { sendNow: false, sendAt: this.state.selectedDate.toISOString() }
                }
                var cacheId = "CreateSMSCampaign-" + this.props.bucket.bucketId + "-" + smsCampaign.name
                this.setState({ showLoadingSpinner: true, showDatePicker: false });
                var res = await this.props.askFor('campaigns', "createsmscampaign", [], false, smsCampaign, cacheId);
                if (res !== null) {
                    this.props.changeScreen({ screen: 'list', creationCancel: false });
                }
                ; break;
            default: ; break;
        }
    }

    async sendTestSms() {
        if (this.state.campaignType === 'sms') {
            if (this.state.campaign.subject !== "") {
                var cacheId = "SendTestSms-" + this.props.bucket.bucketId + "-" + this.state.campaign.name
                var res = await this.props.askFor('campaigns', "sendTestSms", [], false, { message: this.state.campaign.subject, recipients: [this.state.testPhoneNumber] }, cacheId);
                if (res !== null) {
                    this.setState({ testSmsSent: true });
                }
            }

        }
    }

    async sendTestEmail() {
        if (this.state.campaignType === 'email') {
            if ((this.state.file !== null || this.state.campaign.cupon !== null)) {
                var formData = new FormData();
                var email = { destination: this.state.testEmail, subject: this.state.campaign.subject, content: "", cupon: this.state.campaign.cupon, redirectUrl: this.state.campaign.redirectUrl }
                formData.append("email", JSON.stringify(email));
                formData.append('imgFile', this.state.file);
                var res = await this.props.formDataPetition('campaigns', "sendTestEmail", formData);
                if (res !== null) {
                    this.setState({ testEmailSent: true, testEmailError: false });
                }
                else {
                    this.setState({ testEmailSent: true, testEmailError: true })
                }
            }
            else {
                this.setState({ testEmailSent: true, testEmailError: true })
            }

        }
    }

    async createFacebookAudience() {
        if (this.state.isFacebookLogIn && this.state.facebookToken !== null) {
            this.setState({ showLoadingSpinner: true });
            var body = {
                "token": this.state.facebookToken,
                "segments": this.state.campaign.segments,
                "name": this.state.audience.name,
                "description": this.state.audience.description,
            }
            var cacheId = "CreateFacebookAudience-" + this.props.bucket.bucketId + "-" + this.state.audience.name
            var res = await this.props.askFor('campaigns', "createfacebookaudience", [], false, body, cacheId);
            console.log(res)
            if (res !== null) {
                this.props.changeScreen({ screen: 'list', creationCancel: false });
            }
        }
    }


    //Y. PRE-RENDER FUNCTIOSNS
    getStepExplanation = () => {
        var l = null;
        switch (this.state.step) {
            case 1: l = this.labels.lblStep1; break;
            case 2: if (this.state.campaignType === "facebook") { l = this.labels.lblStep2Facebook } else { l = this.labels.lblStep2 }; break;
            case 3: if (this.state.campaignType === "facebook") { l = this.labels.lblStep3Facebook } else { l = this.labels.lblStep3 }; break;
            default: ; break;
        }

        return l;
    }

    getStepScreen = () => {
        var screen = <></>;

        switch (this.state.step) {
            case 1: screen = <Container fluid className="campaignTypeSelectionForm">
                <Row>
                    {this.props.campaignConfig.email.enabled ?
                        <SelectCard lg="3" md="6" s="12"
                            icon={<EmailIcon className="selectCardIcon" />}
                            title={this.labels.lblEmail}
                            text={this.labels.lblEmailExplan}
                            function={this.setCampaignType}
                            functionValues="email"
                            isActive={this.state.campaignType === "email"}
                        />
                        : ""}

                    {this.props.campaignConfig.sms.enabled ?
                        <SelectCard lg="3" md="6" s="12"
                            icon={<SmsIcon className="selectCardIcon" />}
                            title={this.labels.lblSms} text={this.labels.lblSmsExplan}
                            function={this.setCampaignType}
                            functionValues="sms"
                            isActive={this.state.campaignType === "sms"}
                        />
                        : ""}

                    {this.props.campaignConfig.facebook.enabled ?
                        <SelectCard lg="3" md="6" s="12"
                            icon={<FacebookIcon className="selectCardIcon" />}
                            title={this.labels.lblFacebook}
                            text={this.labels.lblFacebookExplan}
                            function={this.setCampaignType}
                            functionValues="facebook"
                            isActive={this.state.campaignType === "facebook"}
                        />
                        : ""}

                </Row>

                <Row className="buttonsRow">
                    {/* <Col> */}
                    <Button onClick={() => { this.setStep(2) }}>{this.labels.lblNext}</Button>
                    {/* </Col> */}
                </Row>

            </Container>; break;
            case 2:
                switch (this.state.campaignType) {
                    case "email":
                        screen =
                            <EmailCampaignForm
                                askFor={this.props.askFor}
                                formDataPetition={this.props.formDataPetition}
                                bucketid={this.props.bucket.bucketId}
                                campaignConfig={this.props.campaignConfig.email}
                                currentEmailCuota={this.props.currentEmailCuota}
                                cuponeraEnabled={this.props.campaignConfig.cuponera.enabled}
                                cuponeraLinkToAction={this.props.campaignConfig.cuponera.link_to_action}
                                lang={this.props.lang}
                                setStep={this.setStep}
                                setCampaignName={this.setCampaignName}
                                setCampaignSubject={this.setCampaignSubject}
                                setCampaignHtml={this.setCampaignHtml}
                                loadingSpinner={this.props.loadingSpinner}
                                setLoadingStep={this.setLoadingStep}
                                changeScreen={this.props.changeScreen}
                            />
                            ; break
                    case "sms":
                        screen =
                            <SmsCampaignForm
                                bucketid={this.props.bucket.bucketId}
                                campaignConfiguration={this.props.campaignConfig.sms}
                                askFor={this.props.askFor}
                                lang={this.props.lang}
                                loadingSpinner={this.props.loadingSpinner}
                                changeScreen={this.props.changeScreen}
                                setLoadingStep={this.setLoadingStep}
                            />
                            ; break
                    case "facebook":
                        screen =
                            <FacebookCampaignForm
                                askFor={this.props.askFor}
                                bucketid={this.props.bucket.bucketId}
                                campaignConfiguration={this.props.campaignConfig.facebook}
                                lang={this.props.lang}
                                loadingSpinner={this.props.loadingSpinner}
                                changeScreen={this.props.changeScreen}
                                setLoadingStep={this.setLoadingStep}
                            />
                            ; break
                }
                ; break;
            default: ; break
        }

        return screen;
    }

    getStepButtons = () => {
        var buttons = <></>;

        switch (this.state.step) {
            case 1: buttons = <>
                <Button onClick={() => { this.setStep(2) }}>{this.labels.lblNext}</Button>
            </>; break;
            case 2:
                switch (this.state.campaignType) {
                    case "email": buttons = <>
                        <Button onClick={() => { this.setStep(3) }}>{this.labels.lblNext}</Button>
                    </>; break;
                    case "sms": buttons = <>
                        <Button onClick={() => { this.setStep(3) }}>{this.labels.lblNext}</Button>
                    </>; break;
                    case "facebook": buttons = <>
                        {this.state.isFacebookLogIn && this.state.audience.name !== null && this.state.audience.description !== null ? <Button onClick={() => { this.setStep(3) }}>{this.labels.lblNext}</Button> : ""}
                    </>; break;
                }
                ; break;
            case 3: buttons = <>
                <Button onClick={() => { this.setStep(4) }}>{this.labels.lblNext}</Button>
            </>; break;
            case 4:
                switch (this.state.campaignType) {
                    case "email":
                        if (this.state.campaign.cupon != null) {
                            if (this.state.cuponCount > this.state.selectionCount) {
                                buttons = <>
                                    <Button onClick={() => { this.summitCampaign() }}>{this.labels.lblCreateCampaign}</Button>
                                </>;
                            }
                            else {
                                buttons = <>
                                    <label>La población seleccionada supera la cantidad de cupones disponibles</label>
                                </>;
                            }
                        }
                        else {
                            buttons = <>
                                <Button onClick={() => { this.summitCampaign() }}>{this.labels.lblCreateCampaign}</Button>
                            </>;
                        }


                        break;
                    case "sms": buttons = <>
                        <Button onClick={() => { this.summitAndSaveCampaign() }}>{this.labels.lblCreateAndSaveCampaign}</Button>
                        <Button onClick={() => { this.summitCampaign() }}>{this.labels.lblCreateAndSendCampaign}</Button>
                        <Button onClick={() => { this.toggleDatePicker(true) }}>{this.labels.lblCreateAndScheduleCampaign}</Button>
                    </>; break;
                    case "facebook": buttons = <>
                        <Button onClick={() => { this.createFacebookAudience() }}>{this.labels.lblCreateAudience}</Button>
                    </>; break;
                }
                break;
            default: ; break;
        }

        return buttons;
    }

    getTitleLabel = () => {
        let label = "";
        switch (this.state.campaignType) {
            case "email": label = this.labels.lblTitle; break;
            case "sms": label = this.labels.lblTitle; break;
            case "facebook": label = this.labels.lblTitleFacebook; break;
            default: label = this.labels.lblTitle; break;
        }

        return label;
    }


    //Z. Render

    render() {
        return (
            <>
                <Container fluid className={classnames({ "campaignCreator": true, "blur": this.state.showDatePicker })}>
                    {this.state.showLoadingSpinner === false ?
                        <>
                            <Row className="titleRow">
                                <h1>{this.getTitleLabel()}</h1>
                                <div>
                                    <Button onClick={() => { this.resetState() }}> Reiniciar </Button>
                                    <Button onClick={() => { this.props.changeScreen({ screen: 'list', creationCancel: true }) }}> Cancelar </Button>
                                </div>
                            </Row>
                            <Row className="stepRow">
                                {this.state.step > 1 ?
                                    ""
                                    :
                                    <p>{this.labels.lblStep0}</p>
                                }
                            </Row>
                            <Row>
                                {this.getStepScreen()}
                            </Row>
                        </>
                        :
                        <div className="campaignCreationLoading">
                            {this.props.loadingSpinner}
                            <p> Procesando Campaña</p>
                        </div>
                    }
                </Container>

                {/* {this.state.showDatePicker ?
                    <CampaignDatePicker
                        selectedDate={this.state.selectedDate}
                        handleChange={this.setSelectedDate}
                        sendCampaign={this.summitAndScheduleCampaign}
                        toggleDatePicker={this.toggleDatePicker}
                    />
                    :
                    ""
                } */}
            </>
        )
    }

}

export default CampaignCreator;
