import React from "react";
import { ClientDetailLang } from '../../lang'
import NumberFormat from "react-number-format";
import { Line, Doughnut, } from "react-chartjs-2";
import {
    Container,
    Card,
    CardBody,
    Row,
    Col,
    Table
} from "reactstrap";


var ClientSummary = props => {
    var cliente = props.cliente;
    var labels = props.labels;
    var rowStyle = { display: 'block' }



    return (<Col className="summaryInfo">
        <Card className="">
            <CardBody>
                <div className="iconAndName text-center">
                    <i className="icon ti-user"></i>
                    <h3 className="text-center">{cliente.NOMBRE} {cliente.PRIMERAPELLIDO}</h3>
                </div>
                <hr />
                <Row>
                    <Col lg="6">
                        <p className="text-center noMargin">No. Loyalty</p>
                        <p className="text-center stdOutData">{cliente.IDENTIFICACION}</p>
                    </Col>
                    <Col lg="6">
                        <p className="text-center noMargin">{labels.lblAge}</p>
                        <p className="text-center stdOutData">{cliente.EDAD}</p>
                    </Col>
                </Row>
            </CardBody>
        </Card>

        <Card>
            <CardBody>
                <Row className="text-center">
                    <Col style={rowStyle}>
                        <h2 className="stdOutData"><NumberFormat value={cliente.PUNTOSDISPONIBLES} displayType="text" thousandSeparator={true} /></h2>
                        <p>{labels.lblAvailablePoints}</p></Col>
                    <Col style={rowStyle}>
                        <h2 className="stdOutData"><NumberFormat value={cliente.PUNTOSCAMBIADOS} displayType="text" thousandSeparator={true} /></h2>
                        <p>{labels.lblRedeemedPoints}</p>
                    </Col>
                </Row>
                <hr />
                <Row>
                    <h3 className="stdOutData" style={{ width: "100%" }}><NumberFormat value={cliente.TIQUETEPROMEDIO} displayType="text" thousandSeparator={true} prefix={props.currencySymbol} /></h3>
                    <p className="text-center" style={{ width: "100%" }}>{labels.lblAvgTicket}</p>
                </Row>
            </CardBody>
        </Card>
    </Col>)
}

var Chart = props => {
    //Props:
    //data, title, subtitle, dataId, YAxisId
    var radio = props.lg ? false : true;
    var chartOptions = {
        responsive: true,
        maintainAspectRatio: true,
        legend: {
            display: true,
            labels: {
                fontColor: '#9a9a9a'
            }
        },
        scales: {
            yAxes: [
                {
                    ticks: {
                        fontColor: "#9a9a9a",
                        maxTicksLimit: 10,
                        userCallback: function (value, index, values) {
                            var strVal = value.toString();

                            if (value >= 1000 && value < 10000) {
                                //strVal = value.toString();
                                return strVal.substring(0, 1) + "," + strVal.substring(1);
                            }

                            if (value >= 10000 && value < 100000) {
                                //strVal = value.toString();
                                return strVal.substring(0, 2) + "," + strVal.substring(2);
                            }

                            if (value >= 10000 && value < 1000000) {
                                //strVal = value.toString();
                                return strVal.substring(0, 3) + "," + strVal.substring(3);
                            }

                            if (value >= 1000000) {
                                value = (value / 1000000);
                                strVal = value.toString();
                                return strVal + "m";
                            }

                            return strVal;
                        }
                    }
                }
            ],
            xAxes: [
                {
                    ticks: {
                        fontColor: "#9a9a9a"
                    }
                }
            ]
        }
    }
    var readyChartData = (data, label, dataID, yAxisId) => {
        if (data === null || data === undefined) {
            return null;
        }


        return (canvas => {
            let ctx = canvas.getContext("2d");

            var gradientStroke = null;
            var pointColor = "#43b02a";

            switch (props.theme) {
                case "default.css":
                    gradientStroke = ctx.createLinearGradient(67, 176, 42, 100);
                    gradientStroke.addColorStop(1, "rgba(67, 176, 42,0.6)");
                    gradientStroke.addColorStop(0.4, "rgba(67, 176, 42,0.4)");
                    gradientStroke.addColorStop(0, "rgba(67, 176, 42,0.2)");
                    break;
                case "lightTheme.css":
                    gradientStroke = ctx.createLinearGradient(155, 118, 54, 100);
                    gradientStroke.addColorStop(1, "rgba(155, 118, 54,0.6)");
                    gradientStroke.addColorStop(0.4, "rgba(155, 118, 54,0.4)");
                    gradientStroke.addColorStop(0, "rgba(155, 118, 54,0.2)");
                    pointColor = "#af9044";
                    break;
            }

            let labels = [];
            let chartData = []


            data.forEach(element => {
                if (element === null || element === undefined) {
                    return null;
                }
                else {
                    labels.push(element[yAxisId]);
                    chartData.push(element[dataID])
                }
            });

            return {
                labels: labels,
                datasets: [
                    {
                        label: label,
                        fill: true,
                        backgroundColor: gradientStroke,
                        borderColor: pointColor,
                        borderWidth: 2,
                        borderDash: [],
                        borderDashOffset: 0.0,
                        pointBackgroundColor: pointColor,
                        pointBorderColor: "rgba(0,0,0,0)",
                        pointHoverBackgroundColor: "#fff",
                        pointBorderWidth: 20,
                        pointHoverRadius: 4,
                        pointHoverBorderWidth: 15,
                        pointRadius: 4,
                        data: chartData
                    }
                ]
            };
        })
    }

    return (<Col className="clientChart" lg={props.lg || "6"} md="6" sm="12" xs="12">
        <Card>
            <CardBody>
                <h6>{props.title}</h6>
                <Line data={readyChartData(props.data, props.title, props.dataId, props.YAxisId)} options={chartOptions} />
            </CardBody>
        </Card>
    </Col>)
}

var FavTable = props => {
    var prodList = [];
    if (props.data !== undefined || props.data !== null) {
        prodList = props.data;
        if (Array.isArray(props.data) && props.data.length > 0) {
            if (props.sortValue != undefined) { //si es un arreglo lo ordena
                prodList.sort((a, b) => {
                    return b[props.sortValue] - a[props.sortValue]
                })
            }
            return (<Col className="clientFavProd">
                <Card>
                    <CardBody>
                        <h4 className="clientFaveProdTitle">{props.title}</h4>
                        <Table>
                            <tbody>
                                {
                                    prodList.map((prod, key) => {
                                        return (
                                            <tr key={key}>
                                                <td>
                                                    <h3>{key + 1}</h3>
                                                </td>

                                                <td>
                                                    {prod[props.name]}
                                                    <br />
                                                    {props.scndValueToDisplay != undefined ?
                                                        <small>{props.scndValueLabel}: {prod[props.scndValueToDisplay]}</small>
                                                        : ""}
                                                    <br />
                                                    {props.hideSku == undefined  ? "" : props.hideSku ? "" : <b>Sku : {prod.SKU} </b>}
                                                    <br />
                                                    <b style={{ marginRight: '5px' }}>{props.valLabel}: </b>
                                                    <b>
                                                        <span className="stdOutData count-text">
                                                            {props.valueToDisplay == 'VECES' ?
                                                                prod[props.valueToDisplay]
                                                                :
                                                                <NumberFormat value={prod[props.valueToDisplay]}
                                                                    displayType={'text'} thousandSeparator={true}
                                                                    fixedDecimalScale={true}
                                                                    decimalScale={2}
                                                                    prefix={props.currencySymbol}
                                                                />
                                                            }
                                                        </span>
                                                    </b>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </Table>
                    </CardBody>
                </Card>
            </Col>)
        } else { // si no es un arreglo, revisa que exista al menos 1 dato.
            if (props.data.PRODUCTO !== undefined) {
                return (<Col>
                    <Card>
                        <CardBody>
                            <h4>{props.title}</h4>
                            <Row className="singleProductRow">
                                <Col lg="2"><h3>1</h3></Col>
                                <Col lg="10">
                                    <p>{props.data[props.name]}</p>
                                    <br />
                                    {props.scndValueToDisplay !== undefined ?
                                        <small>{props.scndValueLabel}: {props.data[props.scndValueToDisplay]}</small>
                                        : ""}
                                    <br />
                                    {props.hideSku == undefined  ? "" : props.hideSku ? "" : <b>Sku : {props.data.SKU} </b>}
                                    <br />
                                    <b style={{ marginRight: '5px' }}>{props.valLabel}: </b>
                                    <b>
                                        <span className="stdOutData count-text">
                                            {props.valueToDisplay == 'VECES' ?
                                                props.data[props.valueToDisplay]
                                                :
                                                <NumberFormat value={props.data[props.valueToDisplay]}
                                                    displayType={'text'} thousandSeparator={true}
                                                    fixedDecimalScale={true}
                                                    decimalScale={2}
                                                    prefix={props.currencySymbol}
                                                />
                                            }
                                        </span>
                                    </b>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>)
            } else {
                return (<Col className='text-center'>No Data</Col>)
            }
        }

    } else {
        return (<Col className='text-center'>No Data</Col>)
    }
}


class ClientDetail extends React.Component {
    constructor(props) {
        super(props)
        this.state = { data: null }
        this.body = { "searchquery": "" }
        this.labels = ClientDetailLang(this.props.lang);
    }

    async componentDidMount() {
        try {

            let cacheId = "ClientDetail" + this.props.clientId;
            this.body.searchquery = "" + this.props.clientId;
            let data = await this.props.askFor('crm', 'crm_bucket/searchclientbyid', [], false, this.body, cacheId);
            if (data !== null) {
                this.setState({ data: data.clients[0] });
            }
            var l = await this.props.getBucketLabels("ClientPersonalInfo", ClientDetailLang(this.props.lang));
            if (l != null) {
                this.labels = l;
                this.forceUpdate();
            }
        } catch (err) {

        }
    }

    ordenarHoraCompra(data) {
        var array = [];
        for (var i = 0; i <= 4; i++) {
            switch (i) {
                case 0: data.forEach((hour) => { if (hour.HORA == "DESAYUNO") { var x = Object.assign({}, hour);; x.HORA = "Mañana"; array.push(x); } }); break;
                case 1: data.forEach((hour) => { if (hour.HORA == "ALMUERZO") { var x = Object.assign({}, hour); x.HORA = "Medio Día"; array.push(x); } }); break;
                case 2: data.forEach((hour) => { if (hour.HORA == "CAFE") { var x = Object.assign({}, hour); x.HORA = "Tarde"; array.push(x); } }); break;
                case 3: data.forEach((hour) => { if (hour.HORA == "CENA") { var x = Object.assign({}, hour); x.HORA = "Noche"; array.push(x); } }); break;
                case 4: data.forEach((hour) => { if (hour.HORA == "OTROS") { var x = Object.assign({}, hour); array.push(x); } }); break;
                default: ; break;
            }

        }
        console.log(array);
        return array;
    }

    render() {
        return (<>
            {this.state.data !== null ?
                <Container fluid className="clientDetail">
                    <Row style={{ marginBottom: '20px' }}>
                        <Col lg="12" md="12" sm="12" className="colGetBackToSegments">
                            {/* Get Back Button */}
                            <button
                                className="btnGetBack"
                                onClick={() => {
                                    this.props.backFunction(this.props.backFunctionValues)
                                }}
                            >
                                <span>&#10229;</span> {this.labels.btnBack}
                            </button>
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: '20px' }}>
                        <Col lg="4" className="noPadding">
                            <ClientSummary
                                cliente={this.state.data}
                                currencySymbol={this.props.currencySymbol}
                                labels={this.labels}
                            />
                        </Col>

                        <Col lg="8" className="charts noPadding">
                            <Row>
                                <Chart
                                    data={this.state.data.TRANS}
                                    dataId="M" YAxisId="MES"
                                    title={this.labels.salesVSgoals}
                                    subtitle={this.labels.salesVSgoalsSubtitle}
                                    theme={this.props.theme}
                                />
                                <Chart
                                    data={this.ordenarHoraCompra(this.state.data.HORACOMPRA)}
                                    dataId="F"
                                    YAxisId="HORA"
                                    title={this.labels.hourPurchases}
                                    subtitle={this.labels.hourPurchasesSubtitle}
                                    theme={this.props.theme}
                                />
                            </Row>

                            <Row>

                                {this.props.hideRedeem ?
                                    <Chart data={this.state.data.TRANS} dataId="P" YAxisId="MES" title={this.labels.lblPointsPerMonths} subtitle="" />
                                    :
                                    <>
                                        <Chart data={this.state.data.TRANS} dataId="P" YAxisId="MES" title={this.labels.lblPointsPerMonths} subtitle="" />
                                        <Chart data={this.state.data.REDEMPTIONS} dataId="M" YAxisId="MES" title={this.labels.lblRedemsPerMonths} subtitle="" />
                                    </>
                                }
                            </Row>
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: '20px' }}>
                        <FavTable title={this.labels.favProducts}
                            name="PRODUCTO" data={this.state.data.PRODUCTOSMASCONSUMIDOS}
                            valLabel={this.labels.lblCount}
                            valueToDisplay="VECES"
                            currencySymbol={this.props.currencySymbol}
                            sortValue="VECES"
                            labels={this.labels}
                            hideSku={this.props.isFieldHidden('sku')}
                        />

                        <FavTable title={this.labels.favStores}
                            name="name" data={this.state.data.STORES}
                            valLabel={this.labels.lblAmount} valueToDisplay="MONTO"
                            scndValueToDisplay="VECES"
                            scndValueLabel={this.labels.lblTimes}
                            currencySymbol={this.props.currencySymbol}
                            sortValue="VECES"
                            labels={this.labels} />
                    </Row>
                </Container>
                :
                <div className="text-center" style={{ paddingTop: '10px' }}>
                    <h2>{this.labels.lblLoading}</h2>
                </div>
            }
        </>)
    }
}

export default ClientDetail;
