import React from "react";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
import { CountsByGenderLang } from '../../lang'
import NumberFormat from 'react-number-format'
import classNames from "classnames";

// reactstrap components
import {
    Container,
    Card,
    Row,
    Col,
    CardBody
} from "reactstrap";


var SingleGender = props => {
    /* Props: 
            Labels: {{},{},{}},
            countData: string
            countPerc: string,
            avgAgeData : int , 
            avgTicket : int
            chartData: {}*/
    return (<>
        <Col className={"genderCount " + props.gender} xs="12" sm='6' md="6" lg="12">
            <Card>
                <CardBody>
                    {props.loading ?
                        props.loadingSpinner
                        :
                        <>
                            <i className={classNames({ fa: true, 'fa-male': props.gender == "male", "fa-female": props.gender == 'female' })} />
                            <p className="noMargin">{props.countData} {props.labels.Title}</p>
                            <h4>{props.countPerc}%</h4>
                            <div className="ct-chart-m">
                                <Line data={props.chartData} options={props.chartOptions} />
                            </div>
                            <Container className="footer">
                                <Row>
                                    <Col lg="5">
                                        <p>
                                            {props.labels.Age + ": "}
                                            <b className={classNames({ maleIcon: props.gender === 'male', femaleIcon: props.gender === 'female' })}>{props.avgAgeData}</b>
                                        </p>
                                    </Col>
                                    <Col lg="7">
                                        <p>
                                            {props.labels.Ticket + ": "}
                                            <b className={classNames({ maleIcon: props.gender === 'male', femaleIcon: props.gender === 'female' })}><NumberFormat value={props.avgTicket} displayType={'text'} thousandSeparator={true} prefix={props.currencySymbol} /></b>
                                            {props.labels.Period}
                                        </p>
                                    </Col>
                                </Row>
                            </Container>
                        </>
                    }

                </CardBody>
            </Card>
        </Col>
    </>)
}


class CountsByGender extends React.Component {
    constructor(props) {
        super(props)
        this._isMounted = false;
        this.state = { genderSeparation: props.genderSeparation, data: null, labels: CountsByGenderLang(props.lang) }
        this.readyChartData = this.readyChartData.bind(this);

        this.chartOptions = {
            // responsive: true,
            legend: {
                display: true,
                labels: {
                    fontColor: '#9a9a9a'
                }
            },
            scales: {
                yAxes: [
                    {
                        gridLines: {
                            drawBorder: false,
                            color: "rgba(29,140,248,0.0)",
                            zeroLineColor: "transparent"
                        },
                        ticks: {
                            maxTicksLimit:10,
                            fontColor: "#9a9a9a",
                            userCallback: function (value, index, values) {
                                var strVal = value.toString();

                                if (value >= 1000 && value < 10000) {
                                    strVal = value.toString();
                                    return strVal.substring(0, 1) + "," + strVal.substring(1);
                                }

                                if (value >= 10000 && value < 100000) {
                                    strVal = value.toString();
                                    return strVal.substring(0, 2) + "," + strVal.substring(2);
                                }

                                if (value >= 1000000) {
                                    value = (value / 1000000);
                                    strVal = value.toString();
                                    return strVal + "m";
                                }

                                return strVal;
                            }

                        }
                    }
                ],
                xAxes: [
                    {
                        gridLines: {
                            drawBorder: false,
                            color: "rgba(29,140,248,0.1)",
                            zeroLineColor: "transparent"
                        },
                        ticks: {
                            fontColor: "#9a9a9a",
                            fontSize: 9.5
                        }
                    }
                ]
            },
            tooltips: {
                callbacks: {
                    label: function (tooltipItem, data) {
                        let v = tooltipItem.yLabel;
                        return v.toLocaleString();
                    }
                }
            }
        }

        this.chartOptionsWithNoSeparation = {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                yAxes: [
                    {
                        barPercentage: 0.5,
                        gridLines: {
                            //drawBorder: false,
                            color: "rgba(29,140,248,0.0)",
                            //zeroLineColor: "transparent"
                        },
                        ticks: {
                            padding: 0,
                            fontColor: "#9a9a9a",
                            beginAtZero: true,
                            userCallback: function (value, index, values) {
                                var strVal = value.toString();

                                if (value >= 1000 && value < 10000) {
                                    strVal = value.toString();
                                    return strVal.substring(0, 1) + "," + strVal.substring(1);
                                }

                                if (value >= 10000 && value < 100000) {
                                    strVal = value.toString();
                                    return strVal.substring(0, 2) + "," + strVal.substring(2);
                                }

                                if (value >= 1000000) {
                                    value = (value / 1000000);
                                    strVal = value.toString();
                                    return strVal + "m";
                                }

                                return strVal;
                            }
                        }
                    }
                ],
                xAxes: [
                    {
                        barPercentage: 0.5,
                        gridLines: {
                            //drawBorder: false,
                            color: "rgba(29,140,248,0.1)",
                            //zeroLineColor: "transparent"
                        },
                        ticks: {
                            padding: 0,
                            fontColor: "#9a9a9a",
                            beginAtZero: true,
                        }
                    }
                ]
            },
            elements: {
                point: {
                    radius: 6,
                    hoverRadius: 6,
                }
            },
            tooltips: {
                callbacks: {
                    label: (item, data) => {
                        return item['yLabel'] + " clientes";
                    }
                }
            },
        }

    }

    async componentDidMount() {
        this._isMounted = true;
        try {
            if (this._isMounted) {
                let data = await this.props.askFor('globalsum', 'global/globalcountbygendersummary');
                if(data != null){
                    this.setState(() => ({ data: data }))
                }
            }
        } catch (err) {
            console.log(err);
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.lang != this.props.lang) {
            if (this._isMounted) {
                this.setState({ labels: CountsByGenderLang(this.props.lang) }, () => { this.forceUpdate() })
            }
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    readyChartData = (data, label, gender) => {
        if (data === null || data === undefined) {
            return null;
        }

        return (canvas => {
            let ctx = canvas.getContext("2d");
            var gradientStroke = null;
            var pointColor = "#43b02a";
      
            switch (this.props.theme) {
              case "default.css":
                  if(gender == "male"){
                    gradientStroke = ctx.createLinearGradient(20, 112, 199, 100);
                    gradientStroke.addColorStop(1, "rgba(20, 112, 199,0.6)");
                    gradientStroke.addColorStop(0.4, "rgba(20, 112, 199,0.4)");
                    gradientStroke.addColorStop(0, "rgba(20, 112, 199,0.2)");
                    pointColor = "#1470c7";
                  }
                  else{
                    gradientStroke = ctx.createLinearGradient(249, 68, 58, 100);
                    gradientStroke.addColorStop(1, "rgba(249, 68, 58,0.6)");
                    gradientStroke.addColorStop(0.4, "rgba(249, 68, 58,0.4)");
                    gradientStroke.addColorStop(0, "rgba(249, 68, 58,0.2)");
                    pointColor = "#f9443a";
                  }
                
                break;
              case "lightTheme.css":
                gradientStroke = ctx.createLinearGradient(155, 118, 54, 100);
                gradientStroke.addColorStop(1, "rgba(155, 118, 54,0.6)");
                gradientStroke.addColorStop(0.4, "rgba(155, 118, 54,0.4)");
                gradientStroke.addColorStop(0, "rgba(155, 118, 54,0.2)");
                pointColor = "#af9044";
                break;
            }



            let labels = [];
            let chartData = []


            data.forEach(element => {
                if (element === null || element === undefined) {
                    return null;
                }
                else {
                    labels.push(element['AGEBRACKET']);
                    chartData.push(element['count'])
                }
            });

            return {
                labels: labels,
                datasets: [
                    {
                        label: label,
                        fill: true,
                        backgroundColor: gradientStroke,
                        borderColor: pointColor,
                        borderWidth: 2,
                        borderDash: [],
                        borderDashOffset: 0.0,
                        pointBackgroundColor: pointColor,
                        pointBorderColor: "rgba(250,105,0,0)",
                        pointHoverBackgroundColor: "white",
                        pointBorderWidth: 20,
                        pointHoverRadius: 4,
                        pointHoverBorderWidth: 15,
                        pointRadius: 4,
                        data: chartData
                    }
                ]
            };
        })
    }



    render() {
        if (this.state.data != null) {
            var chartLabel = this.state.labels.lblFChartTitle + this.state.labels.lblFChartSubTitle;
            var charDataM = this.readyChartData(this.state.data.byAgeBracket.male, chartLabel, "male");
            var charDataF = this.readyChartData(this.state.data.byAgeBracket.female, chartLabel, "female");
            var cardLabelsM = { Age: this.state.labels.lblAvgAge, Ticket: this.state.labels.lblAvgTicket, Title: this.state.labels.lblMenTitle, Period: this.state.labels.lblPeriod };
            var cardLabelsF = { Age: this.state.labels.lblAvgAge, Ticket: this.state.labels.lblAvgTicket, Title: this.state.labels.lblWomenTitle, Period: this.state.labels.lblPeriod };
        }

        return (<>
            <Container fluid className="noPadding">
                {this.state.genderSeparation ? <>
                    {/* <!-- Gender Separation Available --> */}
                    <div>
                        <Row className="noPadding">
                            {this.state.data != null ?
                                <>
                                    <SingleGender gender="male"
                                        labels={cardLabelsM}
                                        countData={this.state.data.malecount}
                                        countPerc={this.state.data.maleperc}
                                        avgAgeData={this.state.data.totalsByAgeBracket.male.avgAge}
                                        avgTicket={this.state.data.totalsByAgeBracket.male.avgTicket}
                                        chartData={charDataM}
                                        chartOptions={this.chartOptions}
                                        currencySymbol={this.props.currencySymbol} />

                                    <SingleGender gender="female"
                                        labels={cardLabelsF}
                                        countData={this.state.data.femalecount}
                                        countPerc={this.state.data.femaleperc}
                                        avgAgeData={this.state.data.totalsByAgeBracket.female.avgAge}
                                        avgTicket={this.state.data.totalsByAgeBracket.female.avgTicket}
                                        chartData={charDataF}
                                        chartOptions={this.chartOptions}
                                        currencySymbol={this.props.currencySymbol} />
                                </>
                                :
                                <>
                                    <SingleGender loading loadingSpinner={this.props.loadingSpinner} />
                                    <SingleGender loading loadingSpinner={this.props.loadingSpinner} />
                                </>
                            }
                        </Row>
                    </div>
                </>
                    :
                    this.state.data == null ? '' :
                        /* <!-- Gender separation NOT available --> */
                        <>
                            <h6>{this.state.labels.lblUnifiedTopTitle}</h6>
                            <p>{this.state.labels.lblUnifiedTopSubTitle}</p>
                            <Card>
                                <div className="">
                                    <div className="">
                                        <Card>
                                            <Bar data={this.readyChartData(this.state.data.byAgeBracket.other, 'Resumen Demográfico')} options={this.chartOptionsWithNoSeparation} height={250} />
                                        </Card>
                                        <CardBody className="flex-distribute">
                                            <p>
                                                <i className="stdOutData fa fa-male"></i> {this.state.labels.lblAvgAge + ': '}
                                                <b className="stdOutData">{this.state.data.totalsByAgeBracket.other.avgAge}</b>
                                            </p>
                                            <p>
                                                <i className=" stdOutData ti-money"></i> {this.state.labels.lblAvgTicket + ': '}
                                                <b className=" stdOutData"><NumberFormat value={this.state.data.totalsByAgeBracket.other.avgTicket} displayType={'text'} thousandSeparator={true} prefix={this.props.currencySymbol} /></b>
                                                {/* {" " + this.state.labels.lblPeriod} */}
                                            </p>
                                        </CardBody>
                                    </div>
                                </div>
                            </Card>
                        </>
                }
            </Container>
        </>)
    }
}




export default CountsByGender;