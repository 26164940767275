import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Button } from 'reactstrap';
import classnames from 'classnames'
import ReactTooltip from "react-tooltip";
import { CSSTransition } from "react-transition-group"


var Selector = props => {


    useEffect(() => {
        ReactTooltip.rebuild()
        return () => { }
    })

    return (<div className={"selector " + props.className}>
        <ReactTooltip id={props.id} place="top" />
        {props.options.map((o, key) => {
            if (o.name !== undefined) {
                return (<button key={key} data-tip={o.name} data-for={props.id} className={classnames({ active: o.value === props.selected })} onClick={() => { props.select(o.value) }}>{o.value}</button>)
            } else {
                return (<button key={key} className={classnames({ active: o === props.selected })} onClick={() => { props.select(o) }}>{o}</button>)
            }
        })}
    </div>)
}

var AdminGeneral = props => {

    // 1. VAR INITIALIZATION ---------------------------------------
    const bucket = props.bucket;
    const baseConfiguration = {
        name: bucket.name,
        currencySymbol: bucket.currencySymbol,
        companyLogoCDNUrl: bucket.companyLogoCDNUrl,
        region: bucket.region,
        genderSeparation: bucket.genderSeparation,
        additionalConfig: bucket.additionalConfig,
        hiddenFields: bucket.hiddenFields
    }
    const [configuration, setConfiguration] = useState(JSON.parse(JSON.stringify(baseConfiguration)));
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [isLoading, setLoading] = useState(false);

    // 2. BUCKET CHANGE VERIFICATION FUNCTION -----------------------

    var hasConfigurationChange = () => {
        let bool = false;
        if (JSON.stringify(baseConfiguration) !== JSON.stringify(configuration)) {
            bool = true;
        }

        return bool;
    }

    // 3. BUCKET OBJECT FUNCTIONS ----------------------------------

    var setBucketName = name => {
        setConfiguration(c => {
            c['name'] = name;
            return ({ ...c })
        })
    }

    var setBucketCurrencySymbol = symbol => {
        setConfiguration(c => {
            c['currencySymbol'] = symbol;
            return ({ ...c })
        })
    }

    var setBucketCompanyLogo = logo => {
        setConfiguration(c => {
            c['companyLogoCDNUrl'] = logo;
            return ({ ...c })
        })
    }

    var setBucketRegion = region => {
        setConfiguration(c => {
            c['region'] = region;
            return ({ ...c })
        })
    }

    var setGenderSeparation = () => {
        setConfiguration(c => {
            c['genderSeparation'] = !c.genderSeparation;
            return ({ ...c })
        })
    }

    var setUseDefaultPeriods = () => {
        setConfiguration(c => {
            c.additionalConfig.UseDefaultPeriods = !c.additionalConfig.UseDefaultPeriods;
            return ({ ...c })
        })
    }


    //HIDDEN FILDS FUNCTIONS ----------------------------------------
    var isFieldHidden = (field) => {
        var ex = false;
        configuration.hiddenFields.forEach(hf=>{
            if(hf == field){
                ex = true;
            }
        })
        return ex;
    }

    var setShowProductSku = () => {
        setConfiguration(c => {
            var hf = c.hiddenFields;
            var ex = false;
            var indice = -1;
            hf.forEach((f,i) => {
                if(f === "sku"){
                    ex = true;
                    indice = i;
                }
            })
            if(ex){
                hf.splice(indice, 1);
            }else{
                hf.push("sku")
            }
            return ({ ...c })
        })
    }

    

    // 4. API CALLS

    var saveConfiguration = async () => {
        setLoading(true)
        let cacheId = "SaveBucketConfiguration" + Math.round(Math.random() * 100000);
        let saveResponse = await props.askFor('config', 'configuration/updatebucket', [], false, { configuration: configuration }, cacheId);
        if (saveResponse !== null) {
            setShowSuccessMessage(true);
            setInterval(() => {
                setShowSuccessMessage(false);
            }, 3000);
            props.changeBucketGeneralConfigurations(JSON.parse(JSON.stringify(configuration)));
            setLoading(false);
        } else {

        }
    }


    // Z. RENDER ---------------------------------------------------
    return (
        <div className="adminScreenContent">
            {isLoading ?
                props.loadingSpinner
                :
                <Container fluid className="general">
                    <Row className="titleRow">
                        <h3>General</h3>
                        <CSSTransition mountOnEnter unmountOnExit classNames="ani" timeout={350} in={showSuccessMessage} appear={showSuccessMessage}>
                            <p><b>¡Configuración guardada corrrectamente!</b></p>
                        </CSSTransition>
                        <CSSTransition mountOnEnter unmountOnExit classNames="ani" timeout={350} in={hasConfigurationChange()} appear={hasConfigurationChange()}>
                            <Button onClick={() => { saveConfiguration() }}>Guardar</Button>
                        </CSSTransition>
                    </Row>
                    <Row className="contentRow">
                        <div className="formGroup">
                            <label>Nombre</label>
                            <input type="text" value={configuration.name} onChange={e => { setBucketName(e.target.value) }} />
                        </div>

                        <div className="formGroup">
                            <label>Región</label>
                            <input type="text" value={configuration.region} onChange={e => { setBucketRegion(e.target.value) }} />
                        </div>

                        <hr />
                        <div className="formGroup">
                            <label>Moneda</label>
                            <input type="text" value={configuration.currencySymbol} onChange={e => { setBucketCurrencySymbol(e.target.value) }} />
                            {/* <Selector id="currencySymbol" options={[{ name: "Dollar", value: "$" }, { name: "Colón", value: "₡" }]} selected={configuration.currencySymbol} select={setBucketCurrencySymbol} /> */}
                        </div>
                        <hr />

                        <div className="formGroup">
                            <label className="w30">Utilizar separación de género</label>
                            <input type="checkbox" checked={configuration.genderSeparation} onChange={() => { setGenderSeparation() }} />
                        </div>

                        <div className="formGroup">
                            <label className="w30">Utilizar periódos dinámicos</label>
                            <input type="checkbox" checked={configuration.additionalConfig.UseDefaultPeriods} onChange={() => { setUseDefaultPeriods() }} />
                        </div>

                        <div className="formGroup">
                            <label className="w30">Ocultar Sku</label>
                            <input type="checkbox" checked={isFieldHidden("sku")} onChange={() => { setShowProductSku() }} />
                        </div>
                    </Row>
                </Container>
            }

        </div>
    )

}

Selector.propTypes = {
    className: PropTypes.string,
    options: PropTypes.array,
    selected: PropTypes.string,
    select: PropTypes.func,
    id: PropTypes.string
}

export { AdminGeneral };