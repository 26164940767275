import React from "react";
// react plugin used to create charts
import { Line } from "react-chartjs-2";
import { GlobalPointsSummaryLang } from '../../lang'
import NumberFormat from 'react-number-format'

// reactstrap components
import {
  Container,
  Card,
  Row,
  Col,
  CardBody
} from "reactstrap";


var GlobalChart = props => {
  return (<Card className="globalChart">
    <CardBody>
      <h6>{props.label}</h6>
      <h6 className="stdOutData"><NumberFormat value={props.globalData} displayType={'text'} thousandSeparator={true} decimalScale={2} /></h6>
      <Row>
        <Col md="12" lg='12'>
          {props.chartData !== null ?
            <Card className="noBorder">
              <div>
                <Line data={props.chartData} options={props.chartOptions} />
              </div>
            </Card>
            :
            <Card>
              <p>No data</p>
            </Card>
          }
        </Col>
      </Row>
    </CardBody>
  </Card>)
}


class GlobalPoints extends React.Component {
  constructor(props) {
    super(props)
    this._isMounted = false;
    this.state = {
      data: null,
      chartDataPoints: null,
      chartDataRedem: null,
      labels: GlobalPointsSummaryLang(props.lang),
      // GlobalPointsSummaryLang(props.lang)
    }
    this.readyChartData = this.readyChartData.bind(this);

    this.chartOptions = {
      responsive: true,
      maintainAspectRatio: true,
      legend: {
        display: true,
        labels: {
          fontColor: '#9a9a9a'
        }
      },
      scales: {
        yAxes: [
          {
            gridLines: {
              drawBorder: false,
              color: "rgba(29,140,248,0.1)",
              zeroLineColor: "#bac4ce"
            },
            ticks: {
              fontColor: "#9a9a9a",
              maxTicksLimit: 10,
              userCallback: function (value, index, values) {
                var strVal = value.toString();

                if (value >= 1000 && value < 10000) {
                  //strVal = value.toString();
                  return strVal.substring(0, 1) + "," + strVal.substring(1);
                }

                if (value >= 10000 && value < 100000) {
                  //strVal = value.toString();
                  return strVal.substring(0, 2) + "," + strVal.substring(2);
                }

                if (value >= 10000 && value < 1000000) {
                  //strVal = value.toString();
                  return strVal.substring(0, 3) + "," + strVal.substring(3);
                }

                if (value >= 1000000) {
                  value = (value / 1000000);
                  strVal = value.toString();
                  return strVal + "m";
                }

                return strVal;
              }
            }
          }
        ],
        xAxes: [
          {
            gridLines: {
              drawBorder: false,
              color: "rgba(29,140,248,0.1)",
              zeroLineColor: "#bac4ce"
            },
            ticks: {
              fontColor: "#9a9a9a",
              fontSize: 8
            }
          }
        ]
      },
      tooltips: {
        callbacks: {
          label: function (tooltipItem, data) {
            let v = tooltipItem.yLabel;
            return v.toLocaleString();
          }
        }
      }
    }
  }

  async componentDidMount() {
    this._isMounted = true;
    try {
      if (this._isMounted) {
        let l = await this.props.getBucketLabels("GlobalPointsSummary", this.state.labels);
        let data = await this.props.askFor('globalsum', 'global/globalpointssum')
        let dataRedem = await this.props.askFor('globalsum', 'global/globalredemptionspermonth', [], false)
        let dataPoints = await this.props.askFor('globalsum', 'global/globalsalespermonth', [], false)
        if (data === null) { this.setState(() => ({ chartDataPoints: dataPoints, chartDataRedem: dataRedem })) }
        if (dataRedem === null) { this.setState(() => ({ data: data[0], chartDataPoints: dataPoints })) }
        if (dataPoints === null) { this.setState(() => ({ data: data[0], chartDataRedem: dataRedem })) }
        if (data !== null && dataRedem !== null && dataPoints !== null) {
          this.setState(() => ({ data: data[0], chartDataPoints: dataPoints, chartDataRedem: dataRedem}))
        }
        if(l !== null){
          this.setState({labels:l})
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.lang !== this.props.lang) {
      if (this._isMounted) {
        this.setState({ labels: GlobalPointsSummaryLang(this.props.lang) }, () => { this.forceUpdate() });
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  readyChartData = (data, label) => {
    if (data === null || data === undefined) {
      return null;
    }

    var createMonthString = (month, year) => {
      let m = null;
      switch (month) {
        case 1: m = ['ENE', year]; break;
        case 2: m = ['FEB', year]; break;
        case 3: m = ['MAR', year]; break;
        case 4: m = ['ABR', year]; break;
        case 5: m = ['MAY', year]; break;
        case 6: m = ['JUN', year]; break;
        case 7: m = ['JUL', year]; break;
        case 8: m = ['AGO', year]; break;
        case 9: m = ['SEP', year]; break;
        case 10: m = ['OCT', year]; break;
        case 11: m = ['NOV', year]; break;
        case 12: m = ['DEC', year]; break;
        default: m = ''; break;
      }

      return m;
    }


    return (canvas => {
      let ctx = canvas.getContext("2d");
      var gradientStroke = null;
      var pointColor = "#43b02a";

      switch (this.props.theme) {
        case "default.css":
          gradientStroke = ctx.createLinearGradient(67, 176, 42, 100);
          gradientStroke.addColorStop(1, "rgba(67, 176, 42,0.6)");
          gradientStroke.addColorStop(0.4, "rgba(67, 176, 42,0.4)");
          gradientStroke.addColorStop(0, "rgba(67, 176, 42,0.2)");
          break;
        case "lightTheme.css":
          gradientStroke = ctx.createLinearGradient(155, 118, 54, 100);
          gradientStroke.addColorStop(1, "rgba(155, 118, 54,0.6)");
          gradientStroke.addColorStop(0.4, "rgba(155, 118, 54,0.4)");
          gradientStroke.addColorStop(0, "rgba(155, 118, 54,0.2)");
          pointColor = "#af9044";
          break;
        default: ; break;
      }

      let labels = [];
      let chartData = []


      data.forEach(element => {
        if (element === null || element === undefined) {
          return null;
        }
        else {
          labels.push(createMonthString(element['month'], element['year']));
          chartData.push(element['points'])
          return null;
        }
      });

      return {
        labels: labels,
        datasets: [
          {
            label: label,
            fill: true,
            backgroundColor: gradientStroke,
            borderColor: pointColor,
            borderWidth: 0,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: pointColor,
            pointBorderColor: "rgba(0,0,0,0)",
            pointHoverBackgroundColor: "#fff",
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: chartData
          }
        ]
      };
    })
  }

  readyRedemtionSummary = (redemtionsArray) => {
    var redemtionsTotal = 0;
    redemtionsArray.forEach(doc => {
      redemtionsTotal += doc["points"];
    })

    return redemtionsTotal;
  }

  render() {
    var w = this.props.hideRedeem ? "12" : "6";
    if (this.props.hideRedeem) { this.chartOptions.maintainAspectRatio = false } else { this.chartOptions.maintainAspectRatio = true }
    return (<Container fluid>
      <Row>
        {this.state.data !== null ?
          //Main Render
          <>
            <Col className="globalChartCol" lg={w} md={w} sm={w} xs="12">
              <GlobalChart
                globalData={this.readyRedemtionSummary(this.state.chartDataPoints) /* this.state.data.PUNTOSDISPONIBLES */}
                chartData={this.readyChartData(this.state.chartDataPoints, this.state.labels.lblAvailablePoints)}
                label={this.state.labels.lblAvailablePoints}
                chartOptions={this.chartOptions}
                strech={this.props.hideRedeem}
              />
            </Col>

            {this.props.hideRedeem ? "" :
              <Col className="globalChartCol" lg="6" md="6" sm="6" xs="12">
                <GlobalChart
                  globalData={this.readyRedemtionSummary(this.state.chartDataRedem) /* this.state.data.PUNTOSCAMBIADOS */}
                  chartData={this.readyChartData(this.state.chartDataRedem, this.state.labels.lblRedemeedPoints)}
                  label={this.state.labels.lblRedemeedPoints}
                  chartOptions={this.chartOptions}
                />
              </Col>
            }
          </>
          :
          // Loading Screen
          <>

            <Col lg={w} md={w} sm={w} xs="12">
              <Card className="globalChart">
                <CardBody>
                  {this.state.labels.lblRedemeedPoints}
                  {this.props.loadingSpinner}
                  <p className="text-center">{this.state.labels.lblLoading}</p>
                </CardBody>
              </Card>

            </Col>

            {this.props.hideRedeem ? "" :
              <Col md="6" sm="12" xs="12" className="text-center">
                <Card className="globalChart">
                  <CardBody>
                    {this.state.labels.lblRedemeedPoints}
                    {this.props.loadingSpinner}
                    <p className="text-center">{this.state.labels.lblLoading}</p>
                  </CardBody>
                </Card>
              </Col>
            }
          </>
        }
      </Row>

    </Container>)
  }
}



export default GlobalPoints;