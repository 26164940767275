import React from "react";
// nodejs library that concatenates classes
import { ProductsByGenderLang } from '../../lang'
import NumberFormat from 'react-number-format'
import classNames from "classnames";
// reactstrap components
import {
    Container,
    Card,
    Row,
    Col,
    CardBody,
    Table
} from "reactstrap";


var SingleTable = (props) => {
    /**Props:
     *      data: {},
     *      isSkuHidden: boolean,
     *      topProduct : {},
     */

    var getIcon = () => {
        var icon = "";
        switch (props.type) {
            case "M": icon = <i className="fa fa-male" />; break;
            case "F": icon = <i className="fa fa-female" />; break;
            default: break;
        }

        return icon;
    }

    return (<Container fluid={true} className="genderProductTable">


        {props.topProduct ?
            <Card className="favProd">
                <CardBody>
                    {getIcon()}
                    {props.type === 'F' || props.type === 'f' ? <h6>{props.labels.lblTitleF}</h6> : <h6>{props.labels.lblTitleM}</h6>}
                    <h6 className={"stdOutData " + props.type}>{props.topProduct._id.Producto}</h6>
                    {props.isSkuHidden ? /**No pinta nada */ "" : <p>Sku: {props.topProduct._id.Sku}</p>}
                    <hr />
                    <label>{props.labels.lblVisitCounter}:</label>
                    <b>
                        <NumberFormat className={props.type} value={props.topProduct.count} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={0} />
                    </b>
                </CardBody>
            </Card>
            : /*No se pinta nada*/""}


        {props.data === null || props.data === undefined ? /*No se pinta nada*/'' :
            <Card>
                <CardBody>
                    <Table responsive className="tableProductdsByGender">
                        <tbody>
                            {props.data.map((element, key) => {
                                if (key !== 0) {
                                    if (key <= 9) {
                                        return (<tr key={key}>
                                            <td>
                                                <h4 className={props.type}>{key + 1}</h4>
                                            </td>
                                            <td>
                                                {element._id.Producto}
                                                {props.isSkuHidden?   /*No pinta nada*/"" : <><br /><small><b>SKU:</b> {element._id.Sku}</small></> }
                                                <br />
                                                <b>{props.labels.lblCount}: <span className={classNames({ maleIcon: props.type === "M", femaleIcon: props.type === "F", "count-text": true })}><NumberFormat value={element.count} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={0} /></span></b>
                                            </td>
                                        </tr>
                                        )
                                    }
                                }
                            })}
                        </tbody>
                    </Table>
                </CardBody>
            </Card>
        }
    </Container>
    )

}



class ProdByGender extends React.Component {
    constructor(props) {
        super(props)
        this._isMounted = false;
        this.state = { genderSeparation: props.genderSeparation, labels: ProductsByGenderLang(this.props.lang), dataFemale: null, dataMale: null }
        this.sortByKey = this.sortByKey.bind(this);
    }

    async componentDidMount() {
        this._isMounted = true;
        try {
            if (this._isMounted) {
                let dataF = await this.props.askFor('globalsum', 'global/globalproductsbygenderfemale')
                let dataM = await this.props.askFor('globalsum', 'global/globalproductsbygendermale')
                if (dataF == null) { this.setState(() => ({ dataMale: dataM })) }
                if (dataM == null) { this.setState(() => ({ dataFemale: dataF })) }
                if (dataF !== null && dataM !== null) { this.setState(() => ({ dataMale: dataM, dataFemale: dataF })) }
            }
        } catch (err) {
            console.log(err)
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.lang !== this.props.lang) {
            if (this._isMounted) {
                this.setState({ labels: ProductsByGenderLang(this.props.lang) }, () => { this.forceUpdate() });
            }
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    sortByKey = (array, key, direction) => {
        var x;
        var y;
        if (direction === undefined) direction = 1; // Ascending
        return array.sort(function (a, b) {
            if (direction === 1) {
                x = a[key]; y = b[key];
                return ((x < y) ? -1 : ((x > y) ? 1 : 0));
            }
            else {
                x = a[key]; y = b[key];
                return ((x > y) ? -1 : ((x < y) ? 1 : 0));
            }
        });
    }

    render() {
        if (this.state.genderSeparation) {
            return (<>
                {/**Props:
                *      data: {},
                *      isSkuHidden: boolean,
                *      topProduct : {},
                */}

                {this.state.dataFemale !== null && this.state.dataMale !== null ?
                    <Container fluid>
                        <Row className="noPadding">
                            <Col className="noPadding">
                                <SingleTable
                                    topProduct={this.state.dataMale[0]}
                                    data={this.state.dataMale}
                                    isSkuHidden={this.props.isFieldHidden('sku')}
                                    labels={this.state.labels}
                                    type='M'
                                />
                            </Col>

                            <Col className="noPadding">
                                <SingleTable
                                    topProduct={this.state.dataFemale[0]}
                                    data={this.state.dataFemale}
                                    isSkuHidden={this.props.isFieldHidden('sku')}
                                    labels={this.state.labels}
                                    type='F' />
                            </Col>
                        </Row>
                    </Container>
                    :
                    <Container fluid>
                        <Row className="noPadding">
                            <Col className="noPadding">
                                <Card>
                                    <CardBody>
                                        {this.props.loadingSpinner}
                                        <p className="text-center">{this.state.labels.lblLoading}</p>
                                    </CardBody>
                                </Card>
                            </Col>

                            <Col className="noPadding">
                                <Card>
                                    <CardBody>
                                        {this.props.loadingSpinner}
                                        <p className="text-center">{this.state.labels.lblLoading}</p>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                }
            </>)
        }
        else {
            return (<></>)
        }

    }
}




export default ProdByGender;



