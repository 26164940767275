import React from "react";
import classnames from 'classnames';
import FetchCmp from "../../components/FetchCmp/fetchCmp"
import { CampaignDetailLang } from '../../lang'
import ReactTooltip from "react-tooltip";
import DatePicker from "react-datepicker";
// reactstrap components
import {
    Card, CardBody,
    Container, Row, Col,
    Button,
    TabContent, TabPane,
    FormGroup, Input,
    Dropdown, DropdownToggle, DropdownMenu, DropdownItem,
    Nav, NavItem, NavLink, Progress, Table
} from "reactstrap";
import NumberFormat from "react-number-format";
import { Line, Bar, Pie, Polar } from "react-chartjs-2";
import { ReactComponent as Icon4 } from '../../assets/icons/icon4.svg';

var Xbutton = props => {

    var s = { cursor: "pointer", color: "white" }

    return (<>
        <span className="xButton" onClick={() => { props.onClick() }} aria-label="Close">&times;</span>
    </>)
}

var CampaignDatePicker = props => {

    return (
        <div className="campaignDatePickerD">
            <Card>
                <CardBody>
                    <Xbutton onClick={() => { props.toggleDatePicker() }} />
                    <p>La campaña será enviada:</p>
                    <DatePicker
                        selected={props.selectedDate}
                        onChange={props.handleChange}
                    />
                    <Button onClick={() => { props.sendCampaign(props.campaignId) }} className="btnCreate">Programar campaña</Button>
                </CardBody>
            </Card>
        </div>
    )
}

class SmsCampaignDetail extends React.Component {
    constructor(props) {
        super(props)
        this._isMounted = false;
        this.labels = CampaignDetailLang(props.lang)
        this.state = {
            tab: "1",
            body: { campaignId: props.campaign["ID-cortteza"], page: 1, textSearch: null, type: "sms" },
            contactList: null,
            count: null,
            textSearch: null,
            sendingCampaign: false,
            sendAt: new Date(),
            showDataPicker: false,
        }
        this.lineChartOptions = {
            responsive: true,
            aspectRatio: false,
            legend: {
                display: true,
                labels: {
                    fontColor: '#9a9a9a'
                }
            },
            scales: {
                yAxes: [
                    {
                        gridLines: {
                            drawBorder: true,
                            color: "rgba(29,140,248,0.1)",
                            zeroLineColor: "#bac4ce"
                        },
                        ticks: {
                            fontColor: "#9a9a9a",
                            maxTicksLimit: 10,
                            userCallback: function (value, index, values) {
                                var strVal = value.toString();

                                if (value >= 1000 && value < 10000) {
                                    //strVal = value.toString();
                                    return strVal.substring(0, 1) + "," + strVal.substring(1);
                                }

                                if (value >= 10000 && value < 100000) {
                                    //strVal = value.toString();
                                    return strVal.substring(0, 2) + "," + strVal.substring(2);
                                }

                                if (value >= 10000 && value < 1000000) {
                                    //strVal = value.toString();
                                    return strVal.substring(0, 3) + "," + strVal.substring(3);
                                }

                                if (value >= 1000000) {
                                    value = (value / 1000000);
                                    strVal = value.toString();
                                    return strVal + "m";
                                }

                                return strVal;
                            }
                        }
                    }
                ],
                xAxes: [
                    {
                        gridLines: {
                            drawBorder: true,
                            color: "rgba(29,140,248,0.1)",
                            zeroLineColor: "#bac4ce"
                        },
                        ticks: {
                            fontColor: "#9a9a9a",
                            fontSize: 12
                        }
                    }
                ],
            },
            tooltips: {
                callbacks: {
                    label: function (tooltipItem, data) {
                        let v = tooltipItem.yLabel;
                        return v.toLocaleString();
                    }
                }
            }
        }
        this.setSendAt = this.setSendAt.bind(this);
        this.toggleDatePicker = this.toggleDatePicker.bind(this);
    }

    //0. REACT LIFECYCLE
    async componentDidMount() {
        this._isMounted = true;
        let cacheId = 'GetSMSCampaignPoblation-' + this.props.bucket.bucketId + "-" + this.props.campaign["ID-cortteza"];
        let data = await this.props.askFor('campaigns', "getCampaignPoblation", [], false, this.state.body, cacheId);
        if(data != null){
          this.setState({ contactList: data.list, count: data.count })  
        }
    }

    async componentDidUpdate(prevProps, prevState) {
        if (prevState.body.page != this.state.body.page) {
            if (this._isMounted) {
                let cacheId = 'GetCampaignPoblation-' + this.props.bucket.bucketId + "-" + this.props.campaign["ID-cortteza"] + "-" + this.state.body.page;
                let data = await this.props.askFor('campaigns', "getCampaignPoblation", [], false, this.state.body, cacheId);
                if(data != null){
                  this.setState({ contactList: data.list, count: data.count })  
                }  
            }
        }

        if (prevProps.lang != this.props.lang) {
            if (this._isMounted) {
                this.labels = CampaignDetailLang(this.props.lang)
                this.forceUpdate()
            }
        }
    }


    //1. TABS 

    nextPage() {
        let pageCount = Math.ceil(this.state.count / 10);
        if (this.state.body.page + 1 < pageCount) {
            this.setState(state => {
                let bod = state.body;
                bod.page++;
                return ({ body: bod })
            })
        }
        else {
            //reach last page
        }

    }

    prevPage() {
        if (this.state.page == 0) { return /*Reach firts page */ }
        this.setState(state => {
            let bod = state.body;
            bod.page--;
            return ({ body: bod })
        })
    }

    firstPage() {
        let currentPage = this.state.body.page;
        if (currentPage > 0) {
            this.setState(state => {
                let bod = state.body;
                bod.page = 0;
                return ({ body: bod })
            })
        }
    }

    lastPage() {
        let currentPage = this.state.page;
        let clientsCount = this.props.data.count;
        let pageCount = Math.ceil(clientsCount / this._pageSize);
        if (currentPage < pageCount) {
            this.setState(state => {
                let bod = state.body;
                bod.page = pageCount - 1;
                return ({ body: bod })
            })
        }


    }

    //2. CLIENT SEARCH FUNCTION
    handleTextSearch(textSearch) {
        if (this._isMounted) {
            this.setState(state => {
                var b = state.body;
                if (textSearch == "") {
                    b.textSearch = null;
                }
                else {
                    b.textSearch = textSearch;
                }

                return ({ body: b });
            })
        }
    }

    async searchClient() {
        if (this._isMounted) {
            this.setState({ contactList: null, count: null })
            let cacheId = 'GetCampaignPoblation-' + this.props.bucket.bucketId + "-" + this.props.campaign["ID-cortteza"] + "-" + this.state.body.page + "-search-" + this.state.body.textSearch;
            let data = await this.props.askFor('campaigns', "getCampaignPoblation", [], false, this.state.body, cacheId);
            if( data != null ){
              this.setState({ contactList: data.list, count: data.count })  
            }
        }
    }

    //3. Campaign Creation FUNCTIONS
    toggleDatePicker() {
        if (this._isMounted) {
            this.setState(state => { return ({ showDataPicker: !state.showDataPicker }) })
        }
    }

    setSendAt(sendAt) {
        if (this._isMounted) {
            this.setState({ sendAt: sendAt })
        }
    }

    async sendCampaign(campaignId) {
        var cacheId = "SendSMSCampaign-" + this.props.campaign.name
        var res = await this.props.askFor('campaigns', "sendsmscampaign", [], false, { campaignId: campaignId, config: { sendNow: true } }, cacheId);
        if(res != null){
          this.props.changeScreen({ screen: 'list', creationCancel: false });  
        }
    }

    async scheduleCampaign(campaignId) {
        var cacheId = "ScheduleSMSCampaign-" + this.props.campaign.name
        var res = await this.props.askFor('campaigns', "sendsmscampaign", [], false, { campaignId: campaignId, config: { sendNow: true, sendAt: this.state.sendAt } }, cacheId);
        if(res != null){
          this.props.changeScreen({ screen: 'list', creationCancel: false });  
        }
    }

    // PRE-RENDER FUNCTIOSNS

    //Z. Render
    render() {
        return (
            <div className="content">
                <Container fluid className={classnames({ "blur": this.state.showDataPicker })}>
                    <Row className="titleRow">
                        <h1>{this.props.campaign.name}</h1>
                        <Button onClick={() => { this.props.changeScreen({ screen: 'list' }) }}><span>&#10229;</span> {this.labels.btnGetBack}</Button>
                    </Row>
                    <Row>
                        <Col className="content-section">
                            <div className="innerSection">
                                <div className="smsCampaignDetail">
                                    <div className="stats">
                                        <Row className="statsRow">
                                            <Col lg="8" md="10" sm="12">
                                                <Card>
                                                    <CardBody>
                                                        {this.props.campaign.status == "pending" ?
                                                            <Row>
                                                                <Col className="content">
                                                                    <p>{this.labels.lblSchedule}: <b>{new Date(this.props.campaign.sentDate).toLocaleDateString()}</b></p>
                                                                </Col>
                                                            </Row>
                                                            : ""}

                                                        <Row>
                                                            <Col className="content">
                                                                <h4>{this.labels.lblContent}</h4>
                                                                <p>"{this.props.campaign.text}"</p>
                                                            </Col>
                                                        </Row>

                                                        <Row>
                                                            <Col className="dates">
                                                                <h6>{this.labels.lblCreationDate}: </h6>
                                                                <p>{new Date(this.props.campaign.creationDate).toLocaleDateString()}</p>

                                                            </Col>

                                                            <Col className="dates">
                                                                <h6>{this.labels.lblSentDate}: </h6>
                                                                <p>{((this.props.campaign.sentDate != undefined) && (new Date().getTime() > new Date(this.props.campaign.sentDate).getTime())) ? new Date(this.props.campaign.sentDate).toLocaleDateString() : this.labels.lblNotSentYet}</p>
                                                            </Col>
                                                        </Row>

                                                        {this.props.campaign.status == "draft" ?
                                                            <Row>
                                                                <Col className="buttons">
                                                                    {this.state.sendingCampaign ?
                                                                        <div className="loading">
                                                                            {this.props.loadingSpinner}
                                                                            <p>Procesando petición</p>
                                                                        </div>
                                                                        :
                                                                        <>
                                                                            <Button onClick={() => { this.sendCampaign(this.props.campaign["ID-cortteza"]) }}>{this.labels.lblSendCampaign}</Button>
                                                                            <Button onClick={() => { this.toggleDatePicker() }}>{this.labels.lblScheduleCampaign}</Button>
                                                                        </>
                                                                    }
                                                                </Col>
                                                            </Row>
                                                            :
                                                            ""
                                                        }

                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                                <div>
                                    {/* Lista de clientes */}
                                    {this.state.contactList != null ?
                                        <Card>
                                            <Row className="searchTextRow">
                                                <Input type="text" placeholder={this.labels.lblClientSearchBar} onChange={e => { this.handleTextSearch(e.target.value) }} />
                                                <button onClick={() => { this.searchClient() }}><i className="ti-search"></i></button>
                                            </Row>


                                            <Table responsive className="tableClients">
                                                <thead>
                                                    <tr>
                                                        <th>{this.labels['lblMemberNumber']}</th>
                                                        <th>{this.labels['lblName']}</th>
                                                        <th>Status</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.contactList != null ?
                                                        this.state.contactList.map((client, key) => {
                                                            var deliveredStatus = <i className="smsNotdelivered">x</i>;
                                                            var stats = this.props.campaign.stats[this.props.campaign.stats.length - 1];
                                                            if (stats != undefined) {
                                                                stats.map(msg => {
                                                                    if (msg.TELCELULAR == client.TELCELULAR) {
                                                                        deliveredStatus = <i className="ti-email"></i>;
                                                                    }
                                                                })
                                                            }


                                                            return (
                                                                <tr key={key}>
                                                                    <td>{client.CLIENTE[0]}</td>
                                                                    <td>{client.NOMBRE}</td>
                                                                    <td>{deliveredStatus}</td>
                                                                    <td>
                                                                        <button className="btnClientDetail"
                                                                            onClick={() => {
                                                                                this.props.changeScreen({ screen: "client", clientId: client.CLIENTE[0], campaignDetail: this.props.campaign })
                                                                            }}
                                                                        >
                                                                            {this.labels['lblButtonViewClient']}
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                        :
                                                        <tr></tr>
                                                    }
                                                </tbody>
                                            </Table>
                                            {this.state.count != null && this.state.count > 10 ?
                                                <Row className="text-center">
                                                    <Col>
                                                        <button className="pager-button" onClick={() => { this.firstPage() }}>{this.labels.btnFirst}</button>
                                                        <button className="pager-button" onClick={() => { this.prevPage() }}>
                                                            <i className="ti-angle-left"></i></button>
                                                        <button className="pager-button" onClick={() => { this.nextPage() }}>
                                                            <i className="ti-angle-right"></i>
                                                        </button>
                                                        <button className="pager-button" onClick={() => { this.lastPage() }}>{this.labels.btnLast}</button>
                                                    </Col>
                                                </Row>
                                                : ""}
                                        </Card>
                                        :
                                        <>
                                            {this.props.loadingSpinner}
                                            <h5 className="text-center">Cargando el listado de participantes</h5>
                                        </>
                                    }

                                </div>
                            </div>
                        </Col>
                    </Row >
                </Container >
                {this.state.showDataPicker ?
                    <CampaignDatePicker
                        selectedDate={this.state.sendAt}
                        handleChange={this.setSendAt}
                        sendCampaign={this.scheduleCampaign}
                        campaignId={this.props.campaign["ID-cortteza"]}
                        toggleDatePicker={this.toggleDatePicker}
                    />
                    : ""}

            </div >
        )
    }

}




export default SmsCampaignDetail;