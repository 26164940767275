import React from "react";
import { GlobalCountLang } from '../../lang'
import NumberFormat from 'react-number-format'
import classNames from "classnames";

// reactstrap components
import {
    Container,
    Card,
    Row,
    Col,
    CardBody
} from "reactstrap";


var SingleCard = (props) => {
    return (<Col className={classNames({ singleCard: true, 'dataLoading': (props.data == undefined || Object.keys(props.data).length === 0 && props.data.constructor === Object) })}>
        <Row>
            <Col xs="12" md="12" sm="12">
                <Card>
                    <CardBody>
                        <h3 className="cardTitle">{props.label}</h3>
                        {props.data == undefined || Object.keys(props.data).length === 0 && props.data.constructor === Object ?
                            <div>
                                {props.loadingSpinner}
                                <p className="text-center"> {props.lblLoading}</p>
                            </div>
                            :
                            <>
                                <h1 className='cardValue'>
                                    {props.isMoney ?
                                        <NumberFormat value={props.mAvarageisHidden ? props.data.avg_ticketyear : props.data} displayType={'text'} thousandSeparator={true} prefix={props.currencySymbol} />
                                        :
                                        <NumberFormat value={props.data} thousandSeparator={true} displayType='text'></NumberFormat>}
                                </h1>
                                {props.mAvarageisHidden ?
                                    <Row className="avgTicketSubdata">
                                        <Col lg="6" xs='6'>
                                            <p>{props.mLabels.sales.label}:</p>
                                            <b className='avgTicketExplainData'><NumberFormat prefix={props.currencySymbol} value={props.data.total_amount} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={0} /></b>
                                        </Col>
                                        <Col lg="6" xs='6'>
                                            <p>{props.mLabels.visit.label}:</p>
                                            <b className='avgTicketExplainData'><NumberFormat value={props.data.total_visits} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={0} /></b>
                                        </Col>
                                    </Row>
                                    :
                                    ''}

                                <p className="explain">{props.explan}</p>
                            </>}
                    </CardBody>
                </Card>
            </Col>
        </Row>
    </Col>);
}


class GlobalCounts extends React.Component {
    constructor(props) {
        super(props)
        this._isMounted = false;
        this.state = { data: {}, labels: GlobalCountLang(props.lang) }
        // GlobalCountLang(props.lang)
    }

    async componentDidMount() {
        this._isMounted = true;
        try {
            if (this._isMounted) {
                let l = await this.props.getBucketLabels("GlobalCount", GlobalCountLang(this.props.lang));
                let data = await this.props.askFor('globalsum', 'global/globalcountsummary')
                if ( data != null ){
                    // console.log(data)
                  this.setState(() => ({ data: data}))  
                }
                if( l != null){
                    this.setState({labels:l});
                }
            }
        } catch (err) {
            console.log(err);
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.lang != this.props.lang) {
            if (this._isMounted) {
                this.setState({ labels: GlobalCountLang(this.props.lang) }, () => { this.forceUpdate() })
            }
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }


    render() {
        return (<>
            <div className="globalCountsContainer">
                {/* Client count + Avarege visit per year */}
                <Row className="gbRow">
                    <SingleCard
                        label={this.state.labels.lblClientCount}
                        data={this.state.data.count}
                        explan={this.state.labels.lblClientCountExplanation}
                        currencySymbol={this.props.currencySymbol}
                        loadingSpinner={this.props.loadingSpinner}
                    />
                </Row>

                <Row className="gbRow">
                    {this.props.useNoPeriods ?
                        <SingleCard
                            label={this.state.labels.lblAvgVisitsYear}
                            data={this.state.data.avg_visitsperyear_withoutperiods}
                            explan={this.state.labels.lblAvgTicketPerYearExplanation}
                            currencySymbol={this.props.currencySymbol}
                            lblLoading={this.state.labels.lblLoading}
                            loadingSpinner={this.props.loadingSpinner}
                        />
                        :
                        <SingleCard
                            label={this.state.labels.lblAvgVisitsYear}
                            data={this.state.data.avg_visitsperyear}
                            explan={this.state.labels.lblAvgVisitsYearExplanation}
                            currencySymbol={this.props.currencySymbol}
                            lblLoading={this.state.labels.lblLoading}
                            loadingSpinner={this.props.loadingSpinner}
                        />
                    }
                </Row>

                <Row className="gbRow">
                    {this.props.isSectionHidden('monthlyAvgTicket') ?
                        <SingleCard label={this.state.labels.lblAvgTicketYear}
                            data={this.state.data}
                            explan={this.state.labels.lblAvgTicketPerYearExplanation}
                            isMoney={true} mAvarageisHidden={true}
                            mLabels={{ visit: { label: this.state.labels.lblVisits, explan: this.state.labels.lblVisitsExplanation }, sales: { label: this.state.labels.lblSales, explan: this.state.labels.lblSalesExplanation } }}
                            currencySymbol={this.props.currencySymbol}
                            loadingSpinner={this.props.loadingSpinner}
                        />
                        :
                        <>
                            <SingleCard label={this.state.labels.lblAvgTicket}
                                data={this.state.data.avg_ticketpermonth}
                                explan={this.state.labels.lblAvgTicketPerMonthExplanation}
                                isMoney={true}
                                currencySymbol={this.props.currencySymbol}
                                loadingSpinner={this.props.loadingSpinner}
                            />
                            {/* <SingleCardMin label={this.state.labels.lblAvgTicket}
                                data={this.state.data.avg_ticketyear}
                                explan={this.state.labels.lblAvgTicketPerYearExplanation}
                                isMoney={true}
                                currencySymbol={this.props.currencySymbol}
                                loadingSpinner={this.props.loadingSpinner}
                            /> */}
                        </>}
                </Row>
            </div>
        </>)

    }
}


export default GlobalCounts;