
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
import bucketIcon from "../../assets/img/bicon.png";
import bucketIconW from "../../assets/img/biconW.png";

// reactstrap components
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Input,
  NavbarBrand,
  Navbar,
  NavLink,
  Nav,
  Container,
  Modal,
  Col,
} from "reactstrap";

class AdminNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseOpen: true,
      modalSearch: false,
      color: "navbar-white",
      test: false,
    };
  }
  componentDidMount() {
    window.addEventListener("resize", this.updateColor);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateColor);
  }
  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  updateColor = () => {
    if (window.innerWidth < 993 && this.state.collapseOpen) {
      this.setState({
        color: "navbar-white"
      });
    } else {
      this.setState({
        color: "navbar-white"
      });
    }
  };
  // this function opens and closes the collapse on small devices
  toggleCollapse = () => {
    if (this.state.collapseOpen) {
      this.setState({
        color: "navbar-white"
      });
    } else {
      this.setState({
        color: "navbar-white"
      });
    }
    this.setState({
      collapseOpen: true
    });
  };
  // this function is to open the Search modal
  toggleModalSearch = () => {
    this.setState({
      modalSearch: !this.state.modalSearch
    });
  };

  handleBucketButton = () => {
    this.props.handleBucketButton('bucketSelector');
  }

  render() {
    return (
      <>
        <Navbar
          className={classNames("navbar-absolute", this.state.color)}
          expand="lg"
        >
          <Container fluid>
            <Col className="navbarCol" sm="12" md='7' lg='8'>
              <div className="navbar-wrapper">
                <div
                  className={classNames("navbar-toggle d-inline", {
                    toggled: this.props.sidebarOpened
                  })}
                >
                  <button
                    className="navbar-toggler"
                    type="button"
                    onClick={this.props.toggleSidebar}
                  >
                    <span className="navbar-toggler-bar bar1" />
                    <span className="navbar-toggler-bar bar2" />
                    <span className="navbar-toggler-bar bar3" />
                  </button>
                </div>
                {this.props.bucketName != undefined || this.props.bucketName != null ? <b className="stdOutData">{this.props.bucketName}</b> : ""}
              </div>
            </Col>
            <Col className="navbarCol" sm="12" md='5' lg='4'>
                  <div className="navCollapse">
                    <a className="bucketLink" onClick={() => { this.handleBucketButton() }}>{/* <i className="ti-dropbox-alt" /> */}<img className="bucketIcon" src={bucketIcon}></img> Buckets</a>
                    {/* <a className="bucketLink" ><i className="ti-search"/>  Buscar</a> */}
                  </div>
            </Col>
          </Container>
        </Navbar>
        <Modal
          modalClassName="modal-search"
          isOpen={this.state.modalSearch}
          toggle={this.toggleModalSearch}
        >
          <div className="modal-header">
            <Input id="inlineFormInputGroup" placeholder="SEARCH" type="text" />
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={this.toggleModalSearch}
            >
              <i className="tim-icons icon-simple-remove" />
            </button>
          </div>
        </Modal>
      </>
    );
  }
}

export default AdminNavbar;
