import React, { useState } from "react";
import classnames from 'classnames';
import { Route, Switch, Redirect } from "react-router-dom";
import { Transition, CSSTransition, TransitionGroup } from "react-transition-group"
import FetchCmp from "../../components/FetchCmp/fetchCmp"
import CampaignDetail from "./CampaignDetail";
import SmsCampaignDetail from "./SmsCampaingDetail";
import CampaignCreator from "./CampaignCreator/CampaignCreator";
import ClientDetail from '../Client/ClientDetail'
import { CampaignsLang, CampaignCardLang, SmsCampaignCardLang } from '../../lang'
import ReactTooltip from "react-tooltip";
// reactstrap components
import {
    // Card,
    // CardBody,
    Container,
    Row,
    Col,
    Button,
    // FormGroup, Input,
    Dropdown, DropdownToggle, DropdownMenu, DropdownItem,
    Progress,
    Table
} from "reactstrap";
import NumberFormat from "react-number-format";
import { Bar, Pie, Polar } from "react-chartjs-2";
import { ReactComponent as EmailIcon } from '../../assets/icons/createCampaign/email-icon.svg';
import { ReactComponent as SmsIcon } from '../../assets/icons/createCampaign/sms-icon.svg';
import { ReactComponent as Icon4 } from '../../assets/icons/icon4.svg';


var CampaingCard = props => {

    var labels = CampaignCardLang(props.lang);

    var createDataSet = stats => {
        let sent = stats[stats.length - 1].sent;
        let s = [];
        if (stats.length > 6) {
            s = stats.slice(Math.max(stats.length - 6, 0));
        }
        else {
            s = stats;
        }

        let l = []
        let dataset = { data: [], label: labels['lblOpenedEmails'], backgroundColor: "rgba(175, 144, 68, 0.3)" }
        let acumulator = 0;

        s.forEach((stat, index) => {
            if (index % 2 == 0) {
                dataset.data.push(acumulator)
                acumulator = 0;
                if (index == 0) { l.push('0h') }
                if (index == 2) { l.push('24h') }
                if (index == 4) { l.push('48h') }
                if (index == 6) { l.push('72h') }

            }
            else {
                let perc = (100 * stat.uniqueViews) / sent;
                acumulator += perc;
            }
        })
        return { datasets: [dataset], labels: l };
    }

    var formatCampaignStats = stats => {
        let formatStats = {};

        var sent = stats["sent"];
        let delivered = stats.delivered;
        let softBounced = stats.softBounces;
        let hardBounced = stats.hardBounces;
        let bounced = stats.hardBounces + stats.softBounces;
        let totalOpenings = stats.viewed;
        let singleOpenings = stats.uniqueViews;
        let multiOpenings = totalOpenings;
        var dPerc = (100 * delivered) / sent;
        var bPerc = (100 * bounced) / sent;
        var sbPerc = (100 * softBounced) / sent;
        var hbPerc = (100 * hardBounced) / sent;
        var toPerc = (100 * singleOpenings) / sent;
        var soPerc = (100 * singleOpenings) / sent;
        var moPerc = (100 * multiOpenings) / sent;

        formatStats['sent'] = sent;
        formatStats['delivered'] = { perc: dPerc, num: delivered };
        formatStats['bounced'] = { "total": { perc: bPerc, num: bounced }, "softBounced": { perc: sbPerc, num: softBounced }, "hardBounced": { perc: hbPerc, num: hardBounced } }
        formatStats['opened'] = { "total": { perc: toPerc, num: totalOpenings }, "singleOpened": { perc: soPerc, num: singleOpenings }, "multiOpened": { perc: moPerc, num: multiOpenings } }

        return formatStats;
    }

    var formatCampaign = campaign => {
        var stats = campaign.stats[campaign["stats"].length - 1];
        var sent = stats["sent"];
        var ePerc = (100 * stats.delivered) / sent;
        var rPerc = (100 * (stats.hardBounces + stats.softBounces)) / sent;
        var lPerc = (100 * stats.uniqueViews) / sent;
        var entregados = { data: [ePerc] };
        var rebotados = { data: [rPerc] };
        var leidos = { data: [lPerc] };
        let date = new Date(campaign.creationDate);

        campaign['formatDate'] = date.getDate() + "-" + date.getMonth() + "-" + date.getFullYear();
        campaign["dataset"] = {
            datasets: [{
                data: [ePerc, lPerc, rPerc],
                backgroundColor: ['#A2052A', '#2e3d44', '#808d93']
            }],
            labels: [labels.lblDelivered, labels.lblOpened, labels.lblBounced]
        };
        campaign['formatStats'] = formatCampaignStats(stats);
        campaign["statsForCard"] = {
            "s": sent,
            "e": { num: stats.delivered, perc: ePerc },
            "r": { num: stats.hardBounces + stats.softBounces, perc: rPerc },
            "a": { num: stats.uniqueViews, perc: lPerc },
        }
        campaign['timelineDataset'] = createDataSet(campaign.stats)

        return campaign
    }


    if (props.campaign.status == "sent") {
        var campaign = formatCampaign(props.campaign);
    }
    else {
        var campaign = props.campaign;
    }


    var chartOptions = {
        legend: {
            display: true,
            position: "bottom",
            labels: {
                boxWidth: 20
            }
        },
        scales: {
            yAxes: [
                {
                    gridLines: {
                        drawBorder: false,
                        color: "rgba(29,140,248,0.0)",
                        zeroLineColor: "transparent"
                    },
                    ticks: {
                        fontColor: "#fff",
                    }
                }
            ],
            xAxes: [
                {
                    gridLines: {
                        drawBorder: false,
                        color: "rgba(29,140,248,0.0)",
                        zeroLineColor: "transparent"
                    },
                    ticks: {
                        fontColor: "#fff",
                        fontSize: 8.5
                    }
                }
            ],
        },
        tooltips: {
            callbacks: {
                title: function (tooltipItem, data) {
                    var indice = tooltipItem.index;
                    var dat = data.datasets[0].data[indice] + "%";
                    return data['labels'][tooltipItem[0]['index']];;
                },
                label: function (tooltipItem, data) {
                    var indice = tooltipItem.index;
                    var dat = data.datasets[0].data[indice] + "%";
                    return dat;
                }
            }
        }
    }

    return (
        <div className="campaign-card">
            <div className="campaignTitle">
                <h4>{campaign.name}</h4>
                {campaign.status == "sent" ?
                    <>
                        <p>{labels.lblSentEmails}: {campaign.statsForCard.s}</p>
                        <small>{labels.lblDate + ": " + new Date(campaign.creationDate).toLocaleDateString()}</small>
                    </>
                    :
                    ""
                }
            </div>

            {campaign.status == "sent" ?
                <div className="sent">
                    {campaign.status == 'sent' ?
                        props.showAs == "data" ?
                            <div className="list">
                                <div className="entregados">
                                    <h6>{labels['lblDelivered']}</h6>
                                    <h4>{campaign.statsForCard.e.perc}%</h4>
                                    <small>{campaign.statsForCard.e.num} {labels['lblEmails']}</small>
                                </div>
                                <div className="abre">
                                    <div className="abiertos">
                                        <h6>{labels['lblOpened']}</h6>
                                        <h4>{campaign.statsForCard.a.perc}%</h4>
                                        <small>{campaign.statsForCard.a.num} {labels['lblEmails']}</small>
                                    </div>
                                    <div className="rebotados">
                                        <h6>{labels['lblBounced']}</h6>
                                        <h4>{campaign.statsForCard.r.perc}%</h4>
                                        <small>{campaign.statsForCard.r.num} {labels['lblEmails']}</small>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="stats">
                                <Pie
                                    data={campaign.dataset}
                                    options={chartOptions}
                                />
                            </div>
                        :
                        <div className="inProcess">
                            <i className="ti-settings"></i>
                            <p>{labels['lblCampaignDraftMessage']}</p>
                        </div>
                    }


                    <div className="detailButton">
                        <button onClick={() => { props.changeScreen({ screen: 'detail', campaignDetail: campaign }) }}> {labels['lblBtnDetailCampaign']}</button>
                    </div >

                </div>
                :
                <div className="inProcess">
                    <i className="ti-settings"></i>
                    <p> Campaña está siendo procesada</p>
                </div>
            }
        </div >
    )
}

var SmsCampaignCard = props => {

    // var campaign = props.campaign;
    var labels = SmsCampaignCardLang(props.lang);
    const [campaign, setCampaign] = useState(props.campaign);

    var refreshCampaignData = async (campaign) => {
        var cacheId = "RefreshCampaign-" + campaign["ID-cortteza"];
        var data = await props.askFor('campaigns', "refreshsmscampaign", [], false, { campaignId: campaign['ID-cortteza'] }, cacheId);
        if (data != null) {
            campaign.stats = data.newStats;
            campaign.smsCount = data.count;
            var c = Object.assign({}, campaign);
            setCampaign(c)
        }
    }

    return (
        <div className="campaign-card">
            <div className="campaignTitle">
                <h4>{campaign.name}</h4>
                {campaign.status == "sent" ?
                    <small><b>{labels.lblDateSent + ": " + new Date(campaign.creationDate).toLocaleDateString()}</b></small>
                    :
                    campaign.status == "pending" ?
                        <small><b>{labels.lblDateNotSentYet + ": " + new Date(campaign.sentDate).toLocaleDateString()}</b></small>
                        :
                        <small><b>{labels.lblDateSent + ": Sin definir"}</b></small>
                }

            </div>

            {campaign.status == "sent" ?
                <div className="mainPanel">
                    <div className="smsSent">
                        <span><NumberFormat displayType="text" value={campaign.smsCount || 0} thousandSeparator={true} /></span>
                        <p>{labels.lblSentSMS}</p>
                    </div>

                    <div className="detailButton">
                        <button onClick={() => { props.changeScreen({ screen: 'detail', campaignDetail: campaign }) }}> {labels['lblBtnDetailCampaign']}</button>
                    </div >

                </div>
                :
                campaign.status == "pending" ?
                    <div className="mainPanel">
                        <div className="smsSent">
                            <span><i className="ti-calendar"></i></span>
                            <p>{labels.lblPending}</p>
                        </div>

                        <div className="detailButton">
                            <button onClick={() => { props.changeScreen({ screen: 'detail', campaignDetail: campaign }) }}> {labels['lblBtnDetailCampaign']}</button>
                        </div >
                    </div>
                    :
                    <div className="mainPanel">
                        <div className="smsSent">
                            <span><i className="ti-settings"></i></span>
                            <p>{labels.lblNotSentYet}</p>
                        </div>

                        <div className="detailButton">
                            <button onClick={() => { props.changeScreen({ screen: 'detail', campaignDetail: campaign }) }}> {labels['lblSendCampaign']}</button>
                        </div >
                    </div>
            }
        </div >
    )
}


class WrapedCampaigns extends React.Component {
    constructor(props) {
        super(props)
        this._isMounted = false;
        this.labels = CampaignsLang(props.lang)
        this.state = {
            screen: "list", // list | create | detail | client
            body: { status: null, offset: 0, limit: 8, textSearch: null, sortType: null },
            //Data
            campaignList: null,
            campaignDetail: null,
            clientId: null,
            //Dropdowns
            typeDropdownVal: "Email", //Email || Sms
            typeDropdownIcon: <EmailIcon className="typeIcon" />,
            typeDropdownOpen: false,
            sortDropdownVal: this.labels.lblSortByDate,
            sortDropdownOpen: false,
            statusDropdownVal: this.labels.lblCampaignStatusAll,
            statusDropdownOpen: false,
            cardViewType: "data", // data | chart
            creationCancel: false,
        };
        this.changeScreen = this.changeScreen.bind(this);
        this.getMonth = this.getMonth.bind(this);
    }

    //0. REACT LIFECYCLE
    async componentDidMount() {
        this._isMounted = true;
        try {
            let cacheId = 'GetCampaigns-' + this.props.bucket.bucketId + "-" + this.state.body.status;
            let data = await this.props.askFor('campaigns', "getCampaigns", [], false, this.state.body, cacheId);
            if (data != null) {
                this.setState({ campaignList: data.campaigns })
            }

        }
        catch (err) {
            console.log(err)
        }

    }

    async componentDidUpdate(prevProps, prevState) {
        if ((prevState.screen != this.state.screen) && (prevState.screen == "create") && (this.state.screen == "list") && (prevState.typeDropdownVal == this.state.typeDropdownVal) && (this.state.typeDropdownVal == "Email")) {
            if (this._isMounted) {
                if (this.state.creationCancel == false) {
                    this.setState({ campaignList: null, campaignDetail: null })
                    let cacheId = 'GetCampaigns-' + this.props.bucket.bucketId + "-" + this.state.body.status + "-" + this.state.body.textSearch + "-" + this.state.body.sortType + "-" + Math.round(Math.random() * 1000);
                    let data = await this.props.askFor('campaigns', "getCampaigns", [], false, this.state.body, cacheId);
                    if (data != null) {
                        this.setState(state => { return ({ campaignList: data.campaigns, campaignDetail: null }) })
                    }
                }
            }
        }

        if ((prevState.screen != this.state.screen) && (prevState.screen == "create") && (this.state.screen == "list") && (prevState.typeDropdownVal == this.state.typeDropdownVal) && (this.state.typeDropdownVal == "Sms")) {
            if (this._isMounted) {
                if (this.state.creationCancel == false) {
                    this.setState({ campaignList: null, campaignDetail: null })
                    let cacheId = 'GetSmsCampaigns-' + this.props.bucket.bucketId + "-" + this.state.body.status + "-" + this.state.body.textSearch + "-" + this.state.body.sortType + Math.round(Math.random() * 1000);
                    let data = await this.props.askFor('campaigns', "getsmscampaigns", [], false, this.state.body, cacheId);
                    if (data != null) {
                        this.setState(state => { return ({ campaignList: data.campaigns, campaignDetail: null }) })
                    }

                }

            }
        }

        if ((prevState.screen == this.state.screen) && (prevState.typeDropdownVal != this.state.typeDropdownVal)) {
            if (this._isMounted) {
                this.setState({ campaignList: null, campaignDetail: null })
                let data = null;
                let cacheId = null;
                switch (this.state.typeDropdownVal) {
                    case "Email":
                        cacheId = 'GetCampaigns-' + this.props.bucket.bucketId + "-" + this.state.body.status + "-" + this.state.body.textSearch + "-" + this.state.body.sortType;
                        data = await this.props.askFor('campaigns', "getCampaigns", [], false, this.state.body, cacheId);
                        ; break;
                    case "Sms":
                        cacheId = 'GetSmsCampaigns-' + this.props.bucket.bucketId + "-" + this.state.body.status + "-" + this.state.body.textSearch + "-" + this.state.body.sortType + Math.round(Math.random() * 1000);
                        data = await this.props.askFor('campaigns', "getsmscampaigns", [], false, this.state.body, cacheId);
                        ; break;
                }
                if (data != null) {
                    this.setState(state => { return ({ campaignList: data.campaigns, campaignDetail: null }) })
                }

            }
        }

        if (prevState.sortDropdownVal != this.state.sortDropdownVal) {
            if (this._isMounted) {
                let data = null;
                let cacheId = null;
                switch (this.state.typeDropdownVal) {
                    case "Email":
                        this.setState({ campaignList: null, campaignDetail: null })
                        cacheId = 'GetCampaigns-' + this.props.bucket.bucketId + "-" + this.state.body.status + "-" + this.state.body.textSearch + "-" + this.state.body.sortType;
                        data = await this.props.askFor('campaigns', "getCampaigns", [], false, this.state.body, cacheId);
                        console.log('Data:', data)
                        this.setState(state => { return ({ campaignList: data.campaigns, campaignDetail: null }) })
                            ; break;
                    case "Sms":
                        this.setState(state => { return ({ campaignList: null, campaignDetail: null, typeDropdownVal: state.typeDropdownVal }) })
                        cacheId = 'GetSmsCampaigns-' + this.props.bucket.bucketId + "-" + this.state.body.status + "-" + this.state.body.textSearch + "-" + this.state.body.sortType + Math.round(Math.random() * 1000);
                        data = await this.props.askFor('campaigns', "getsmscampaigns", [], false, this.state.body, cacheId);

                        ; break;
                }
                if (data != null) {
                    this.setState(state => { return ({ campaignList: data.campaigns, campaignDetail: null }) })
                }
            }

        }

        if (prevState.statusDropdownVal != this.state.statusDropdownVal) {
            if (this._isMounted) {
                let data = null;
                let cacheId = null;
                switch (this.state.typeDropdownVal) {
                    case "Email":
                        this.setState({ campaignList: null, campaignDetail: null })
                        cacheId = 'GetCampaigns-' + this.props.bucket.bucketId + "-" + this.state.body.status + "-" + this.state.body.textSearch + "-" + this.state.body.sortType;
                        data = await this.props.askFor('campaigns', "getCampaigns", [], false, this.state.body, cacheId);
                        ; break;
                    case "Sms":
                        this.setState(state => { return ({ campaignList: null, campaignDetail: null, typeDropdownVal: state.typeDropdownVal }) })
                        cacheId = 'GetSmsCampaigns-' + this.props.bucket.bucketId + "-" + this.state.body.status + "-" + this.state.body.textSearch + "-" + this.state.body.sortType + Math.round(Math.random() * 1000);
                        let data = await this.props.askFor('campaigns', "getsmscampaigns", [], false, this.state.body, cacheId);
                        ; break;
                }
                if (data != null) {
                    this.setState(state => { return ({ campaignList: data.campaigns, campaignDetail: null }) })
                }
            }

        }

        if (prevProps.lang != this.props.lang) {
            if (this._isMounted) {
                this.labels = CampaignsLang(this.props.lang)
                this.forceUpdate()
            }
        }

        ReactTooltip.rebuild();
    }


    //1. DROPDOWNS & VISUALIZATION STYLE
    toggleTypeDropdown = () => {
        if (this._isMounted) {
            this.setState(state => {
                return ({ typeDropdownOpen: !state.typeDropdownOpen })
            })
        }
    }

    changeTypeDropdownValue = (val) => {
        if (val == undefined) { val = "Email" }
        if (this._isMounted) {
            var icon = null;
            switch (val) {
                case "Email": icon = <EmailIcon className="typeIcon" />; break;
                case "Sms": icon = <SmsIcon className="typeIcon" />; break;
            }
            this.setState(state => { return ({ typeDropdownVal: val, typeDropdownIcon: icon }) })
        }
    }

    toggleSortDropdown = () => {
        if (this._isMounted) {
            this.setState(state => {
                return ({ sortDropdownOpen: !state.sortDropdownOpen })
            })
        }
    }

    changeSortDropdownValue = (label, value) => {
        if (label == undefined) { label = this.labels.lblSortByDate }
        if (value == undefined) { value = "" }
        if (this._isMounted) {
            this.setState(state => {
                let body = state.body;
                body.sortType = value
                return ({ sortDropdownVal: label, body: body })
            })
        }
    }

    toggleStatusDropdown = () => {
        if (this._isMounted) {
            this.setState(state => {
                return ({ statusDropdownOpen: !state.statusDropdownOpen })
            })
        }
    }

    changeStatusDropdownValue = (label, value) => {
        if (label == undefined) { label = this.labels.lblCampaignStatusAll }
        if (value == undefined) { value = null }
        if (this._isMounted) {
            this.setState(state => {
                let body = state.body;
                body.status = value
                return (state => { return ({ statusDropdownVal: label, body: body }) })
            })
        }
    }

    changeCardViewType = val => {
        if (val == undefined) { val = "data" }
        if (this._isMounted) {
            this.setState({ cardViewType: val })
        }
    }

    // 2. PROGRESS BAR FUNCTIONS
    getMonth = () => {
        var monthNames = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
            "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
        ];

        var d = new Date();
        return monthNames[d.getMonth()];
    }

    getCurrentSentEmailNumber = () => {
        if (this.state.campaignList == null) { return 0 }

        var count = 0;
        var today = new Date();

        this.state.campaignList.forEach(c => {
            var d = new Date(c.creationDate);
            if ((d.getFullYear() == today.getFullYear()) && (d.getMonth() == today.getMonth())) {
                if (c['status'] == 'sent') {
                    count += c.stats[c.stats.length - 1]['sent'];
                }
            }
        })

        return count;
    }

    // 3. NAVIGATION

    changeScreen(values) {
        var screen = values.screen;
        var campaignDetail = values.campaignDetail;
        var clientId = values.clientId;
        var creationCancel = values.creationCancel || false;
        var type = this.state.typeDropdownVal;
        if (screen == undefined) { screen = 'list' }
        if (campaignDetail == undefined) { campaignDetail = null }
        if (clientId == undefined) { clientId = null }
        if (this._isMounted) {
            this.setState((state) => {
                return ({
                    screen: screen,
                    campaignDetail: campaignDetail,
                    clientId: clientId,
                    creationCancel: creationCancel,
                    typeDropdownVal: type
                })
            });
        }
    }

    // 4. Format Campaign Data

    createDataSet = stats => {
        var labels = CampaignCardLang(this.props.lang);
        let sent = stats[stats.length - 1].sent;
        let s = [];
        if (stats.length > 6) {
            s = stats.slice(Math.max(stats.length - 6, 0));
        }
        else {
            s = stats;
        }

        let l = []
        let dataset = { data: [], label: labels['lblOpenedEmails'], backgroundColor: "rgba(175, 144, 68, 0.3)" }
        let acumulator = 0;

        s.forEach((stat, index) => {
            if (index % 2 == 0) {
                dataset.data.push(acumulator)
                acumulator = 0;
                if (index == 0) { l.push('0h') }
                if (index == 2) { l.push('24h') }
                if (index == 4) { l.push('48h') }
                if (index == 6) { l.push('72h') }

            }
            else {
                let perc = (100 * stat.uniqueViews) / sent;
                acumulator += perc;
            }
        })
        return { datasets: [dataset], labels: l };
    }

    formatCampaignStats = stats => {
        let formatStats = {};

        var sent = stats["sent"];
        let delivered = stats.delivered;
        let softBounced = stats.softBounces;
        let hardBounced = stats.hardBounces;
        let bounced = stats.hardBounces + stats.softBounces;
        let totalOpenings = stats.viewed;
        let singleOpenings = stats.uniqueViews;
        let multiOpenings = totalOpenings;
        var dPerc = (100 * delivered) / sent;
        var bPerc = (100 * bounced) / sent;
        var sbPerc = (100 * softBounced) / sent;
        var hbPerc = (100 * hardBounced) / sent;
        var toPerc = (100 * singleOpenings) / sent;
        var soPerc = (100 * singleOpenings) / sent;
        var moPerc = (100 * multiOpenings) / sent;

        formatStats['sent'] = sent;
        formatStats['delivered'] = { perc: dPerc, num: delivered };
        formatStats['bounced'] = { "total": { perc: bPerc, num: bounced }, "softBounced": { perc: sbPerc, num: softBounced }, "hardBounced": { perc: hbPerc, num: hardBounced } }
        formatStats['opened'] = { "total": { perc: toPerc, num: totalOpenings }, "singleOpened": { perc: soPerc, num: singleOpenings }, "multiOpened": { perc: moPerc, num: multiOpenings } }

        return formatStats;
    }

    formatCampaign = campaign => {
        // console.log('Campaign:', campaign)
        var labels = CampaignCardLang(this.props.lang);
        var stats = campaign.stats[campaign["stats"].length - 1];
        var sent = stats["sent"];
        var ePerc = (100 * stats.delivered) / sent;
        var rPerc = (100 * (stats.hardBounces + stats.softBounces)) / sent;
        var lPerc = (100 * stats.uniqueViews) / sent;
        var entregados = { data: [ePerc] };
        var rebotados = { data: [rPerc] };
        var leidos = { data: [lPerc] };
        let date = new Date(campaign.creationDate);

        campaign['formatDate'] = date.getDate() + "-" + date.getMonth() + "-" + date.getFullYear();
        campaign["dataset"] = {
            datasets: [{
                data: [ePerc, lPerc, rPerc],
                backgroundColor: ['#A2052A', '#2e3d44', '#808d93']
            }],
            labels: [labels.lblDelivered, labels.lblOpened, labels.lblBounced]
        };
        campaign['formatStats'] = this.formatCampaignStats(stats);
        campaign["statsForCard"] = {
            "s": sent,
            "e": { num: stats.delivered, perc: ePerc },
            "r": { num: stats.hardBounces + stats.softBounces, perc: rPerc },
            "a": { num: stats.uniqueViews, perc: lPerc },
        }
        campaign['timelineDataset'] = this.createDataSet(campaign.stats)

        return campaign
    }



    // 4. PRE-RENDER FUNCTIOSNS

    formatStatus = status => {
        var s = "";
        switch (status) {
            case 'draft': s = "Borrador"; break;
            case 'sent': s = "Enviada"; break;
            default: ; break;
        }

        return s;
    }

    getMainScreen = () => {
        switch (this.state.screen) {
            case "list": return (<Container fluid className="campaignList">
                <Row className="titleRow">
                    <h1>{this.labels.lblTitle}</h1>
                    <Button onClick={() => { this.changeScreen({ screen: 'create' }) }}><Icon4 /> {this.labels.lblCreateCampaign}</Button>
                </Row>
                <Row className="configBar">
                    <Col className="dropdownsCol" lg="9" md="9" sm="12">
                        <span>{this.labels.lblCampaignStatus}:</span>
                        <Dropdown isOpen={this.state.statusDropdownOpen} toggle={this.toggleStatusDropdown}>
                            <DropdownToggle>
                                <span>{this.state.statusDropdownVal} | <i className="ti-angle-down" /></span>
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem onClick={() => { this.changeStatusDropdownValue() }}>{this.labels.lblCampaignStatusAll}</DropdownItem>
                                <DropdownItem onClick={() => { this.changeStatusDropdownValue(this.labels.lblCampaignStatusSent, 'sent') }}>{this.labels.lblCampaignStatusSent}</DropdownItem>
                                <DropdownItem onClick={() => { this.changeStatusDropdownValue(this.labels.lblCampaignStatusDraft, 'draft') }}>{this.labels.lblCampaignStatusDraft}</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>

                        <span>{this.labels.lblSort}:</span>
                        <Dropdown isOpen={this.state.sortDropdownOpen} toggle={this.toggleSortDropdown}>
                            <DropdownToggle>
                                <span>{this.state.sortDropdownVal} | <i className="ti-angle-down" /></span>
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem onClick={() => { this.changeSortDropdownValue(this.labels.lblSortByDate, "") }}>{this.labels.lblSortByDate}</DropdownItem>
                                <DropdownItem onClick={() => { this.changeSortDropdownValue(this.labels.lblSortByName, "name") }}>{this.labels.lblSortByName}</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>

                        <span>{this.labels.lblView}:</span>
                        <Dropdown isOpen={this.state.typeDropdownOpen} toggle={this.toggleTypeDropdown}>
                            <DropdownToggle>
                                <span>{this.state.typeDropdownIcon}  {this.state.typeDropdownVal} | <i className="ti-angle-down" /></span>
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem onClick={() => { this.changeTypeDropdownValue('Email') }}><EmailIcon className="typeIcon" /> Email</DropdownItem>
                                {this.props.campaignConfiguration.sms.enabled ?
                                    <DropdownItem onClick={() => { this.changeTypeDropdownValue('Sms') }}><SmsIcon className="typeIcon" /> Sms</DropdownItem>
                                    : ""}

                            </DropdownMenu>
                        </Dropdown>
                    </Col>

                    <Col hidden={this.state.typeDropdownVal != "Email"} className="progressCol" lg="3" md="3" sm="12">
                        <p className="text-center cuotaEmail">Cuota de correos para {this.getMonth()}: <b>{this.props.campaignConfiguration.email.month_limit}</b></p>
                        <Progress color="success" max={this.props.campaignConfiguration.email.month_limit} value={this.getCurrentSentEmailNumber()}>{this.getCurrentSentEmailNumber()}</Progress>
                    </Col>

                    {/* this.state.typeDropdownVal === 'Email' ?
                        <Col className="cardStyleSelectionCol" lg="3" md="3" sm="12">
                            <span onClick={() => { this.changeCardViewType('data') }}><i className={classnames({ "ti-menu": true, "active": this.state.cardViewType == "data" })} /></span>
                            <span onClick={() => { this.changeCardViewType('chart') }}><i className={classnames({ "ti-pie-chart": true, "active": this.state.cardViewType == "chart" })} /></span>
                        </Col> : "" */}
                </Row>
                <Row className="campaign-container">
                    <ReactTooltip id="campaignTableTooltip" place="top" textColor="#fff" backgroundColor="#4a92d4" />
                    {this.state.campaignList != null ?
                        this.state.campaignList.length > 0 ?
                            <Table responsive striped bordered className="campaignTable">
                                <thead>
                                    <tr>
                                        <th className="text-center">Nombre</th>
                                        <th className="text-center">Fecha de Creación</th>
                                        <th className="text-center">Status</th>
                                        {this.state.typeDropdownVal == 'Email' ? <th className="text-center">Estadisticas</th> : ""}

                                        <th className="text-center">Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.campaignList.map((campaign, key) => {
                                            if (campaign.subject != undefined) {
                                                var c;
                                                if (campaign.status == 'sent') {
                                                    c = this.formatCampaign(campaign);
                                                }
                                                else {
                                                    c = campaign;
                                                }

                                                return (
                                                    <tr key={key}>
                                                        <td className="text-center">{campaign.name}</td>
                                                        <td className="text-center">{new Date(campaign.creationDate).toLocaleString('es',{dateStyle:'short'})}</td>
                                                        <td className="text-center">{this.formatStatus(campaign.status)}</td>
                                                        {campaign.status == 'sent' ?
                                                            <td className="text-center">
                                                                <Table striped={false} responsive borderless>
                                                                    <tbody>
                                                                        <tr><td>Enviados: <b>{c.formatStats.sent}</b></td></tr>
                                                                        <tr><td>Entregados: <b>{c.formatStats.delivered.perc + '%'}</b> </td></tr>
                                                                    </tbody>
                                                                </Table>
                                                            </td>
                                                            :
                                                            <td className="text-center">Sin stats</td>
                                                        }
                                                        <td className="text-center">
                                                            {campaign.status == 'sent' ?
                                                                <button data-for="campaignTableTooltip" className="" data-tip={"Ver Detalle"} onClick={() => { this.changeScreen({ screen: 'detail', campaignDetail: c }) }}>
                                                                    <i className="fa fa-eye" />
                                                                </button>
                                                                :
                                                                ""
                                                            }
                                                        </td>
                                                    </tr>
                                                )
                                            }

                                            if (campaign.text != undefined) {
                                                return (
                                                    <tr key={key}>
                                                        <td className="text-center">{campaign.name}</td>
                                                        <td className="text-center">{new Date(campaign.creationDate).toLocaleString()}</td>
                                                        <td className="text-center">{this.formatStatus(campaign.status)}</td>
                                                        <td className="text-center">
                                                            <button data-for="campaignTableTooltip" className="" data-tip={"Ver Detalle"} onClick={() => { this.changeScreen({ screen: 'detail', campaignDetail: campaign }) }}>
                                                                <i className="fa fa-eye" />
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )
                                            }

                                        })
                                    }
                                </tbody>
                            </Table>
                            :
                            <div className="noCampaignMessage">
                                <h3>De momento no cuentas con campañas creadas</h3>
                                <p>Crea una campaña través de nuestros distintos canales de salida y aprovecha al máximo el valor de tus segmentos </p>
                            </div>
                        :
                        <div className="loading">
                            {this.props.loadingSpinner}
                            <h2>{this.labels.lblLoading}</h2>
                        </div>
                    }
                </Row>
            </Container>
            ); break;
            case "create": return (
                <CampaignCreator
                    lang={this.props.lang}
                    changeScreen={this.changeScreen}
                    askFor={this.props.askFor}
                    formDataPetition={this.props.formDataPetition}
                    bucket={this.props.bucket}
                    campaignConfig={this.props.campaignConfiguration}
                    loadingSpinner={this.props.loadingSpinner}
                    currentEmailCuota={this.getCurrentSentEmailNumber()}
                />
            ); break;
            case "detail":
                switch (this.state.typeDropdownVal) {
                    case "Email": return (
                        <CampaignDetail
                            campaign={this.state.campaignDetail}
                            lang={this.props.lang}
                            changeScreen={this.changeScreen}
                            askFor={this.props.askFor}
                            bucket={this.props.bucket}
                        />
                    ); break;
                    case "Sms": return (
                        <SmsCampaignDetail
                            campaign={this.state.campaignDetail}
                            lang={this.props.lang}
                            changeScreen={this.changeScreen}
                            askFor={this.props.askFor}
                            bucket={this.props.bucket}
                            loadingSpinner={this.props.loadingSpinner}
                        />
                    ); break;
                }
                ; break;
            case "client": return (
                <ClientDetail
                    clientId={this.state.clientId}
                    askFor={this.props.askFor}
                    currencySymbol={this.props.currencySymbol}
                    lang={this.props.lang}
                    loadingSpinner={this.props.loadingSpinner}
                    backFunction={this.changeScreen}
                    backFunctionValues={{ screen: "detail", campaignDetail: this.state.campaignDetail }}
                    theme={this.props.bucket.theme}
                    hideRedeem={this.props.isSectionHidden('clientdetails_redeemed_points_permonth')}
                    isFieldHidden= {this.props.isFieldHidden}
                    getBucketLabels={this.props.getBucketLabels}
                />
            )
                ; break;
        }
    }


    //Z. Render

    render() {
        return (
            <div className="content">
                {this.getMainScreen()}
            </div>
        )
    }

}





var Campaigns = p => {
    return (<>
        <FetchCmp
            render={props =>
                <WrapedCampaigns
                    askFor={props.askFor}
                    bucket={props.bucket}
                    lang={props.lang}
                    loadingSpinner={props.loadingSpinner}
                    currencySymbol={props.bucket.currencySymbol}
                    formDataPetition={props.formDataPetition}
                    campaignConfiguration={props.bucket.campaignsConfiguration}
                    isSectionHidden={props.isSectionHidden}
                    isFieldHidden= {props.isFieldHidden}
                    getBucketLabels={props.getLabels}
                />}
        />
    </>)
}

export default Campaigns;