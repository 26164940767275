import React from "react";
import ReactDOM from 'react-dom'
import { Redirect } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { SegmentDetailLang, crmDropdownLang, MyExportsCreatorLang } from '../../lang'
import classnames from 'classnames';
import { Line, Doughnut, } from "react-chartjs-2";
import TreeMap from "react-d3-treemap";
import ReactTooltip from "react-tooltip";
// import TreeMap from "react-d3-treemap";
// import "react-d3-treemap/dist/react.d3.treemap.css";
import {
    Card, CardBody,
    Container, Row, Col,
    Nav, NavItem, NavLink,
    TabContent, TabPane,
    Button,
    Table,
    Dropdown, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown,
    FormGroup, Input
} from "reactstrap";
import NumberFormat from "react-number-format";
import Filtros from "./Filtros"


// A. SUBCOMPONENTS --------------------------------------------------------------------------------------------------------------
//Mini-componentes que forman parte del componente principal. Realizan sus propias peticiones a distintos servicios para mostrar datos.

var SummaryCard = props => {
    var labels = props.labels;
    var data = props.data;
    var color = props.color;

    return (<>
        <Card style={{ verticalAlign: 'center' }}>
            <CardBody className="summaryDetail">
                <Row className="text-center">
                    <Col>
                        <h2 style={{ color: color }}><NumberFormat value={data.count || 0} displayType={'text'} thousandSeparator={true} decimalScale={0} /></h2>
                        <small style={{ color: color }}>{labels.lblPercFromCount}: {data.perc_count_fromtotal || 0}%</small>
                        <p>{labels.lblClientCount}</p>
                    </Col>
                    <Col>
                        <h2 style={{ color: color }}><NumberFormat value={data.tiquete_promedio || 0} prefix={props.currencySymbol} displayType={'text'} thousandSeparator={true} decimalScale={0} /></h2>
                        <small style={{ color: color }}>{labels.lblPurchases}: {data.txcount || 0}</small>
                        <p>{labels.lblAvgTicket} {labels.lblPeriod}</p>
                    </Col>
                    <Col>
                        <h2 style={{ color: color }}><NumberFormat value={data.avgVisitsPerYear || 0} displayType={'text'} thousandSeparator={true} decimalScale={0} /></h2>
                        <small style={{ color: color }}>{labels.lblAvg}</small>
                        <p>{labels.lblVisitsYear}</p>
                    </Col>
                </Row>
            </CardBody>
        </Card>

    </>)
}

class PointChart extends React.Component {
    constructor(props) {
        super(props)
        this._isMounted = false;
        this.state = { data: null, total: null }
        this.labels = this.props.labels;
        switch (props.type) {
            case 'redem': this.subtitle = props.labels.lblChartRedemptionsSubTitle; break;
            case 'points': this.subtitle = props.labels.lblChartPointsSummarySubTitle; break;

            default:
                break;
        }

    }
    //Props: label , totalPoints, chartData , chartOptions, empty.



    //0.REACT LIFECYCLE FUNCTIONS (API CALLS)-------------------------------------------------------------------------------------------------------------------------
    async componentDidMount() {
        this._isMounted = true;
        var points = 0;
        let res = null;
        var dataReady = null
        var cacheId = "";
        try {
            switch (this.props.type) {
                case 'points':
                    cacheId = 'DetailSegmentPoints' + this.props.segment;
                    res = await this.props.askFor('segments', 'segments/getpurchasesummarybysegment', this.props.body.filters, false, this.props.body, cacheId);
                    if (res != null) {
                        res.map(obj => { points += obj.points; });
                        dataReady = this.readyChartData(res, this.props.label, "points");
                    }
                    break;

                case 'redem':
                    cacheId = 'DetailSegmentRedeem' + this.props.segment;
                    res = await this.props.askFor('segments', 'segments/getredemptionssummarybysegment', this.props.body.filters, false, this.props.body, cacheId);
                    if (res != null) {
                        res.map(obj => { points += obj.ptssum; });
                        dataReady = this.readyChartData(res, this.props.label, "ptssum");
                    }
                    break;

                default:
                    break;
            }

            if (this._isMounted) {
                this.setState({ total: points, data: dataReady });
            }
        }
        catch (err) {
            console.log(err);
        }
    }

    async componentDidUpdate(prevProps) {
        if (prevProps.segment != this.props.segment) {
            if (this.props.empty === undefined) {
                var points = 0;
                let res = null;
                var dataReady = null;
                var cacheId = "";
                try {
                    if (this._isMounted) {
                        this.setState({ total: 0, data: null });
                    }
                    switch (this.props.type) {
                        case 'points':
                            cacheId = 'DetailSegmentPoints' + this.props.segment;
                            res = await this.props.askFor('segments', 'segments/getpurchasesummarybysegment', this.props.body.filters, false, this.props.body, cacheId);
                            if (res != null) {
                                res.map(obj => { points += obj.points; });
                                dataReady = this.readyChartData(res, this.props.label, "points");
                            }

                            break;

                        case 'redem':
                            cacheId = 'DetailSegmentRedeem' + this.props.segment;
                            res = await this.props.askFor('segments', 'segments/getredemptionssummarybysegment', this.props.body.filters, false, this.props.body, cacheId);
                            if (res != null) {
                                res.map(obj => { points += obj.ptssum; });
                                dataReady = this.readyChartData(res, this.props.label, "ptssum");
                            }
                            break;

                        default:
                            break;
                    }
                    if (this._isMounted) {
                        this.setState({ total: points, data: dataReady });
                    }
                } catch (err) {
                    console.log(err);
                }
            }

        }

        if (prevProps.labels != this.props.labels) {
            this.labels = this.props.labels;
            if (this._isMounted) {
                this.forceUpdate();
            }
        }

    }

    componentWillUnmount() {
        this._isMounted = false;
    }


    //1.PRE-RENDER FUNCTIONS ------------------------------------------------------------------------------------------------------------------------------------------------
    hexToRgb(hex) {
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16)
        } : null;
    }

    readyChartData = (data, label, yAxis) => {
        if (data === null || data === undefined) {
            return null;
        }

        var createMonthString = (element) => {
            let m = null;
            let year = element["month_num"].toString();
            let y = year.slice(0, 4);
            switch (element["month"]) {
                case "Enero": m = ['ENE', y]; break;
                case "Febrero": m = ['FEB', y]; break;
                case "Marzo": m = ['MAR', y]; break;
                case "Abril": m = ['ABR', y]; break;
                case "Mayo": m = ['MAY', y]; break;
                case "Junio": m = ['JUN', y]; break;
                case "Julio": m = ['JUL', y]; break;
                case "Agosto": m = ['AGO', y]; break;
                case "Septiembre": m = ['SEP', y]; break;
                case "Octubre": m = ['OCT', y]; break;
                case "Noviembre": m = ['NOV', y]; break;
                case "Diciembre": m = ['DEC', y]; break;
                default: m = ''; break;
            }

            return m;
        }


        return (canvas => {
            let ctx = canvas.getContext("2d");
            let color = this.hexToRgb(this.props.color);

            let gradientStroke = ctx.createLinearGradient(color.r, color.g, color.b, 100);

            gradientStroke.addColorStop(1, "rgba(" + color.r + "," + color.g + "," + color.b + ",0.6)");
            gradientStroke.addColorStop(0.4, "rgba(" + color.r + "," + color.g + "," + color.b + ",0.5)");
            gradientStroke.addColorStop(0, "rgba(" + color.r + "," + color.g + "," + color.b + ",0.4)"); //blue colors

            let labels = [];
            let chartData = []


            data.forEach(element => {
                if (element === null || element === undefined) {
                    return null;
                }
                else {
                    labels.push(createMonthString(element));
                    chartData.push(element[yAxis].toFixed(0));
                    //chartData.push(element['points'])
                    //chartData.push(element['ptssum'])

                }
            });

            return {
                labels: labels,
                datasets: [
                    {
                        label: label,
                        fill: true,
                        backgroundColor: gradientStroke,
                        borderColor: this.color,
                        borderWidth: 2,
                        borderDash: [],
                        borderDashOffset: 0.0,
                        pointBackgroundColor: this.props.color,
                        pointBorderColor: "rgba(0,0,0,0)",
                        pointHoverBackgroundColor: this.color,
                        pointBorderWidth: 20,
                        pointHoverRadius: 4,
                        pointHoverBorderWidth: 15,
                        pointRadius: 4,
                        data: chartData
                    }
                ]
            };
        })
    }

    render() {
        return (
            <Col lg={this.props.lg || "6"} md="12" sm="12" xs="12" className="pointChart text-center">
                <Card>
                    <CardBody>
                        <div className="text-left chartTitle">
                            <h6>{this.props.label}</h6>
                            <h6 className="subdata">{this.subtitle}: <NumberFormat value={this.state.total} displayType={'text'} thousandSeparator={true} decimalScale={2} /></h6>
                        </div>
                        <Row>
                            <Col md="12" lg='12'>
                                {this.state.data != null ?

                                    <div className="content text-center">
                                        <Line data={this.state.data} options={this.props.chartOptions} />
                                    </div>

                                    :
                                    <CardBody>
                                        {this.props.loadingSpinner}
                                        <p>{this.labels.lblLoading}</p>
                                    </CardBody>
                                }
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        )
    }
}

class GenderSummary extends React.Component {
    constructor(props) {
        super(props)
        this._isMounted = false;
        this.getCol = this.getCol.bind(this);
        this.state = { dataMen: null, dataWomen: null }
        //Styles----------------------------------------------------------------------------------------
        this.normalStyle = { fontSize: '18px', marginBottom: '10px' }
        this.paddingStyle = { fontSize: '18px', paddingBottom: '15px' }                                //
        //----------------------------------------------------------------------------------------------
        this.labels = this.props.labels;
        this.body = Object.assign({}, props.body);
        this.body.filters = this.changeFilters(this.body.filters);
    }


    async componentDidMount() {
        this._isMounted = true;
        try {
            let cacheId = "SegmentDetailGender" + this.props.segment;
            let gen = await this.props.askFor('segments', 'segments/getsummarybygender', this.props.filters, false, this.body, cacheId);
            if (gen != null) {
                var dataMen = null;
                var dataWomen = null;
                if (gen.length > 1) {
                    gen.map(gender => {
                        if (gender._id.GENERO === 'MUJER') {
                            dataWomen = gender;
                        }
                        else {
                            dataMen = gender;
                        }
                    })
                    let total = dataMen.count + dataWomen.count;
                    let wPerc = (dataWomen.count * 100) / total;
                    let mPerc = (dataMen.count * 100) / total;

                    let wAvgCalc = dataWomen.monto / dataWomen.txcount;
                    let mAvgCalc = dataMen.monto / dataMen.txcount;

                    let wAvgTicket = Math.round(wAvgCalc * 100) / 100;
                    let mAvgTicket = Math.round(mAvgCalc * 100) / 100;

                    dataMen = { count: dataMen.count, perc: mPerc, avgTicket: mAvgTicket }
                    dataWomen = { count: dataWomen.count, perc: wPerc, avgTicket: wAvgTicket }

                } else {
                    if (gen.length == 0) {
                        return;
                    }
                    switch (gen[0]._id.GENERO) {
                        case 'HOMBRE': dataMen = { count: gen[0].count, perc: 100, avgTicket: gen[0].tiquete_promedio }
                            dataWomen = { count: 0, perc: 0, avgTicket: 0 };

                            break;
                        case 'MUJER': dataMen = { count: 0, perc: 0, avgTicket: 0 }
                            dataWomen = { count: gen[0].count, perc: 100, avgTicket: gen[0].tiquete_promedio }

                            break;

                        default:
                            break;
                    }
                }
                if (this._isMounted) {
                    this.setState({ dataMen: dataMen, dataWomen: dataWomen });
                }
            }
        } catch (err) {
            console.log(err);
        }
    }

    async componentDidUpdate(prevProps) {
        if (prevProps.segment != this.props.segment || prevProps.filters != this.props.filters) {
            try {
                this.body = Object.assign({}, this.props.body);
                this.body.filters = this.changeFilters(this.body.filters);
                if (this._isMounted) { this.setState({ dataMen: null, dataWomen: null }); }
                let cacheId = "SegmentDetailGender" + this.props.segment;
                let gen = await this.props.askFor('segments', 'segments/getsummarybygender', this.props.filters, false, this.props.body, cacheId);
                if (gen != null) {
                    var dataMen = null;
                    var dataWomen = null;
                    if (gen.length > 1) {
                        gen.map(gender => {
                            if (gender._id.GENERO === 'MUJER') {
                                dataWomen = gender;
                            }
                            else {
                                dataMen = gender;
                            }
                        })
                        let total = dataMen.count + dataWomen.count;
                        let wPerc = (dataWomen.count * 100) / total;
                        let mPerc = (dataMen.count * 100) / total;

                        let wAvgCalc = dataWomen.monto / dataWomen.txcount;
                        let mAvgCalc = dataMen.monto / dataMen.txcount;

                        let wAvgTicket = Math.round(wAvgCalc * 100) / 100;
                        let mAvgTicket = Math.round(mAvgCalc * 100) / 100;

                        dataMen = { count: dataMen.count, perc: mPerc, avgTicket: mAvgTicket }
                        dataWomen = { count: dataWomen.count, perc: wPerc, avgTicket: wAvgTicket }

                    } else {
                        if (gen.length === 0) {
                            return;
                        }
                        switch (gen[0]._id.GENERO) {
                            case 'HOMBRE':
                                let mAvgCalc = gen[0].monto / gen[0].txcount;
                                let mAvgTicket = Math.round(mAvgCalc * 100) / 100;
                                dataMen = { count: gen[0].count, perc: 100, avgTicket: mAvgTicket }
                                dataWomen = { count: 0, perc: 0, avgTicket: 0 };

                                break;
                            case 'MUJER':
                                let wAvgCalc = gen[0].monto / gen[0].txcount;
                                let wAvgTicket = Math.round(wAvgCalc * 100) / 100;
                                dataMen = { count: 0, perc: 0, avgTicket: 0 }
                                dataWomen = { count: gen[0].count, perc: 100, avgTicket: wAvgTicket }

                                break;

                            default:
                                break;
                        }
                    }

                    if (this._isMounted) {
                        this.setState({ dataMen: dataMen, dataWomen: dataWomen });
                    }
                }

            } catch (err) {
                console.log(err);
            }


        }

        if (prevProps.labels != this.props.labels) {
            this.labels = this.props.labels;
            if (this._isMounted) {
                this.forceUpdate();
            }
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    changeFilters(filters) {
        let f = [];

        filters.forEach(fil => {
            let clone = Object.assign({}, fil)
            f.push(clone);
        })

        f.map(fi => {
            fi.fieldname = fi.productTableField;
        })

        return f;
    }

    getCol = (icon, gender, count, perc, avgTicket) => {
        return (<Col className="genderCard text-center">
            <Card>
                <CardBody>
                    <p className="icon" style={{ color: this.props.color }}>{icon}</p>
                    <p className="mainData" style={{ color: this.props.color }}>
                        <NumberFormat displayType="text" value={count} thousandSeparator={","} /> {gender}
                    </p>
                    <hr />
                    <p> <NumberFormat value={perc} decimalScale={2} displayType={'text'} />%</p>
                    <p className="avgTicket">
                        {this.labels.lblAvgTicket}
                        <NumberFormat
                            value={avgTicket}
                            prefix={this.props.currencySymbol}
                            displayType={'text'}
                            thousandSeparator={true}
                            decimalScale={0}
                        />
                    </p>
                </CardBody>
            </Card>
        </Col>)
    }


    render() {
        if (this.state.dataMen === null || this.state.dataWomen === null) {
            return (
                <>
                    <Col>
                        <Card>
                            <CardBody>
                                {this.props.loadingSpinner}
                                <p className="text-center">{this.labels.lblLoading}</p>
                            </CardBody>
                        </Card>
                    </Col>

                    <Col>
                        <Card>
                            <CardBody>
                                {this.props.loadingSpinner}
                                <p className="text-center">{this.labels.lblLoading}</p>
                            </CardBody>
                        </Card>
                    </Col>

                </>

            )
        }
        else {
            return (<>
                <div className="detailGenderSummary">
                    <Row>
                        {this.getCol(<i className="fa fa-male"></i>, this.labels.lblMenTitle, this.state.dataMen.count, this.state.dataMen.perc, this.state.dataMen.avgTicket)}
                        {this.getCol(<i className="fa fa-female"></i>, this.labels.lblWomenTitle, this.state.dataWomen.count, this.state.dataWomen.perc, this.state.dataWomen.avgTicket)}
                    </Row>
                </div>
            </>)
        }
    }
}

var Paginator = props => {
    var labels = props.labels;
    return (<>
        <Row className="text-center">
            <Col>
                <h4>Mostrando pagina: {props.currentPage}</h4>
            </Col>
        </Row>
        <Row className="text-center">
            <Col>
                <button className="pager-button" onClick={() => { props.firstPage() }}>{labels.btnFirst}</button>
                <button className="pager-button" onClick={() => { props.prevPage() }}>
                    <i className="ti-angle-left"></i></button>
                <button className="pager-button" onClick={() => { props.nextPage() }}>
                    <i className="ti-angle-right"></i>
                </button>
                <button className="pager-button" onClick={() => { props.lastPage() }}>{labels.btnLast}</button>
            </Col>
        </Row>
    </>)
}

class ClientList extends React.Component {
    constructor(props) {
        super(props)
        this._isMounted = false;
        this.dropdownLabels = crmDropdownLang(props.lang)
        this.state = { data: null, dropdownOpen: false, dropDownValue: "NOMBRE", dropDownLabel: this.dropdownLabels.name };
        this.body = {
            "parameters": {
                "requester": "segment",
                "requestparameters": { "selectedSegment": "" + this.props.segment, "settings": [] },
                "filters": [],
                "textsearch": ""
            },
            "page": this.props.page,
            "sort": "NOMBRE",
            "sortdirection": -1
        }
        this.labels = props.labels;
        this.toggleDropdown = this.toggleDropdown.bind(this)
        //this.readyFiltersForList = this.readyFiltersForList.bind(this);
    }

    async componentDidMount() {
        this._isMounted = true;
        try {
            this.body.parameters.filters = this.changeFilters(this.props.filters);
            this.body.sort = this.state.dropDownValue;
            let crmcacheID = "crmSegments" + this.props.segment + "page" + this.props.page;
            let clientL = await this.props.askFor('crm', 'crm_bucket/searchclientswithparameters', this.body.parameters.filters, false, this.body, crmcacheID);
            if (this._isMounted && clientL != null) {
                this.setState({ data: clientL.clients });
            }
        } catch (err) {
            console.log(err);
        }
    }

    async componentDidUpdate(prevProps, prevState) {
        if ((prevProps.segment != this.props.segment)) {
            try {
                if (this._isMounted) { this.setState({ data: null }) };
                this.body.page = 0;
                this.body.parameters.requestparameters.selectedSegment = this.props.segment;
                let crmcacheID = "crmSegments" + this.props.segment + "page" + 0 + this.state.dropDownValue;
                let clientL = await this.props.askFor('crm', 'crm_bucket/searchclientswithparameters', this.body.parameters.filters, false, this.body, crmcacheID);
                if (this._isMounted && clientL != null) {
                    this.setState({ data: clientL.clients });
                }
            } catch (err) {
                console.log(err);
            }
        }

        if (prevProps.page != this.props.page) {
            this.setState({ data: null });
            this.body.page = this.props.page;
            let crmcacheID = "crmSegments" + this.props.segment + "page" + this.props.page + this.state.dropDownValue;
            let clientL = await this.props.askFor('crm', 'crm_bucket/searchclientswithparameters', this.body.parameters.filters, false, this.body, crmcacheID);
            if (clientL != null) {
                this.setState({ data: clientL.clients });
            }
        }

        if (prevProps.filters != this.props.filters) {
            this.setState({ data: null });
            this.body.parameters.filters = [];
            this.body.parameters.filters = this.changeFilters(this.props.filters);
            this.body.page = 0;
            let crmcacheID = "crmSegments" + this.props.segment + "page" + this.props.page + this.state.dropDownValue;
            let clientL = await this.props.askFor('crm', 'crm_bucket/searchclientswithparameters', this.body.parameters.filters, false, this.body, crmcacheID);
            if (clientL != null) {
                this.setState({ data: clientL.clients });
            }
        }

        if (prevProps.labels != this.props.labels) {
            this.labels = this.props.labels;
            if (this._isMounted) {
                this.forceUpdate();
            }
        }

        if (prevProps.lang != this.props.lang) {
            this.dropdownLabels = crmDropdownLang(this.props.lang);
            if (this._isMounted) {
                this.forceUpdate();
            }
        }

        if (prevState.dropDownValue != this.state.dropDownValue) {
            try {
                if (this._isMounted) { this.setState({ data: null }) };
                this.body.parameters.filters = this.changeFilters(this.props.filters);
                this.body.sort = this.state.dropDownValue;
                let crmcacheID = "crmSegments" + this.props.segment + "page" + this.props.page + this.state.dropDownValue;
                let clientL = await this.props.askFor('crm', 'crm_bucket/searchclientswithparameters', this.body.parameters.filters, false, this.body, crmcacheID);
                if (this._isMounted && clientL != null) {
                    this.setState({ data: clientL.clients });
                }
            } catch (err) {
                console.log(err);
            }
        }

        ReactTooltip.rebuild();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    getEmail = (email) => {
        var r = null;

        if (this.props.isFieldHidden('email')) {
            return;
        } else {
            if (email === null || email === undefined) {
                r = <th>Email</th>;
            } else {
                r = <td>{email}</td>;
            }

        }

        return r;
    }

    changeFilters(filters) {
        let f = [];

        filters.forEach(fil => {
            let clone = Object.assign({}, fil)
            f.push(clone);
        })

        f.map(fi => {
            fi.fieldname = fi.productTableField;
        })

        return f;
    }

    toggleDropdown = () => {
        this.setState(state => { return ({ dropdownOpen: !state.dropdownOpen }) })
    }

    changeDropdownValue = (value) => {
        var label = "";
        var val = "";

        switch (value) {
            case 'name': label = this.dropdownLabels.name; val = "NOMBRE"; break;
            case 'amount': label = this.dropdownLabels.amount; val = "TXAMOUNTSUM"; break;
            case 'visits': label = this.dropdownLabels.visits; val = "TXCOUNT"; break;
        }


        if (this._isMounted) {
            this.setState({ dropDownValue: val, dropDownLabel: label })
        }
    }

    handleTextSearch(val) {
        this.body.parameters.textsearch = val;
    }

    async searchClients() {
        try {
            if (this._isMounted) { this.setState({ data: null }) };
            //this.body.page = 0;
            this.body.parameters.requestparameters.selectedSegment = this.props.segment;
            let crmcacheID = "crmSegments" + this.props.segment + "page" + 0 + this.state.dropDownValue + this.body.parameters.textsearch;
            let clientL = await this.props.askFor('crm', 'crm_bucket/searchclientswithparameters', this.body.parameters.filters, false, this.body, crmcacheID);
            if (this._isMounted && clientL != null) {
                this.setState({ data: clientL.clients });
            }
        } catch (err) {
            console.log(err);
        }
    }

    render() {
        return (<Container fluid>
            <ReactTooltip id="crmClientsTooltip" place="left" textColor="#1c79cd" backgroundColor="#c7e1f9" />
            <Row className="crmSegmentsBar">
                <Col style={{ display: "flex" }}>
                    <input
                        type="text"
                        className="form-control border-input noRadius"
                        placeholder={this.labels.lblSearchPlaceHolder}
                        onChange={e => { this.handleTextSearch(e.target.value) }}
                    />
                    <Button
                        className="btnExport noRadius" onClick={() => { this.searchClients() }}>
                        <i className="ti-search" />
                    </Button>
                </Col>
                <label>Ordenar Por:</label>
                <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggleDropdown}>
                    <DropdownToggle>
                        <span>{this.state.dropDownLabel} <i className="ti-angle-down" /></span>
                    </DropdownToggle>
                    <DropdownMenu>
                        <DropdownItem onClick={() => { this.changeDropdownValue('name') }}>{this.dropdownLabels.name}</DropdownItem>
                        <DropdownItem onClick={() => { this.changeDropdownValue('amount') }}>{this.dropdownLabels.amount}</DropdownItem>
                        <DropdownItem onClick={() => { this.changeDropdownValue('visits') }}>{this.dropdownLabels.visits}</DropdownItem>
                    </DropdownMenu>
                </Dropdown>
                <Button className="btnExport" onClick={() => { this.props.toggleSegmentExport() }}><i className="ti-download" /> {this.dropdownLabels.export}</Button>
            </Row>
            {this.state.data != null ?
                <Table responsive className="crmSegments" striped={true}>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>{this.labels.lblSorterName}</th>
                            <th>{this.labels.lblSorterLastName}</th>
                            {this.getEmail()}
                            <th>{this.labels.lblSorterAge}</th>
                            <th>{this.labels.lblSorterCount}</th>
                            <th>{this.labels.lblVisitCounter}</th>
                            <th>{this.labels.lblActions}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.data.map((element, key) => {
                            return (<tr key={key}>
                                <td>{element.CLIENTE}</td>
                                <td>{element.NOMBRE}</td>
                                <td>{element.PRIMERAPELLIDO}</td>
                                {this.getEmail(element.EMAIL)}
                                <td>{element.EDAD}</td>
                                <td><NumberFormat value={element.TXAMOUNTSUM} prefix={this.props.currencySymbol} displayType={'text'} thousandSeparator={true} /></td>
                                <td>{element.TXCOUNT}</td>
                                <td><Button data-tip="Ver clientes" data-for="crmClientsTooltip" onClick={() => { this.props.changeScreen({ screen: 'clientDetail', value: element.CLIENTE }) }}>{/* {this.labels.btnViewClients} */} <i className="ti-eye"></i></Button></td>
                            </tr>
                            )
                        })
                        }
                    </tbody>
                </Table>
                :
                <>
                    {this.props.loadingSpinner}
                </>}
        </Container>)
    }
}

class SalesPerMonthChart extends React.Component {
    constructor(props) {
        super(props)
        this._isMounted = false;
        this.state = { data: null }
        this.titleStyle = { width: "100%", textAling: "center", borderBottom: 'groove 1px white' }
        this.labels = this.props.labels;
        this.chartOptions = {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
                display: true,
                labels: {
                    fontColor: '#9a9a9a'
                }
            },
            scales: {
                yAxes: [
                    {
                        gridLines: {
                            display: false
                        },
                        ticks: {
                            display: false
                        }
                    }
                ],
                xAxes: [
                    {
                        gridLines: {
                            display: false
                        },
                        ticks: {
                            display: true
                        }
                    }
                ],
            },
            tooltips: {
                callbacks: {
                    label: function (tooltipItem, data) {
                        let v = tooltipItem.yLabel;
                        return v.toLocaleString();
                    }
                }
            }
        }
    }
    //Props: label , totalPoints, chartData , chartOptions.

    //0.REACT LIFECYCLE FUNCTIONS (API CALLS)-------------------------------------------------------------------------------------------------------------------------
    async componentDidMount() {
        this._isMounted = true;
        try {
            let cacheId = 'DetailSegmentPurch' + this.props.segment;
            let res = await this.props.askFor('segments', 'segments/getpurchasesummarybysegment', this.props.filters, false, this.props.body, cacheId);
            if (res != null) {
                let dataReady = this.readyChartData(res, this.props.title, "txsum");
                if (this._isMounted) { this.setState({ data: dataReady }) };
            }
        }
        catch (err) {
            console.log(err);
        }
    }

    async componentDidUpdate(prevProps) {
        if (prevProps.segment != this.props.segment || prevProps.filters != this.props.filters) {
            try {
                let cacheId = 'DetailSegmentPurch' + this.props.segment;
                let res = await this.props.askFor('segments', 'segments/getpurchasesummarybysegment', this.props.filters, false, this.props.body, cacheId);
                if (res != null) {
                    let dataReady = this.readyChartData(res, this.props.title, "txsum");
                    if (this._isMounted) { this.setState({ data: dataReady }) };
                }
            } catch (err) {
                console.log(err);
            }
        }

        if (prevProps.labels != this.props.labels) {
            this.labels = this.props.labels;
            if (this._isMounted) {
                this.forceUpdate();
            }
        }

    }

    componentWillUnmount() {
        this._isMounted = false;
    }


    //1.PRE-RENDER FUNCTIONS ------------------------------------------------------------------------------------------------------------------------------------------------
    hexToRgb(hex) {
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16)
        } : null;
    }

    readyChartData = (data, label, yAxis) => {
        if (data === null || data === undefined) {
            return null;
        }

        var createMonthString = (element) => {
            let m = null;
            let year = element["month_num"].toString();
            let y = year.slice(0, 4);
            switch (element["month"]) {
                case "Enero": m = ['ENE', y]; break;
                case "Febrero": m = ['FEB', y]; break;
                case "Marzo": m = ['MAR', y]; break;
                case "Abril": m = ['ABR', y]; break;
                case "Mayo": m = ['MAY', y]; break;
                case "Junio": m = ['JUN', y]; break;
                case "Julio": m = ['JUL', y]; break;
                case "Agosto": m = ['AGO', y]; break;
                case "Septiembre": m = ['SEP', y]; break;
                case "Octubre": m = ['OCT', y]; break;
                case "Noviembre": m = ['NOV', y]; break;
                case "Diciembre": m = ['DEC', y]; break;
                default: m = ''; break;
            }

            return m;
        }


        return (canvas => {
            let ctx = canvas.getContext("2d");
            let color = this.hexToRgb(this.props.color);

            let gradientStroke = ctx.createLinearGradient(color.r, color.g, color.b, 100);

            gradientStroke.addColorStop(1, "rgba(" + color.r + "," + color.g + "," + color.b + ",0.6)");
            gradientStroke.addColorStop(0.4, "rgba(" + color.r + "," + color.g + "," + color.b + ",0.5)");
            gradientStroke.addColorStop(0, "rgba(" + color.r + "," + color.g + "," + color.b + ",0.4)"); //blue colors

            let labels = [];
            let chartData = []


            data.forEach(element => {
                if (element === null || element === undefined) {
                    return null;
                }
                else {
                    labels.push(createMonthString(element));
                    chartData.push(element[yAxis].toFixed(0));
                }
            });

            return {
                labels: labels,
                datasets: [
                    {
                        label: label,
                        fill: true,
                        backgroundColor: gradientStroke,
                        borderColor: this.props.color,
                        borderWidth: 2,
                        borderDash: [],
                        borderDashOffset: 0.0,
                        pointBackgroundColor: this.props.color,
                        pointBorderColor: "rgba(0,0,0,0)",
                        pointHoverBackgroundColor: this.color,
                        pointBorderWidth: 20,
                        pointHoverRadius: 4,
                        pointHoverBorderWidth: 15,
                        pointRadius: 4,
                        data: chartData
                    }
                ]
            };
        })
    }

    render() {
        return (<Col md="12" sm="12" xs="12" lg="12" className="text-center salesPerMonthCol" /* style={{ marginTop: "10px" }} */>
            <Card className="salesPerMonthCard">
                <CardBody className="salesPerMonthCardBody">
                    {/* <div className="text-left" style={this.titleStyle}>
                        <h6 style={{ marginBottom: "5px", width:"100%", textAlign:"center" }}>{this.props.title}</h6>
                    </div> */}
                    {/* <Row>
                        <Col md="12" lg='12'> */}
                    {this.state.data != null ?

                        <div className="content text-center salesPerLineContainer">
                            <Line data={this.state.data} options={this.chartOptions} />
                        </div>

                        :
                        <div>
                            {/* {this.props.loadingSpinner} */}
                            <p className="text-center">{this.labels.lblLoading}</p>
                        </div>
                    }
                    {/* </Col>
                    </Row> */}
                </CardBody>
            </Card>
        </Col>)
    }
}

class FavProducts extends React.Component {
    constructor(props) {
        super(props)
        this._isMounted = false;
        this.state = { data: null, topProduct: null };
        this.labels = this.props.labels;
        this.colorStyle = { color: this.props.color };
    }

    //0. API CALLS ------------------------------------------------------------------------------------------------------------------------------
    async componentDidMount() {
        this._isMounted = true;
        try {
            let cacheId = "FavProdsSegment" + this.props.body.segmentToFilter;
            let data = await this.props.askFor('segments', 'segments/gettopproductsbysegment', [], false, this.props.body, cacheId)
            if (data != null & Array.isArray(data) && (data.length > 0) && (this._isMounted)) {
                this.setState({ data: data, topProduct: data[0] });
            }

        } catch (err) {
            console.log(err);
        }


    }

    async componentDidUpdate(prevProps) {
        if (prevProps.segment != this.props.segment || prevProps.filters != this.props.filters) {
            if (this.props.empty === undefined) {
                try {
                    this.setState({ data: null })
                    this.colorStyle = { color: this.props.color };
                    let cacheId = "FavProdsSegment" + this.props.body.segmentToFilter;
                    let bod = this.props.body;
                    let data = await this.props.askFor('segments', 'segments/gettopproductsbysegment', bod.filters, false, bod, cacheId)
                    if (data != null && Array.isArray(data) && (data.length > 0) && (this._isMounted)) {
                        this.setState({ data: data, topProduct: data[0] });
                    }

                } catch (err) {
                    console.log(err);
                }
            }

        }

        if (prevProps.labels != this.props.labels) {
            this.labels = this.props.labels;
            if (this._isMounted) {
                this.forceUpdate();
            }
        }

    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    //1. RENDER ---------------------------------------------------------------------------------------------------------------------------------

    render() {
        if (this.state.data != null) {
            return (<>
                <Row>
                    <Card>
                        <CardBody>
                            <h4 style={this.colorStyle}> <i className="ti-star"></i> {this.labels.lblBestSeller}</h4>
                            <h3>{this.state.topProduct.name}</h3>
                            <p>SKU: {this.state.topProduct.sku}</p>
                            <hr />
                            <Row>
                                <Col>
                                    {this.labels.lblVisitCounter}: {this.state.topProduct.times}
                                </Col>
                                <Col>
                                    <p>
                                        <b>{this.labels.lblPurchases}</b>:
                                        <b className="segmentsFavValues">
                                            <NumberFormat
                                                style={this.colorStyle}
                                                displayType="text"
                                                value={this.state.topProduct.amount}
                                                prefix={this.props.currencySymbol}
                                                decimalScale={2}
                                                thousandSeparator={true} />
                                        </b>
                                    </p>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Row>
                <hr />
                <Row style={{ display: 'block' }}>
                    <h6>{this.labels.lblTop10}</h6>
                    <p>{this.labels.lblTop10Subtitle}</p>
                    <ul className="list-unstyled">
                        {this.state.data.map((element, key) => {
                            if (key + 1 <= 10) {
                                return (
                                    <li key={key}>
                                        <Card>
                                            <CardBody>
                                                <Row>
                                                    <Col lg="1">
                                                        <h2><b style={this.colorStyle}>{key + 1}</b></h2>
                                                    </Col>
                                                    <Col lg="11">
                                                        <h3>{element.name}</h3>
                                                        <p>SKU: <b style={this.colorStyle}>{element.sku}</b></p>
                                                    </Col>
                                                </Row>
                                                <hr />
                                                <Row>
                                                    <Col>
                                                        {this.labels.lblVisitCounter}: <b style={this.colorStyle}>{element.times}</b>
                                                    </Col>
                                                    <Col>
                                                        <p>
                                                            <b>{this.labels.lblPurchases}</b>:
                                                            <b className="segmentsFavValues">
                                                                <NumberFormat
                                                                    style={this.colorStyle}
                                                                    displayType="text"
                                                                    value={element.amount}
                                                                    prefix={this.props.currencySymbol}
                                                                    decimalScale={2}
                                                                    thousandSeparator={true} />
                                                            </b>
                                                        </p>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </li>
                                )
                            }
                        })}
                    </ul>
                </Row>
            </>)
        }
        else {
            return (<div className="text-center" style={{ paddingTop: "15px" }}><h2>{this.labels.lblLoading}</h2></div>)
        }

    }

}

class FavStores extends React.Component {
    constructor(props) {
        super(props)
        this._isMounted = false;
        this.state = { data: null, topProduct: null };
        this.labels = this.props.labels;
        this.colorStyle = { color: this.props.color };
    }

    //0. API CALLS ------------------------------------------------------------------------------------------------------------------------------
    async componentDidMount() {
        this._isMounted = true;
        try {
            let cacheId = "FavStoresSegment" + this.props.body.segmentToFilter;
            let data = await this.props.askFor('segments', 'segments/gettopstoresbysegment', [], false, this.props.body, cacheId)
            if (data != null && Array.isArray(data) && (data.length > 0) && (this._isMounted)) {
                this.setState({ data: data, topStore: data[0] });
            }

        } catch (err) {
            console.log(err);
        }


    }

    async componentDidUpdate(prevProps) {
        if (prevProps.segment != this.props.segment || prevProps.filters != this.props.filters) {
            if (this.props.empty === undefined) {
                try {
                    this.setState({ data: null })
                    this.colorStyle = { color: this.props.color };
                    let cacheId = "FavStoresSegment" + this.props.body.segmentToFilter;
                    let bod = this.props.body;
                    let data = await this.props.askFor('segments', 'segments/gettopstoresbysegment', bod.filters, false, bod, cacheId)
                    if (data != null && Array.isArray(data) && (data.length > 0) && (this._isMounted)) {
                        this.setState({ data: data, topStore: data[0] });
                    }

                } catch (err) {
                    console.log(err);
                }
            }

        }

        if (prevProps.labels != this.props.labels) {
            this.labels = this.props.labels;
            if (this._isMounted) {
                this.forceUpdate();
            }
        }

    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    //1. RENDER ---------------------------------------------------------------------------------------------------------------------------------

    render() {
        if (this.state.data != null) {
            return (<>
                <Row>
                    <Card>
                        <CardBody>
                            <h4 style={this.colorStyle}> <i className="ti-star"></i> {this.labels.lblBestSeller}</h4>
                            <h3>{this.state.topStore.name}</h3>
                            <p>ID: {this.state.topStore.id}</p>
                            <hr />
                            <Row>
                                <Col>
                                    {this.labels.lblVisitCounter}: <b style={this.colorStyle}><NumberFormat value={this.state.topStore.times} displayType="text" thousandSeparator={true} /></b>
                                </Col>
                                <Col>
                                    <p>
                                        <b>{this.labels.lblPurchases}</b>:
                                        <b className="segmentsFavValues">
                                            <NumberFormat
                                                style={this.colorStyle}
                                                displayType="text"
                                                value={this.state.topStore.amount}
                                                prefix={this.props.currencySymbol}
                                                decimalScale={2}
                                                thousandSeparator={true} />
                                        </b>
                                    </p>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Row>
                <hr />
                <Row style={{ display: 'block' }}>
                    <h6>{this.labels.lblTop10}</h6>
                    <p>{this.labels.lblTop10Subtitle}</p>
                    <ul className="list-unstyled">
                        {this.state.data.map((element, key) => {
                            if (key + 1 <= 10) {
                                return (
                                    <li key={key}>
                                        <Card>
                                            <CardBody>
                                                <Row>
                                                    <Col lg="1">
                                                        <h2><b style={this.colorStyle}>{key + 1}</b></h2>
                                                    </Col>
                                                    <Col lg="11">
                                                        <h3>{element.name}</h3>
                                                        <p>ID: <b style={this.colorStyle}>{element.id}</b></p>
                                                    </Col>
                                                </Row>
                                                <hr />
                                                <Row>
                                                    <Col>
                                                        {this.labels.lblVisitCounter}: <b style={this.colorStyle}><NumberFormat value={element.times} displayType="text" thousandSeparator={true} /></b>
                                                    </Col>
                                                    <Col>
                                                        <p>
                                                            <b>{this.labels.lblPurchases}</b>:
                                                            <b className="segmentsFavValues">
                                                                <NumberFormat
                                                                    style={this.colorStyle}
                                                                    displayType="text"
                                                                    value={element.amount}
                                                                    prefix={this.props.currencySymbol}
                                                                    decimalScale={2}
                                                                    thousandSeparator={true} />
                                                            </b>
                                                        </p>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </li>
                                )
                            }
                        })}
                    </ul>
                </Row>
            </>)
        }
        else {
            return (<div className="text-center"><h2>{this.labels.lblLoading}</h2></div>)
        }

    }

}

class ExportCreator extends React.Component {
    constructor(props) {
        super(props)
        this._isMounted = false;
        this._labels = MyExportsCreatorLang(props.lang);
        this.state = {
            petitionBody: {
                "parameters": {
                    "requester": "segment",
                    "requestparameters": {
                        "matrixcellid": null,
                        "selectedSegment": props.segmentId,
                        "settings": null,
                    },
                    "filters": "",
                    "textsearch": "",
                    "collectionname": "",       // Client Export Only
                    "description": ""    // Client Export Only
                },
                "page": 0,
                "sort": ""
            },
            petitionSended: false,
        }
        //Function Binding
        this.handleDescription = this.handleDescription.bind(this);
        this.handleName = this.handleName.bind(this);
        this.saveExport = this.saveExport.bind(this);
    }

    //1. REACT LIFECYCLE --------------------------
    componentDidMount() {
        this._isMounted = true;
        console.log(this.props.segment)
        this.setState(state => {
            let body = state.petitionBody;
            if (this.props.filters.length > 0) {
                body.parameters.filters = this.props.filters;
            }
            else {
                body.parameters.filters = "";
            }
            return ({ petitionBody: body })
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.filters != this.props.filters) {
            if (this._isMounted) {
                this.setState(state => {
                    let body = state.petitionBody;
                    if (this.props.filters.length > 0) {
                        body.parameters.filters = this.props.filters;
                    }
                    else {
                        body.parameters.filters = "";
                    }
                    return ({ petitionBody: body })
                })
            }
        }

        if (prevProps.lang != this.props.lang) {
            if (this._isMounted) {
                this._labels = MyExportsCreatorLang(this.props.lang);
                this.forceUpdate();
            }
        }
    }

    componentWillMount() {
        this._isMounted = false;
    }


    //2.DATA HANDLERS
    handleName(name) {
        if (this._isMounted) {
            this.setState(state => {
                let body = state.petitionBody;
                body.parameters.collectionname = name;
                return ({ petitionBody: body })
            })
        }
    }

    handleDescription(desc) {
        if (this._isMounted) {
            this.setState(state => {
                let body = state.petitionBody;
                body.parameters.description = desc;
                return ({ petitionBody: body })
            })
        }
    }


    //3.SAVE EXPORT FUNCTION
    async saveExport() {
        try {
            let cacheId = 'SaveExport' + this.state.petitionBody.parameters.collectionname + this.state.petitionBody.parameters.page;
            let data = await this.props.askFor('crm', 'crm_bucket/clients/export/create', [], false, this.state.petitionBody, cacheId);
            if (this._isMounted && data != null) {
                this.setState({ petitionSended: true })
            }
        } catch (err) {
            console.log(err);
        }
    }


    //4. RENDER -------------------------- -------------------------- --------------------------
    render() {
        if (this.state.petitionSended) {
            //Redirects to My exports section
            return (<Redirect to="/myexports" />)
        }
        else {
            //Default
            return (<Container fluid className="exportCreator">
                <Card>
                    <CardBody>
                        <Row>{/* Title and explanation */}
                            <Col>
                                <h4>{this._labels.lblTitle}</h4>
                                <p>{this._labels.lblExplain}</p>
                            </Col>
                        </Row>
                        <Row>{/* Name and count */}
                            <Col>
                                <h4>{this.props.segmentName || 'Nombre'}</h4>
                                <p>{this._labels.lblClientsToExport}: <b>{this.props.count || 0}</b> </p>
                            </Col>
                        </Row>
                        <Row>{/* Inputs */}
                            <Col>
                                <FormGroup>
                                    <Input type="text" placeholder={this._labels.lblNamePlaceHolder} onChange={e => this.handleName(e.target.value)} />
                                    <Input type="textarea" placeholder={this._labels.lblDescPlaceholder} onChange={e => this.handleDescription(e.target.value)} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>{/* Buttons */}
                            <Col>
                                <Row>
                                    <Col>
                                        <button className="d-inline" onClick={() => { this.props.closeExport() }}>{this._labels.lblButtonCancel}</button>
                                        <button className="d-inline" onClick={() => { this.saveExport() }}><i className="ti-download" />  {this._labels.lblButtonExport}</button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Container>)
        }

    }
}

class TreeMapCmp extends React.Component {
    constructor(props) {
        super(props)
        this._isMounted = false;
        this.state = {
            data: null,
            categorization: null,
            category: null,
            typeOf: 'CAT',
            typeName: props.labels.treeMapTitle,
            valToShow: 'amount',
            valToShowLabel: props.labels.treeMapValAmount,
            valueUnit: props.currencySymbol
        }
        this.treeChartData = {
            "name": this.state.typeName,
            "children": []
        }
        this.numLevels = null;
        this.maxLevel = null;
        this.refreshTreeChart = this.refreshTreeChart.bind(this);
        this.notExistsInChildren = this.notExistsInChildren.bind(this);
        this.formRecordsMap = this.formRecordsMap.bind(this);
        this.formHMap = this.formHMap.bind(this);
        this.formTreeMap = this.formTreeMap.bind(this);
        this.callFormDataFunctions = this.callFormDataFunctions.bind(this);
        this.colors = this.calcColors(props.color);
        this.labels = props.labels;
        this.errorMessage = <div><h3 className="text-center">{this.labels.lblLoading}</h3></div>;

    }

    //0. REACT LIFE CYCLE (API CALLS) ------------------------------------------------------------------------------------------------------------------------

    async componentDidMount() {
        this._isMounted = true;
        try {
            let cacheID = "SegmentDetailTreeChart" + this.props.body.segmentToFilter;
            let res = await this.props.askFor('segments', 'segments/gettopproductsbysegmentfullcats', this.props.filters, false, this.props.body, cacheID)
            if (res != null) {
                this.numLevels = res.categorization.length;
                this.maxLevel = this.numLevels - 1;
                if (this._isMounted) {
                    this.setState({ data: res, category: res.categorization[0].name, categorization: res.categorization, typeName: res.categorization[0].label }, () => this.callFormDataFunctions());
                }
            }
        } catch (err) {
            console.log(err);
        }
    }

    async componentDidUpdate(prevProps, prevState) {
        if (prevProps.segment !== this.props.segment || prevProps.filters !== this.props.filters) {
            try {
                this.colors = this.calcColors(this.props.color);
                let cacheID = "SegmentDetailTreeChart" + this.props.segment;
                let res = await this.props.askFor('segments', 'segments/gettopproductsbysegmentfullcats', this.props.body.filters, false, this.props.body, cacheID);
                if (res != null) {
                    this.numLevels = res.categorization.length;
                    this.maxLevel = this.numLevels - 1;
                    if (this._isMounted) {
                        this.setState({ data: res })
                        this.callFormDataFunctions()
                    }
                }
            } catch (err) {
                console.log(err);
            }
        }

        if (prevState.recharge !== this.state.recharge && this.state.recharge === true) {
            this.setState({ recharge: false })
        }

        if (prevProps.labels !== this.props.labels) {
            this.labels = this.props.labels;
            if (this._isMounted) {
                this.forceUpdate();
            }
        }

    }

    componentWillUnmount() {
        this._isMounted = false;
    }


    //1. UTILITY FUNCTIONS ------------------------------------------------------------------------------------------------------------------------
    notExistsInChildren(name, obj) {
        let r = true;
        if (obj === undefined) {
            this.treeChartData.children.map(x => {
                if (x.name === name) {
                    r = false;
                }
            })
        }
        else {
            obj.children.map(x => {
                if (x.name === name) {
                    r = false;
                }
            })
        }


        //console.log(obj);
        return r;
    }

    formName = data => {
        var r = { name: "", children: [] };
        if (data === undefined) return r;
        if (data.level !== undefined) r.id = data.level;
        r.name = data.name;
        //r.value=data.amount.toFixed(0);

        return r;

    }

    formDep = data => {
        var r = { name: "", children: [] };
        if (data === undefined) return r;
        if (data.level != undefined) r.id = data.level;
        r.name = data.DEP;
        //r.value=data.amount.toFixed(0);

        return r;

    }

    formNameValue = data => {
        var r = { name: "", children: [] };
        if (data === undefined) return r;
        if (data.level !== undefined) r.id = data.level;
        r.name = data.name;
        r.value = data[this.state.valToShow].toFixed(0);
        return r;

    }


    //2. DATA CONSTRUCTIONS ------------------------------------------------------------------------------------------------------------------------
    formHMap = (data) => {
        if (data === undefined) return;
        if (!Array.isArray(data)) return;

        let toCompare = "";

        if (this.numLevels === 1) {
            this.formTreeMap(data, this.state.category);
            return;
        }

        data.map((level, index) => {
            level.data.map(_l => {
                if (_l.name === undefined) {
                    if (this.treeChartData.children.length < 20) { if (this.notExistsInChildren(_l.name, this.treeChartData)) { this.treeChartData.children.push(this.formName(_l)); } } toCompare = level.DEP;
                    // console.log(index)
                    //console.log(level);
                    //console.log(_l);
                } else {
                    //console.log(level);
                    //console.log(index);
                    switch (index) {
                        case 0: if (this.treeChartData.children.length < 20) { if (this.notExistsInChildren(_l.name, this.treeChartData)) { this.treeChartData.children.push(this.formName(_l)); } } toCompare = level.level; break;
                        case 1: this.treeChartData.children.map(firstLevel => { if (_l[toCompare] === firstLevel.name) { if (firstLevel.children.length < 20) { if (this.notExistsInChildren(_l.name, firstLevel)) { firstLevel.children.push(this.formNameValue(_l)) } } else { toCompare = level.level; } } }); break;
                        case 2: console.log(_l); this.treeChartData.children.map(firstLevel => { firstLevel.children.map(secondLevel => { if (_l[toCompare] === secondLevel.name) { if (secondLevel.children.length < 20) { if (this.notExistsInChildren(_l.name, secondLevel)) { secondLevel.children.push(this.formName(_l)) } } else { toCompare = level.level; } } }) }); break;
                        case 3: this.treeChartData.children.map(firstLevel => { firstLevel.children.map(secondLevel => { secondLevel.children.map(thirdLevel => { if (_l[toCompare] === thirdLevel.name) { if (thirdLevel.children.length < 20) { if (this.notExistsInChildren(_l.name, thirdLevel)) { thirdLevel.children.push(this.formName(_l)) } } } }) }) }); break;
                        //case 4: this.treeChartData.children.map(firstLevel => { firstLevel.children.map(secondLevel => { secondLevel.children.map(thirdLevel=>{thirdLevel.children.map(fourthLevel=>{if (_l[toCompare] == fourthLevel.name) { if (fourthLevel.children.length < 20) { if (this.notExistsInChildren(_l.name, fourthLevel)) { fourthLevel.children.push(this.formNameValue(_l)) } } }})}) }) }); break;

                    }
                }


            })
        })

        console.log(this.treeChartData)
        this.setState({ recharge: true })

    } //Deprecated function

    formTreeMap = (data, level) => {
        let dat = null;
        data.map(lvl => {
            if (lvl.level === level) {
                dat = lvl;
            }
        })
        
        dat.data.map(obj => {
            if (this.notExistsInChildren(obj.name)) {
                if (this.treeChartData.children.length < 20) {
                    this.treeChartData.children.push(this.formNameValue(obj));
                }
            }
        });

        this.setState({ recharge: true })
    }

    formRecordsMap = (data) => {
        data.map(obj => {
            if (this.notExistsInChildren(obj.name)) {
                if (this.treeChartData.children.length < 20)
                    this.treeChartData.children.push(this.formNameValue(obj));
            }
        })
        this.setState({ recharge: true })
    }

    callFormDataFunctions() {
        this.refreshTreeChart();
        switch (this.state.typeOf) {
            case 'HMAP': this.formHMap(this.state.data.groupedData); break;
            case 'CAT': this.formTreeMap(this.state.data.groupedData, this.state.category); break;
            case 'REC': this.formRecordsMap(this.state.data.records); break;
            default: break;
        }
    }

    refreshTreeChart() {
        this.treeChartData.name = this.state.typeName;
        this.treeChartData.children = [];
    }


    //2. STYLING FUNCTIONS -------------------------------------------------------------------------------------------------------------------------
    hexToRgb(hex) {
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? {
            r: { val: parseInt(result[1], 16), id: "r" },
            g: { val: parseInt(result[2], 16), id: 'g' },
            b: { val: parseInt(result[3], 16), id: 'b' }
        } : null;
    }

    componentToHex(c) {
        var hex = c.toString(16);
        return hex.length === 1 ? "0" + hex : hex;
    }

    rgbToHex(r, g, b) {
        if (r < 0) r = 0;
        if (r > 255) r = 255;
        if (g < 0) g = 0;
        if (g > 255) g = 255;
        if (b < 0) b = 0;
        if (b > 255) b = 255;
        return "#" + this.componentToHex(r) + this.componentToHex(g) + this.componentToHex(b);
    }

    calcColors(color) {
        let rgbValues = this.hexToRgb(color);
        var redColorBase = null;
        let baseColor = null;
        let maxVal = 0;
        let highR = 0;
        let highG = 0;
        let highB = 0;
        let lowR = 0;
        let lowG = 0;
        let lowB = 0;
        let colors = {}
        for (const prop in rgbValues) {
            //console.log(prop);
            if (rgbValues[prop].val > maxVal) {
                maxVal = rgbValues[prop].val;
                if ((rgbValues[prop].val - maxVal >= 200)) {
                    //oranges
                    redColorBase = "orange";
                }
                if ((rgbValues[prop].val - maxVal > 100)) {
                    //reds
                    redColorBase = "red";
                }
                if ((rgbValues[prop].val - maxVal < 100)) {
                    //yellows
                    redColorBase = "yellow";
                }
                baseColor = rgbValues[prop].id;

            }
        }

        switch (baseColor) {
            case 'r':
                highG = rgbValues.g.val - 20;
                highB = rgbValues.b.val - 20;
                lowG = rgbValues.g.val + 60;
                lowB = rgbValues.b.val + 60;

                colors = { highColor: this.rgbToHex(rgbValues.r.val - 40, highG, highB), lowColor: this.rgbToHex(rgbValues.r.val + 30, lowG, lowB) }
                    ; break;
            case 'g':
                highR = rgbValues.r.val - 20;
                lowR = rgbValues.r.val + 150;
                highB = rgbValues.b.val - 20;
                lowB = rgbValues.b.val + 150;

                colors = { highColor: this.rgbToHex(highR, rgbValues.g.val - 40, highB), lowColor: this.rgbToHex(lowR, rgbValues.g.val + 60, lowB) }

                    ; break;
            case 'b':
                highR = rgbValues.r.val - 40;
                highG = rgbValues.g.val - 90;
                lowR = rgbValues.r.val + 200;
                lowG = rgbValues.g.val + 140;

                colors = { highColor: this.rgbToHex(highR, highG, rgbValues.b.val - 80), lowColor: this.rgbToHex(lowR, lowG, rgbValues.b.val + 60) }
                    ; break;

            default: break;
        }


        return colors;


    }

    //3. EVENT HANDLERS -------------------------------------------------------------------------------------------------------------------------

    onValueDropdownChange(val, valU, valLabel) {
        this.setState({ valToShow: val, valueUnit: valU, valToShowLabel: valLabel }, () => { this.callFormDataFunctions() })
    }

    onTypeDropdownChange(val, name) {
        if (val === 'HMAP') {
            this.setState({ typeOf: val, typeName: name }, () => { this.callFormDataFunctions() })
        } else {
            if (val === this.state.categorization[this.state.categorization.length - 1].name) {
                this.setState({ typeOf: 'REC', category: val, typeName: name }, () => { this.callFormDataFunctions() })
            } else {
                this.setState({ typeOf: 'CAT', category: val, typeName: name }, () => { this.callFormDataFunctions() })
            }

        }
    }


    //4. RENDER --------------------------------------------------------------------------------------------------------------------------------------
    render() {
        if (this.state.data != null) {
            return (
                <Container fluid className="treeMapChart" >
                    <Row>
                        <Col className="configCol">
                            <UncontrolledDropdown>
                                <DropdownToggle caret className="dropDownButton" data-toggle="dropdown">
                                    {this.state.typeName}
                                </DropdownToggle>
                                <DropdownMenu>
                                    {/* <DropdownItem onClick={() => { this.onTypeDropdownChange("HMAP", "Mapa Jerarquico") }}>{this.labels.treeMapTitle}</DropdownItem> */}
                                    {this.state.categorization === null ? "" :
                                        this.state.categorization.map((category, key) => {
                                            return (
                                                <DropdownItem key={key} onClick={() => { this.onTypeDropdownChange(category.name, category.label) }}>{category.label}</DropdownItem>
                                            )
                                        })}
                                </DropdownMenu>
                            </UncontrolledDropdown>

                            <UncontrolledDropdown>
                                <DropdownToggle caret className="dropDownButton" data-toggle="dropdown">
                                    {this.state.valToShowLabel}
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem onClick={() => { this.onValueDropdownChange('amount', this.props.currencySymbol, this.labels.treeMapValAmount) }}>{this.labels.treeMapValAmount}</DropdownItem>
                                    <DropdownItem onClick={() => { this.onValueDropdownChange('times', this.labels.treeMapValTimes, this.labels.treeMapValAmount) }}>{this.labels.treeMapValTimes}</DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </Col>
                        {/* <Col lg="2" md="2" sm="2">

                        </Col> */}
                    </Row>
                    <Row>
                        <TreeMap
                            data={this.treeChartData}
                            valueUnit={""}
                            width={this.props.width}
                            colorModel={0}
                            bgColorRangeHigh={this.colors.highColor}
                            bgColorRangeLow={this.colors.lowColor}
                        />
                    </Row>
                </Container>)
        }
        else {
            return this.errorMessage;
        }
    }

}

//B. MAIN COMPONENT --------------------------------------------------------------------------------------------------------------
//Componente exportado hacia la aplicación principal, recibe propiedades del componente de Fetch para realizar peticiones web y las pasa a los subcomponentes.
class SegmentDetail extends React.Component {
    constructor(props) {
        super(props)
        this._isMounted = false;
        this._pageSize = 10;
        this.body = {
            "requestfilters": {
                "filtertype": "FAV",
                "selectedproducttype": "N",
                "notinflag": "false",
                "multiproductfilter": [
                ],
                "classification": "",
                "classificationarray": [
                    {
                        "class": "",
                        "cellindex": "80"
                    }
                ]
            },
            "rfmmatrix": this.props.bucket.rfmConfig.rfmmatrix,
            "rfmvaluesarrays": this.props.bucket.rfmConfig.rfmvaluesarrays,
            "segmentToFilter": this.props.segment,
            "filters": this.props.filters
        }
        this.state = {
            data: null,
            tab: props.tab || "1",
            recharge: false,
            treeChartData: null,
            treeChartSize: 0,
            page: 0,
            openClientDetail: false,
            cleintId: null,
            openSegmentExport: false,
            dropdownOpen: false,
        }
        this.chartOptions = {
            responsive: true,
            legend: {
                display: true,
                labels: {
                    fontColor: '#9a9a9a'
                }
            },
            scales: {
                yAxes: [
                    {
                        gridLines: {
                            drawBorder: true,
                            color: "rgba(29,140,248,0.1)",
                            zeroLineColor: "#bac4ce"
                        },
                        ticks: {
                            fontColor: "#9a9a9a",
                            maxTicksLimit: 10,
                            userCallback: function (value, index, values) {
                                var strVal = value.toString();

                                if (value >= 1000 && value < 10000) {
                                    //strVal = value.toString();
                                    return strVal.substring(0, 1) + "," + strVal.substring(1);
                                }

                                if (value >= 10000 && value < 100000) {
                                    //strVal = value.toString();
                                    return strVal.substring(0, 2) + "," + strVal.substring(2);
                                }

                                if (value >= 10000 && value < 1000000) {
                                    //strVal = value.toString();
                                    return strVal.substring(0, 3) + "," + strVal.substring(3);
                                }

                                if (value >= 1000000) {
                                    value = (value / 1000000);
                                    strVal = value.toString();
                                    return strVal + "m";
                                }

                                return strVal;
                            }
                        }
                    }
                ],
                xAxes: [
                    {
                        gridLines: {
                            drawBorder: true,
                            color: "rgba(29,140,248,0.1)",
                            zeroLineColor: "#bac4ce"
                        },
                        ticks: {
                            fontColor: "#9a9a9a",
                            fontSize: 8.5
                        }
                    }
                ],
            },
            tooltips: {
                callbacks: {
                    label: function (tooltipItem, data) {
                        let v = tooltipItem.yLabel;
                        return v.toLocaleString();
                    }
                }
            }
        }
        this.labels = SegmentDetailLang(props.lang)
        this.changeTabs = this.changeTabs.bind(this);
        this.color = this.getSegmentColor(this.props.segment);
        this.nextPage = this.nextPage.bind(this);
        this.prevPage = this.prevPage.bind(this);
        this.firstPage = this.firstPage.bind(this);
        this.getSize = this.getSize.bind(this);
        this.lastPage = this.lastPage.bind(this);
        this.toggleSegmentExport = this.toggleSegmentExport.bind(this);
        this.toggleDropdown = this.toggleDropdown.bind(this);
        this.myRef = React.createRef();
    }

    //1. REACT LIFECYCLE FUNCTIONS (API CALLS) ------------------------------------------------------------------------------------------------
    async componentDidMount() {
        this._isMounted = true;
        //this.props.scrollTop();
        try {
            if (this._isMounted) {
                this.getSize();
                let l = await this.props.getBucketLabels("SegmentDetail", SegmentDetailLang(this.props.lang));
                this.labels = l;
                this.forceUpdate();
            }
        } catch (err) {
            console.log(err);
        }
    }

    async componentDidUpdate(prevProps, prevState) {
        if ((prevProps.segment !== this.props.segment) || (prevProps.filters !== this.props.filters)) {
            this.color = this.getSegmentColor(this.props.segment);
            this.body.filters = this.props.filters;
            this.body.segmentToFilter = this.props.segment;
            if (this._isMounted) {
                this.setState(state => {
                    if (state.tab > 4) {
                        return { tab: '1', page: 0 }
                    } else {
                        return { page: 0 }
                    }
                })
            }
        }

        if (prevState.recharge !== this.state.recharge && this.state.recharge === true) {
            if (this._isMounted) {
                this.setState({ recharge: false });
            }
        }

        if (prevProps.lang !== this.props.lang) {
            this.labels = SegmentDetailLang(this.props.lang);
            if (this._isMounted) {
                this.forceUpdate();
            }
        }

    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    changeTabs(tab) {
        this.setState({ tab: tab });
    }

    //2.UTILITY FUNCTIONS -----------------------------------------------------------------------------------------------------------------

    getSegmentColor(num) {
        var color = '#fff'
        switch (num) {
            case 1:
                color = '#2DC13F'
                break;
            case 2:
                color = '#258E98'
                break;
            case 3:
                color = '#F9943A'
                break;
            case 4:
                color = '#ffb81c'
                break;
            case 5:
                color = '#F9443A'
                break;

            default:
                break;
        }
        return color;
    }

    getSize() {
        if (this.myRef.current === null) return;
        let rect = ReactDOM.findDOMNode(this.myRef.current);//getBoundingClientRect();
        let r = rect.getBoundingClientRect();
        let width = r.width - 100;
        //console.log(rect);
        //console.log(r);

        this.setState({ treeChartSize: width });
    }

    toggleSegmentExport() {
        if (this._isMounted) {
            this.setState(state => {
                return ({ openSegmentExport: !state.openSegmentExport })
            })
        }
    }

    toggleDropdown() {
        if (this._isMounted) {
            this.setState(state => { return ({ dropdownOpen: !state.dropdownOpen }) })
        }
    }

    //3. PAGINATOR FUNCTIONS -----------------------------------------------------------------------------------------------------------------
    nextPage() {
        let clientsCount = this.props.data.count;
        let pageCount = Math.ceil(clientsCount / this._pageSize);
        if (this.state.page + 1 < pageCount) {
            this.setState(state => ({ recharge: true, page: state.page + 1 }))
        }
        else {
            //reach last page
        }

    }

    prevPage() {
        if (this.state.page === 0) { return /*Reach firts page */ }
        this.setState(state => ({ recharge: true, page: state.page - 1 }))
    }

    firstPage() {
        let currentPage = this.state.page;
        if (currentPage > 0) {
            this.setState({ recharge: true, page: 0 })
        }
    }

    lastPage() {
        let currentPage = this.state.page;
        let clientsCount = this.props.data.count;
        let pageCount = Math.ceil(clientsCount / this._pageSize);
        if (currentPage < pageCount) {
            this.setState({ recharge: true, page: pageCount - 1 })
        }


    }


    //4. PRE-RENDER FUNCTIONS ------------------------------------------------------------------------------------------------------------------
    getDropdownItem(segment) {
        var r =
            <>
                {segment != 1 ? <DropdownItem className="item" onClick={() => { this.props.toggleFilterSelectorFocus(false); this.props.changeSegment(1); }}>{this.labels.lblSegment1}</DropdownItem> : ""}
                {segment != 2 ? <DropdownItem className="item" onClick={() => { this.props.toggleFilterSelectorFocus(false); this.props.changeSegment(2); }}>{this.labels.lblSegment2}</DropdownItem> : ""}
                {segment != 3 ? <DropdownItem className="item" onClick={() => { this.props.toggleFilterSelectorFocus(false); this.props.changeSegment(3); }}>{this.labels.lblSegment3}</DropdownItem> : ""}
                {segment != 4 ? <DropdownItem className="item" onClick={() => { this.props.toggleFilterSelectorFocus(false); this.props.changeSegment(4); }}>{this.labels.lblSegment4}</DropdownItem> : ""}
                {segment != 5 ? <DropdownItem className="item" onClick={() => { this.props.toggleFilterSelectorFocus(false); this.props.changeSegment(5); }}>{this.labels.lblSegment5}</DropdownItem> : ""}
            </>;
        return r;
    }

    hexToRgba(hex) {
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16),
        } : null;
    }

    getFilterBar() {

        var bar = <>
            <CSSTransition classNames="animated" timeout={1000} in={this.props.openFilterBar}>
                <Row className="topFiltros" hidden={!this.props.openFilterBar}>
                    <Filtros
                        filters={this.props.filters}
                        ageBrackets={this.props.bucket.ageBrackets}
                        clasificationlist={this.props.bucket.clasificationlist}
                        genderSeparation={this.props.bucket.genderSeparation}
                        lang={this.props.lang}
                        getCatDat={this.props.getCatDat}
                        toggleFilterSelectorFocus={this.props.toggleFilterSelectorFocus}
                        toogleFilterBar={this.props.toggleFilterBar}
                        applyFilters={this.props.applyFilters}
                    />
                </Row>
            </CSSTransition>
        </>


        return bar;
    }

    //5. RENDER
    render() {
        // var _c = this.hexToRgba(this.color);
        // var colorToUse = "rgba(" + _c.r + "," + _c.g + "," + _c.b + ",0.8)";
        var forValues = [1, 2, 3, 4, 5];
        if (this.props.isSectionHidden('segmentdetails_redeemed_points_permonth')) { this.chartOptions.maintainAspectRatio = false } else { this.chartOptions.maintainAspectRatio = true }


        return (<>
            <Container fluid className={classnames({ "segmentDetail": true, "blur": this.state.openSegmentExport })}>
                <Row className="segmentNavigation">{/* NAVIGATION BUTTONS */}
                    <Col lg="8" md="5" sm="12">
                        {/* <h2>{this.props.name}</h2> */}
                        <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggleDropdown}>
                            <DropdownToggle className="title" onClick={() => { this.props.toggleFilterSelectorFocus(true) }}>
                                <span>{this.props.name}</span><i className="ti-angle-down" />
                            </DropdownToggle>
                            <DropdownMenu className="menu">
                                {this.getDropdownItem(this.props.segment)}
                            </DropdownMenu>
                        </Dropdown>
                    </Col>
                    <Col className="start-end" lg="2" md="3" sm="12">
                        <Button className="addFilterButton" hidden={this.props.openFilterBar} onClick={() => { this.props.toggleFilterBar(true) }}><i className="ti-filter" /> Agregar Filtro</Button>
                    </Col>
                    <Col className="start-end" lg="2" md="4" sm="12">
                        <Button className="float-right backToSegmentsButton" onClick={() => { this.props.changeScreen({ screen: 'segments' }) }}><span>&#10229;</span>  {this.labels.btnBackToSegments}</Button>
                    </Col>
                </Row>
                {this.getFilterBar()}
                <Row className="segmentTabs"> {/* NAVIGATION TABS */}
                    <Col lg="12" md="12" sm="12" xs="12">
                        <Nav tabs>
                            <NavItem>
                                <NavLink onClick={() => this.changeTabs("1")} className={classnames({ active: this.state.tab === "1" })}><i className="ti-align-justify" /> {this.labels.btnShowSummary}</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink onClick={() => this.changeTabs("2")} className={classnames({ active: this.state.tab === "2" })}><i className="ti-user" />{this.labels.btnViewClients}</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink onClick={() => this.changeTabs("3")} className={classnames({ active: this.state.tab === "3" })}><i className="ti-pie-chart" />{this.labels.btnPurchaseCharts}</NavLink>
                            </NavItem>
                            {(this.props.filters.length <= 0) ?
                                <>
                                    {this.props.isSectionHidden('favprods') ? " " :
                                        <NavItem>
                                            <NavLink onClick={() => this.changeTabs("5")} className={classnames({ active: this.state.tab === "5" })}>{this.labels.btnFavoriteProducts}</NavLink>
                                        </NavItem>
                                    }
                                    {this.props.isSectionHidden('favstores') ? "" :
                                        <NavItem>
                                            <NavLink onClick={() => this.changeTabs("6")} className={classnames({ active: this.state.tab === "6" })}>{this.labels.btnFavoriteStores}</NavLink>
                                        </NavItem>
                                    }
                                </>
                                : " "}
                        </Nav>
                    </Col>
                </Row>
                <Row> {/* NAVIGATION CONTENT */}
                    <TabContent activeTab={this.state.tab} className="container-fluid tabCont" ref={this.myRef}>

                        <TabPane tabId="1">{/* Segment Summary */}
                            <Row>
                                <Col lg="5">
                                    <Container>

                                        {this.props.bucket.genderSeparation ?
                                            <Row style={{ marginBottom: "15px" }}>
                                                <GenderSummary color={this.color}
                                                    currencySymbol={this.props.currencySymbol}
                                                    body={this.body}
                                                    askFor={this.props.askFor}
                                                    filters={this.body.filters}
                                                    segment={this.props.segment}
                                                    labels={this.labels}
                                                    loadingSpinner={this.props.loadingSpinner}
                                                />
                                            </Row>
                                            :
                                            ""
                                        }


                                        <Row style={{ marginBottom: "15px" }}>
                                            <Card className="doughnut">
                                                <CardBody>
                                                    {this.props.data != null ?
                                                        <Row style={{ alignItems: "center" }}>
                                                            <Col lg="6" sm="6" xs="6">
                                                                {this.state.tab === "1" ?
                                                                    <Doughnut
                                                                        data={{
                                                                            datasets: [{
                                                                                data: [this.props.data.perc_purchase_fromtotal, 100 - this.props.data.perc_purchase_fromtotal],
                                                                                backgroundColor: [this.color, '#cdb989'],
                                                                                borderColor: "rgba(0,0,0,0)"
                                                                            }],
                                                                            labels: [""]
                                                                        }}
                                                                        options={{
                                                                            legend: { display: false, position: "bottom" },
                                                                            maintainAspectRatio: false,
                                                                            tooltips: { enabled: false },
                                                                            hover: { mode: null },
                                                                        }}
                                                                    />
                                                                    : this.props.loadingSpinner}
                                                            </Col>
                                                            <Col lg="6" sm="6" xs="6">
                                                                <p className="noMargin">{this.labels.lblOfPurchases}</p>
                                                                <h4 style={{ color: this.color, alignSelf: "center", }}>{this.props.data.perc_purchase_fromtotal}%</h4>
                                                            </Col>
                                                        </Row>
                                                        : ""}
                                                </CardBody>
                                            </Card>
                                        </Row>
                                    </Container>
                                </Col>

                                <Col lg="7">
                                    <Row style={{ marginBottom: "15px" }}>{/* Summary Card */}
                                        <Col sm="12">
                                            {this.props.data != null ?
                                                <SummaryCard
                                                    labels={this.labels}
                                                    data={this.props.data}
                                                    currencySymbol={this.props.currencySymbol}
                                                    color={this.color}
                                                />
                                                :
                                                this.props.loadingSpinner
                                            }
                                        </Col>
                                    </Row>

                                    <Row style={{ marginBottom: "15px" }}>
                                        <SalesPerMonthChart
                                            title={this.labels.lblChartPurchaseSumTitle}
                                            segment={this.props.segment}
                                            body={this.body}
                                            filters={this.body.filters}
                                            chartOptions={this.chartOptions}
                                            color={this.color}
                                            askFor={this.props.askFor}
                                            labels={this.labels}
                                            loadingSpinner={this.props.loadingSpinner}
                                        />
                                    </Row>

                                    <Row>{/* Charts */}
                                        <PointChart
                                            lg={'12'}
                                            label={this.labels.lblChartPointsSummary}
                                            color={this.color}
                                            type="points"
                                            segment={this.props.segment}
                                            body={this.body}
                                            askFor={this.props.askFor}
                                            chartOptions={this.chartOptions}
                                            labels={this.labels}
                                            loadingSpinner={this.props.loadingSpinner}
                                        />

                                        {this.props.isSectionHidden('segmentdetails_redeemed_points_permonth') ? "" :
                                            <PointChart
                                                label={this.labels.lblChartRedemptions}
                                                color={this.color}
                                                type="redem"
                                                segment={this.props.segment}
                                                askFor={this.props.askFor}
                                                body={this.body}
                                                chartOptions={this.chartOptions}
                                                labels={this.labels}
                                                loadingSpinner={this.props.loadingSpinner}
                                            />
                                        }
                                    </Row>
                                </Col>
                            </Row>
                        </TabPane>


                        <TabPane tabId="2">{/* Client List */}
                            <Row>
                                <Col sm="12">
                                    <Card style={{ marginTop: '15px' }}>
                                        <CardBody>
                                            <ClientList
                                                segment={this.props.segment}
                                                filters={this.props.filters}
                                                currencySymbol={this.props.currencySymbol}
                                                isFieldHidden={this.props.isFieldHidden}
                                                askFor={this.props.askFor}
                                                labels={this.labels}
                                                lang={this.props.lang}
                                                page={this.state.page}
                                                resetPage={this.resetPage}
                                                openClientDetail={this.props.openClientDetail}
                                                changeScreen={this.props.changeScreen}
                                                toggleSegmentExport={this.toggleSegmentExport}
                                                loadingSpinner={this.props.loadingSpinner}
                                            />
                                            {this.props.data != null && this.props.data.count > 10 ?
                                                <Paginator
                                                    labels={this.labels}
                                                    nextPage={this.nextPage}
                                                    prevPage={this.prevPage}
                                                    firstPage={this.firstPage}
                                                    lastPage={this.lastPage}
                                                    currentPage={this.state.page + 1}
                                                />
                                                : " "}
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </TabPane>


                        <TabPane tabId="3">{/* Charts */}
                            <Row>
                                <Col sm="12" lg="12">
                                    <Card>
                                        <CardBody>
                                            <TreeMapCmp
                                                body={this.body}
                                                segment={this.body.segmentToFilter}
                                                filters={this.body.filters}
                                                color={this.color}
                                                width={this.state.treeChartSize}
                                                askFor={this.props.askFor}
                                                labels={this.labels}
                                                currencySymbol={this.props.currencySymbol}
                                            />
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </TabPane>

                        {this.props.isSectionHidden('favprods') ? "" :
                            <TabPane tabId="5">{/* Fav Products */}
                                <Row>
                                    <Col sm="12" lg="12">
                                        <FavProducts
                                            body={this.body}
                                            labels={this.labels}
                                            askFor={this.props.askFor}
                                            currencySymbol={this.props.currencySymbol}
                                            color={this.color}
                                            segment={this.body.segmentToFilter}
                                            filters={this.body.filters}
                                        />
                                    </Col>
                                </Row>
                            </TabPane>
                        }


                        {this.props.isSectionHidden('favstores') ? "" :
                            <TabPane tabId="6">{/* Fav Stores */}
                                <Row>
                                    <Col sm="12" lg="12">
                                        <FavStores
                                            body={this.body}
                                            labels={this.labels}
                                            askFor={this.props.askFor}
                                            currencySymbol={this.props.currencySymbol}
                                            color={this.color}
                                            segment={this.body.segmentToFilter}
                                            filters={this.body.filters}
                                        />
                                    </Col>
                                </Row>
                            </TabPane>
                        }
                    </TabContent>
                </Row>
            </Container>
            {this.state.openSegmentExport ?
                <CSSTransition classNames="animated" timeout={500} in={true}>
                    <ExportCreator
                        askFor={this.props.askFor}
                        lang={this.props.lang}
                        segmentName={this.props.name}
                        count={this.props.data.count}
                        segmentId={this.props.segment}
                        filters={this.props.filters}
                        closeExport={this.toggleSegmentExport}
                    />
                </CSSTransition>
                : ""}
        </>)
    }
}


export default SegmentDetail;
export { PointChart, GenderSummary, ClientList, SalesPerMonthChart, FavProducts, FavStores, SummaryCard, Paginator }
//Todos estos exportes realizan peticiones para formarse, en caso de utilizarlos con datos pasados como propiedades,
//es necesario pasar un propiedad "empty" para evitar que envien peticiones web